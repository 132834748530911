import React, { InputHTMLAttributes } from 'react';
import { Controller } from 'react-hook-form';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    defaultValue?: string | number | any;
    control: any;
    rules: any;
}

const FormInput: React.FC<FormInputProps> = ({ name, defaultValue, control, rules, ...rest }) => {
    return (
        <Controller
            control={control}
            render={({ onChange, onBlur, value }) => (
                <input
                    type={rest.type ? rest.type : "text"}
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    {...rest}
                />
            )}
            name={name}
            rules={rules}
            defaultValue={defaultValue ? defaultValue : ""}
        />
    )
};

export default FormInput;