import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { baseURL } from '../../services/functions/api';
import Regional from '../../services/model/regional';
import { getRegionals, getRegional} from '../../services/regional';

import PageTitle from '../../components/PageTitle';
import FormInput from '../../components/FormInput';
import FormInputGroup from '../../components/FormInputGroup';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';

import unityIcon from '../../assets/images/icons/unity-list.svg';
import editIcon from '../../assets/images/icons/edit.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';

const RegionalList: React.FC = () => {

    const [nRegionals, setNRegionals] = useState<number>(0);
    const [listRegionals, setListRegionals] = useState<Array<Regional>>([]);

    function screenUpdate() {
        getRegionals().then(response => {
            setListRegionals(response);
            setNRegionals(response.length);
        });
    }
    useEffect(() => {
        screenUpdate();
    }, []);

    const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>(0);

    const [regional, setRegional] = useState<Regional>();

    const { control, handleSubmit, errors, reset } = useForm<Regional>({ defaultValues: regional });

    useEffect(() => {
        if (selectedItem !== 0) {
            getRegional(selectedItem).then(response => {
                if (response) {
                    setRegional(response);
                    setOpenModalAdd(true);
                    setSrcImage(response.logo_name ? baseURL + '/uploads/img/logo/' + response.logo_name : '');
                }
            });
        } else {
            setRegional({});
        }
    }, [selectedItem]);

    useEffect(() => {
        reset(regional);
    }, [regional, reset]);

    function handleCloseModal() {
        screenUpdate();
        setSelectedItem(0);
        setOpenModalAdd(false);
        setSrcImage(null);
    }

    const [srcImage, setSrcImage] = useState<string | ArrayBuffer | null>();

    return (
        <div id="page-regional">
            <main className="container">

                <div className="ts-gutter">
                    <PageTitle text="Lista de Regionais" icon={unityIcon} />
                    <Row className="justify-content-between align-items-center">
                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nRegionals} registros encontrados</p></Col>
                        <Col sm="auto" className="ts-gutter-t">
                        </Col>
                    </Row>
                </div>
                <div className="ts-gutter">
                    <Table striped hover size="sm" className="ts-items-table">
                        <thead>
                            <tr>
                                <th>Cód.</th>
                                <th>Nome</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!listRegionals && listRegionals.map((item, key) => (
                                <tr onClick={() => setSelectedItem(item.id_regional ? item.id_regional : 0)} key={key}>
                                    <td><p>{item.id_regional}</p></td>
                                    <td><p>{item.regional_name}</p>
                                        <img src={editIcon} alt="Editar" className="img-edit" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </main>

            <Modal dialogClassName="ts-modal ts-modal-large" show={openModalAdd} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <PageTitle text={"Visualizar Regional"} icon={unityIcon} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mx-n2" as="form">
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Código" id="id_regional">
                                <FormInput
                                    id="id_regional"
                                    readOnly={true}
                                    name="id_regional"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="Nome" id="regional_name">
                                <FormInput
                                    disabled={true}
                                    id="regional_name"
                                    name="regional_name"
                                    control={control}
                                    rules={{ required: 'Nome é obrigatório' }}
                                />
                                {errors.regional_name?.message && (
                                    <p className="p-form-error">{errors.regional_name.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="E-mail" id="email">
                                <FormInput
                                    disabled={true}
                                    id="email"
                                    name="email"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={2} className="px-2">
                            <FormInputGroup label="Logo" id="regional_image">
                                <label htmlFor="regional_image" className="img-form">{srcImage && (<img src={srcImage.toString()} alt="Foto" />)}</label>
                                
                            </FormInputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default RegionalList;