import React, { createContext, useEffect, useState } from 'react';

import { getInfoConfig } from '../services/configurations/configurations';
import InfoConfig from '../services/model/configurations/configurations';

// Crie um novo contexto

interface InterfaceConfigContext {
    infoConfig: InfoConfig | undefined,
    setInfoConfig: React.Dispatch<React.SetStateAction<InfoConfig | undefined>>,
    getInfoConfigCall(reset?: any): void
}

const initialValues = {
    infoConfig: {} as InfoConfig | undefined,
    setInfoConfig: () => { },
    getInfoConfigCall(reset?: any) { }
}

const InfoConfigContext = createContext<InterfaceConfigContext>(initialValues);

const ConfigurationProvider = ({ children }: { children: React.ReactNode }) => {
    const [infoConfig, setInfoConfig] = useState<InfoConfig>();

    async function getInfoConfigCall(reset?: any) {
        getInfoConfig().then(response => {
            if (response) {
                setInfoConfig(response);
                if (reset) {
                    reset(response)
                }
            }
        })
    };

    useEffect(() => {
        getInfoConfigCall();
    }, []);

    return (
        <InfoConfigContext.Provider value={{ infoConfig, setInfoConfig, getInfoConfigCall }}>
            {children}
        </InfoConfigContext.Provider>
    );
};

export { ConfigurationProvider, InfoConfigContext };