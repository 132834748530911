import api from './functions/api';
import { verifyDataList, verifyData } from './functions/verify-response';
import fileDownload from 'js-file-download';
import lastMovimentation from './model/lastMovimentation';

export async function getLastMovimentations(filtros?: any): Promise<any | undefined> {
  return api.post('/lastMovimentation/', filtros).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

export async function getLastMovimentationsPdf(lastMovimentationArray?: Array<lastMovimentation>): Promise<Array<lastMovimentation>> {
  return api.post('/lastMovimentation/pdf/', lastMovimentationArray, { responseType: 'blob' }).then(response => {
    fileDownload(response.data, 'ultimos_lançamentos.pdf')
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}
