import React, { InputHTMLAttributes } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    defaultValue?: string;
    control: any;
    rules: any;
    mask: string;
}

const FormInputMask: React.FC<FormInputProps> = ({ name, defaultValue, control, rules, mask, ...rest }) => (
    <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
            <InputMask
                type={"text"}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                mask={mask}
                maskPlaceholder={null}
                {...rest}
            />
        )}
        name={name}
        rules={rules}
        defaultValue={defaultValue ? defaultValue : ""}
    />
);

export default FormInputMask;