import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import infoIcon from '../../../assets/images/icons/info.svg';
import logoImg from '../../../assets/images/logo.png';
import FormInput from '../../../components/FormInput';
import FormInputGroup from '../../../components/FormInputGroup';
import FormInputMask from '../../../components/FormInputMask';
import Loading from '../../../components/Loading';
import PageTitle from '../../../components/PageTitle';
import { InfoConfigContext } from '../../../contexts/InfoContext';
import { getInfoConfig, patchInfoConfig } from '../../../services/configurations/configurations';
import { clearAlert, getAlert, login } from '../../../services/functions/auth';
import InfoConfig from '../../../services/model/configurations/configurations';

const InfoContact: React.FC<any> = () => {

    const [showSuccess, setShowSuccess] = useState<Boolean>(false);
    const [errorSubmit, setErrorSubmit] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const { infoConfig, setInfoConfig, getInfoConfigCall } = useContext(InfoConfigContext);

    useEffect(() => {
        getInfoConfigCall(reset);
    }, []);

    const { control, handleSubmit, errors, reset, setValue, setError } = useForm<InfoConfig>({ defaultValues: infoConfig });
    const onSubmit = (data: any, e: any) => {
        if (data.phoneInfo)
            data.phoneInfo = data.phoneInfo.replace(/\D/g, '');
        if (data.whats)
            data.whats = data.whats.replace(/\D/g, '');

        patchInfoConfig(data).then(async response => {
            setLoading(false);

            if (typeof response === 'object' && !response.statusCode) {
                setErrorSubmit(response.msg);
                setTimeout(() => {
                    setErrorSubmit('');
                }, 1000);
            } else {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 1000);
            }
            getInfoConfigCall(reset);
        })
    };

    return (
        <main className="container">
            <div className="ts-gutter">
                <Row>
                    <Col lg={10}>
                        <PageTitle text="Informações de contato" icon={infoIcon} />
                    </Col>

                </Row>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mx-n2" as="fieldset">

                    <Col lg={2} className="px-2">
                        <FormInputGroup label="Whatsapp suporte" id="whats">
                            <FormInputMask
                                id="whats"
                                name="whats"
                                control={control}
                                readOnly={false}
                                rules=""
                                mask="(99) 9 9999-9999"
                                placeholder='(xx) x xxxx-xxxx'
                            />
                        </FormInputGroup>
                    </Col>

                    <Col lg={2} className="px-2">
                        <FormInputGroup label="Telefone fixo" id="phoneInfo">
                            <FormInputMask
                                id="phoneInfo"
                                name="phoneInfo"
                                control={control}
                                rules=""
                                mask="(99) 9999-9999"
                                placeholder='(xx) xxxx-xxxx'
                            />
                        </FormInputGroup>
                    </Col>

                    <Col lg={4} className="px-2">
                        <FormInputGroup label="E-mail" id="contactEmail">
                            <FormInput
                                id="contactEmail"
                                name="contactEmail"
                                control={control}
                                rules={''}
                                placeholder='@'
                                type='email'

                            />
                        </FormInputGroup>
                        {errors.contactEmail && <span>O email é obrigatório.</span>}
                    </Col>
                    <Col lg={4} className="px-2">
                        <FormInputGroup label="Link portal de videos" id="linkVideos">
                            <FormInput
                                id="linkVideos"
                                name="linkVideos"
                                control={control}
                                readOnly={false}
                                rules=""
                                placeholder='Ex: https://...'
                            />
                        </FormInputGroup>
                    </Col>
                    <Col lg={12}>
                        {errorSubmit && (
                            <p className="p-form-error text-center mb-3">{errorSubmit}</p>
                        )}
                        {!!showSuccess && (
                            <Alert variant="success">
                                <p className="mb-0 text-center">
                                    Informações salvas com sucesso
                                </p>
                            </Alert>)
                        }
                        {
                            <div className="ts-submit"><button disabled={loading} >{loading ? 'Salvando...' : 'Salvar'}</button></div>
                        }
                    </Col>

                </Row>
            </form>
        </main>
    )
}

export default InfoContact;