import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Collapse, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useForm } from 'react-hook-form';

import configIcon from '../../assets/images/icons/configs.svg';
import downloadIcon from '../../assets/images/icons/download.svg';
import printIcon from '../../assets/images/icons/printer.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import FormInput from '../../components/FormInput';

import FormInputGroup from '../../components/FormInputGroup';
import FormTextArea from '../../components/FormTextArea';
import LinkIcon from '../../components/LinkIcon';
import PageTitle from '../../components/PageTitle';
import PaginationCP from '../../components/PaginationCP';
import SelectUnity from '../../components/SelectFilterUnity';
import { downloadFile, getHistoric, getHistoricAll, getHistoricPdf } from '../../services/historic';
import Historic from '../../services/model/Historic';
import Regional from '../../services/model/regional';
import Unity from '../../services/model/unity';

import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import './styles.css';

const HistoricList: React.FC = () => {

    const itemsPerPage = 30;

    const [loading, setLoading] = useState<boolean>(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [pagination, setPagination] = useState<number>(0);
    const [nHistoric, setNHistoric] = useState<number>(0);
    const [listHistoric, setListHistoric] = useState<Array<Historic>>([]);
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>(0)

    const [fileDownload, setFileDownload] = useState<boolean>(false)

    const [historic, setHistoric] = useState<Historic>();
    const { control } = useForm<Historic>({ defaultValues: historic })

    const [filters, setFilters] = useState<any>({
        numserie: '',
        dtlan_from: '',
        dtlan_to: '',
        hrlan_from: '',
        hrlan_to: '',
        descricao: '',
        codcont: '',
        operador: '',
        tipo: '',
        pagination: '1',
    });

    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate()
    }

    function screenUpdate() {
        if (selectedUnity) {
            let dados = {
                id_unity: selectedUnity?.id_unity,
                filters: filters
            }

            getHistoricAll(dados).then(response => {
                setListHistoric(response.list);
                setNHistoric(response.total);
                response.total > 0 ? setPagination(Math.ceil(response.total / itemsPerPage)) : setPagination(0);
            });
        }
    }

    useEffect(() => {
        if (selectedItem !== 0) {
            let data = {
                numserie: selectedItem,
                id_unity: selectedUnity?.id_unity
            }
            if (fileDownload) {
                handleFileDownload(data)
            } else {
                getHistoric(data).then(response => {
                    if (response) {
                        setHistoric(response)
                        setOpenModalAdd(true);
                    }
                });
            }
        } else {
            setHistoric({});
        }
    }, [selectedItem]);

    function handleCloseModal() {
        screenUpdate();
        setSelectedItem(0);
        setOpenModalAdd(false);
    }

    function handleExportHistoric() {
        setLoading(true);
        let dados = {
            id_unity: selectedUnity?.id_unity,
            filters: filters,
            print: true
        }
        getHistoricPdf(dados).then(() => setLoading(false));
    }

    function handleFileDownload(dados: any) {
        setLoading(true);
        downloadFile(dados).then(() => setLoading(false));
        setSelectedItem(0);
        setFileDownload(false);
    }

    useEffect(() => {
        screenUpdate()
    }, [selectedUnity]);

    return (
        <div id="page-historic-usuario">
            <main className="container tabela">
                <div className="ts-gutter">
                    <PageTitle text="Histórico" icon={configIcon} />
                </div>

                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact_filter"
                    filter={false}
                    size={4} />


                <Row className="justify-content-between align-items-center">
                    <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nHistoric} registros encontrados</p></Col>
                    {selectedUnity &&
                        <>
                            <Col lg={4} sm="auto" className="ts-gutter-t" style={{ paddingLeft: '14%' }}>
                                <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                    <LinkIcon text="Pesquisar" icon={searchIcon} />
                                </button>
                                <button className="ts-link-icon" onClick={handleExportHistoric} disabled={loading}>
                                    <LinkIcon text="Imprimir" icon={printIcon} />
                                </button>
                            </Col>
                        </>}
                </Row>
                <Collapse in={openSearch} appear={true}>
                    <div className="ts-search ts-form-box ts-gutter">
                        <p className="ts-page-text mb-3">Pesquisar por:</p>
                        <Row className="mx-n2">
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Nº Série" id="numserie">
                                    <input type="text" id="numserie" onChange={(e) => verifyFilters('numserie', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Cod Cont" id="codcont">
                                    <input type="text" id="codcont" onChange={(e) => verifyFilters('codcont', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Tipo" id="tipo">
                                    <input type="text" id="tipo" onChange={(e) => verifyFilters('tipo', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={6} className="px-2">
                                <FormInputGroup label="Descrição" id="descricao">
                                    <input type="text" id="descricao" onChange={(e) => verifyFilters('descricao', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Operador" id="operador">
                                    <input type="text" id="operador" onChange={(e) => verifyFilters('operador', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={4} className="px-2"><b>Data </b></Col>
                            <Col lg={8} className="px-2"><b>Hora </b></Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="De" id="dtlan_from">
                                    <input type="date" id="dtlan_from" onChange={(e) => verifyFilters('dtlan_from', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Até" id="dtlan_to">
                                    <input type="date" id="dtlan_to" onChange={(e) => verifyFilters('dtlan_to', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="De" id="hrlan_from">
                                    <input type="time" id="hrlan_from" onChange={(e) => verifyFilters('hrlan_from', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Até" id="hrlan_to">
                                    <input type="time" id="hrlan_to" onChange={(e) => verifyFilters('hrlan_to', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                {selectedUnity &&
                    <div className="ts-gutter">
                        <Table striped hover size="sm" className="ts-items-table" style={{ cursor: 'pointer' }}>
                            <thead>
                                <tr>
                                    <th>Numserie</th>
                                    <th>Codcli</th>
                                    <th>Prontuário</th>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th>Operador</th>
                                    <th>Tipo</th>
                                    <th>Data/Hora</th>
                                    <th>Arquivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!listHistoric && listHistoric.map((item, key) => (
                                    <>
                                        <tr onClick={() => setSelectedItem(item.numserie ? item.numserie : 0)} key={key}>
                                            <td><p>{item.numserie}</p></td>
                                            <td><p>{item.codcont}</p></td>
                                            <td><p>{Number(item.codcont) != 1 && item.prontuario}</p></td>
                                            <td><p>{item.contact_name}</p></td>
                                            <td><p>{item.descricao}</p></td>
                                            <td><p>{item.operador}</p></td>
                                            <td><p>{Number(item.tipo) === 0 ? 'GERAL' : item.tipoDescricao}</p></td>
                                            <td><p>{item.hrlan + ' ' + item.dtlan}</p></td>
                                            <td><p>{!!item.arquivo_nome &&
                                                <button className="ts-link-icon" onClick={() => { setSelectedItem(item.numserie || 0); setFileDownload(true) }} disabled={loading}>
                                                    <LinkIcon text="Baixar" icon={downloadIcon} />
                                                </button>
                                            }</p></td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                        <PaginationCP
                            pagination={pagination}
                            filter={filters.pagination}
                            filterKey={'pagination'}
                            verifyFilters={verifyFilters}
                        />
                    </div >}
            </main >
            <Modal dialogClassName="ts-modal ts-modal-small" show={openModalAdd} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <PageTitle text={"Histórico"} icon={configIcon} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row className="mx-n2" as="fieldset" disabled={true}>
                            {historic?.codcont != "1" && (<Col lg={2} className="px-2">
                                <FormInputGroup label="Prontuário" id="prontuario">
                                    <FormInput
                                        disabled={true}
                                        id="prontuario"
                                        name="prontuario"
                                        control={control}
                                        rules=""
                                        defaultValue={historic?.prontuario}
                                    />
                                </FormInputGroup>
                            </Col>)}

                            <Col lg={5} className="px-2">
                                <FormInputGroup label="Nome" id="contact_name">
                                    <FormInput
                                        disabled={true}
                                        id="contact_name"
                                        name="contact_name"
                                        control={control}
                                        rules=""
                                        defaultValue={historic?.contact_name}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={5} className="px-2">
                                <FormInputGroup label="Operador" id="operador">
                                    <FormInput
                                        disabled={true}
                                        id="operador"
                                        name="operador"
                                        control={control}
                                        rules=""
                                        defaultValue={historic?.operador}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Hora" id="hrlan">
                                    <FormInput
                                        disabled={true}
                                        id="hrlan"
                                        name="hrlan"
                                        control={control}
                                        rules=""
                                        defaultValue={historic?.hrlan}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Data" id="dtlan">
                                    <FormInput
                                        disabled={true}
                                        id="dtlan"
                                        name="dtlan"
                                        control={control}
                                        rules=""
                                        defaultValue={moment(historic?.dtlan).format('DD/MM/YYYY')}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Tipo" id="tipo_historico">
                                    <FormInput
                                        disabled={true}
                                        id="tipo_historico"
                                        name="tipo_historico"
                                        control={control}
                                        rules=""
                                        defaultValue={historic?.tipo_historico}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={12} className="px-2">
                                <FormInputGroup label="Descrição" id="descricao">
                                    <FormTextArea
                                        id="descricao"
                                        name="descricao"
                                        control={control}
                                        rules=""
                                        rows={3}
                                        defaultValue={historic?.descricao}
                                    />
                                </FormInputGroup>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default HistoricList;