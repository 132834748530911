import api from './functions/api';
import { verifyData, verifyDataList } from './functions/verify-response';
import { Convenio } from './model/convenio';

export async function getConvenios(filters: any): Promise<Array<Convenio>> {
  return api.get('/convenio/list/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

export async function getConvenio(data: any): Promise<Convenio | undefined> {
  return api.get('/convenio/?' + new URLSearchParams(data).toString()).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

