import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import LoginData from '../../services/model/login';
import { login, getAlert, clearAlert } from '../../services/functions/auth';

import FormInput from '../../components/FormInput';
import FormInputGroup from '../../components/FormInputGroup';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';

import icone from '../../assets/images/icone.png';
import backgroundImage from '../../assets/images/fundoLogin.jpg';

import './styles.css';
import '../../assets/styles/form.css';

const Login: React.FC = () => {

    const [errorSubmit, setErrorSubmit] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const { control, handleSubmit, errors } = useForm<LoginData>();
    const onSubmit = handleSubmit((data) => {
        setErrorSubmit('');
        setLoading(true);

        login(data).then(response => {
            if (response.statusCode !== 200) {
                setErrorSubmit(response.msg);
                setLoading(false);
            } else {
                window.location.href = '/';
            }
        });
    });

    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();
    useEffect(() => {
        let msg = getAlert();
        if (msg) {
            setMsgAlertError(msg);
            setOpenAlertError(true);
            clearAlert();
        }
    }, []);

    return (
        <>
             <img src={backgroundImage} className="ts-img"></img>
        <div id="page-login" >

            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} />

            <main className="container">
                <div className="login-content d-flex align-items-center justify-content-center">
                    <div className="form">
                        <aside>
                        <img src={icone} alt="SAGEP" className="logo1" />
                        <label style={{marginLeft: '3%'}} className="portal">Portal administrativo</label>
                        <h1>Bem-vindo(a)!</h1>
                        </aside>
                        <form onSubmit={onSubmit}>
                            <FormInputGroup label="Usuário" id="user">
                                <FormInput
                                    id="user"
                                    name="user"
                                    control={control}
                                    rules={{ required: 'Usuário é obrigatório' }}
                                />
                                {errors.user?.message && (
                                    <p className="p-form-error">{errors.user?.message}</p>
                                )}
                            </FormInputGroup>
                            <FormInputGroup label="Senha" id="password">
                                <FormInput
                                    id="password"
                                    type="password"
                                    name="password"
                                    control={control}
                                    rules={{ required: 'Senha é obrigatória' }}
                                />
                                {errors.password?.message && (
                                    <p className="p-form-error">{errors.password?.message}</p>
                                )}
                            </FormInputGroup>
                            {errorSubmit && (
                                <p className="p-form-error text-center mb-3">{errorSubmit}</p>
                            )}
                            <div className="ts-submit"><button onClick={onSubmit}>PRÓXIMO</button></div>
                        </form>
                    </div>
                </div>
            </main>
            {loading && (<Loading />)}
        </div>
        </>
    );
}

export default Login;