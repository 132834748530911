import api from './functions/api';
import fileDownload from 'js-file-download';
import { verifyDataPagination, verifyDataList } from './functions/verify-response';
import ReleasesReturn from './model/releases-return';
import BankAccount from './model/bank-account';

export async function getReleases(filters: any): Promise<ReleasesReturn> {
	return api.get('/lists/releases/?' + new URLSearchParams(filters).toString()).then(response => {
		return verifyDataPagination(response);
	}).catch(error => {
		if (error.response.status == 400) {
			throw error.response.data.msg
		}
		return verifyDataPagination(error.response);
	});
}

export function getReleasesPdf(filters: any): Promise<object | void> {
	return api.get('/lists/download_releases/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
		try {
			fileDownload(response.data, 'lancamentos.pdf');
		} catch (error: any) {
			console.log(error);
		}
	}).catch(error => {
		console.log(error);
	});
}

export async function getBankAccounts(unity: number): Promise<Array<BankAccount>> {
	return api.get('/dados/contas/' + unity).then(response => {
		return verifyDataList(response);
	}).catch(error => {
		return verifyDataList(error.response);
	});
}