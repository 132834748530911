import { useEffect, useRef, useState } from "react";

const TdResponsive: React.FC<{ val: string }> = ({ val, children }) => {


    const textRef = useRef<HTMLTextAreaElement>(null);
    const tdRef = useRef<HTMLTableDataCellElement>(null);
    const [resize, setResize] = useState<Boolean>(false);

    useEffect(() => {
        if (tdRef.current && textRef.current) (Number(textRef.current?.scrollHeight) > Number(tdRef.current?.offsetHeight) ? setResize(true) : setResize(false))
    }, [val])


    return (

        <td ref={tdRef} style={{ padding: '0' }}><textarea readOnly ref={textRef} className="ts-text-area" value={String(val)} style={resize ? {} : { resize: 'none' }}>{children}</textarea></td>

    )
}

export default TdResponsive