import api from './functions/api';
import { verifyData, verifyDataPagination } from './functions/verify-response';
import Dados from './model/dados_usuario';

interface PaginationResponse {
  list: Array<Dados>;
  total: number;
}

export async function getDadosAll(filters: any): Promise<PaginationResponse> {
  return api.get('/dados/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export async function getDados(id: number): Promise<Dados | undefined> {
  return api.get('/dados/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}