import React, { ReactElement } from 'react';

import Modal from 'react-bootstrap/Modal';

import './styles.css';

interface AlertProps {
    showAlert: boolean;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
    message: ReactElement;
    handleOk?: any;
    handleYes?: any;
    handleNo?: any;
    handleCancel?: any;
}

const Alert: React.FC<AlertProps> = ({ showAlert, setShowAlert, message, handleOk, handleYes, handleNo, handleCancel }) => {
    return (
        <Modal dialogClassName="ts-modal" show={showAlert} onHide={() => setShowAlert(false)} centered>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                {handleOk !== false && (<div className="ts-alert-btn"><button onClick={handleOk ? handleOk : () => setShowAlert(false)}>OK</button></div>)}
                {!!handleYes && (<div className="ts-alert-btn yes"><button onClick={handleYes}>Sim</button></div>)}
                {!!handleNo && (<div className="ts-alert-btn no"><button onClick={handleNo}>Não</button></div>)}
                {!!handleCancel && (<div className="ts-alert-btn cancel"><button onClick={handleCancel}>Cancelar</button></div>)}
            </Modal.Footer>
        </Modal>
    );
}

export default Alert;