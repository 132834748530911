import AccountsList from '../../pages/AccountsList';
import AdminList from '../../pages/AdminList';
import CashList from '../../pages/CashList';
import ConvenioList from '../../pages/ConvenioList';
import DadosList from '../../pages/DadosUsuarioList';
import DetExtractGeneralList from '../../pages/DetExtractGeneralList';
import DetExtractList from '../../pages/DetExtractUnityList';
import HistoricList from '../../pages/HistoricList';
import InmateBalanceList from '../../pages/InmateBalanceList';
import InmateList from '../../pages/InmateList';
import LastMovimentationList from '../../pages/lastMovimentationList';
import LogList from '../../pages/LogList';
import RegionalList from '../../pages/RegionalList';
import ReleasesList from '../../pages/ReleasesList';
import ReportAttendance from '../../pages/Reports/Attendance/ReportAttendance';
import SaldoList from '../../pages/SaldoList';
import InfoContact from '../../pages/SapConfigs/InfoContact';
import UnityList from '../../pages/UnityList';
import Menu from '../model/menu';

export const menu: Array<Menu> = [
	{
		title: 'Acesso',
		sub_menu: [
			{ id: 1, title: 'Regionais', url: '/regionais', component: RegionalList, adm_master: true },
			{ id: 2, title: 'Unidades', url: '/unidades', component: UnityList, adm_master: true },
			{ title: '', url: '', type: 'divider', adm_master: true },
			{ title: 'Usuários', url: '', type: 'text', adm_master: true },
			{ id: 3, title: 'Administradores', url: '/administradores', component: AdminList, adm_master: true },
		],
	},
	{
		title: 'Logs',
		sub_menu: [
			{ id: 4, title: 'Dados log', url: '/dados', component: DadosList, adm_master: true },
			{ id: 11, title: 'Histórico ', url: '/historico', component: HistoricList },
			{ id: 12, title: 'Log', url: '/log', component: LogList },
		],
	},
	{
		title: 'Financeiro',
		sub_menu: [
			{ id: 14, title: 'Monitoramento de Atividades', url: '/monitoramento-de-atividades', component: LastMovimentationList },
			{ id: 7, title: 'Consulta de contas', url: '/consulta-contas', component: AccountsList },
			{ id: 8, title: 'Movimentação de caixa', url: '/caixas', component: CashList },
			{ id: 9, title: 'Grade de lançamentos', url: '/lancamentos', component: ReleasesList },
			{ id: 5, title: 'Saldo Unidade', url: '/saldo', component: SaldoList },
			{ id: 6, title: 'Extrato do detento na Unidade', url: '/extrato-detento-unidade', component: DetExtractList, },
			{ id: 10, title: 'Extrato do detento em Geral', url: '/extrato-detento-geral', component: DetExtractGeneralList },
		],
	},
	{
		title: 'Detento',
		sub_menu: [
			{ id: 13, title: 'Detentos por unidade', url: '/detento-por-unidade', component: InmateList },
			{ id: 15, title: 'Saldo do detento', url: '/saldo-do-detento', component: InmateBalanceList },

		],
	},
	{
		title: 'Configurações',
		sub_menu: [
			{ id: 16, title: 'Infos contato', url: '/info-contato', component: InfoContact, adm_master: true },

		],
	},

	{
		title: 'Relatórios',
		sub_menu: [
			{ id: 17, title: 'Convênio', url: '/convenio', component: ConvenioList },
			{ id: 18, title: 'Folha de chamada', url: '/folhaChamada', component: ReportAttendance },
		],
	},


];