import DefaultResponse from '../model/default-response';
import { getDataToken, setAlert } from './auth';

export function verifyDataList(response: any): Array<object> {

    if (!response || !response.data) return [];

    if (response.data.statusCode) {
        verifyStatusCode(response.data.statusCode);
        return [];
    }
    if (response.data[0]) {
        return response.data
    };
    if (response.data.list) {
        return response.data.list
    }
    return [];
}

export function verifyDataListGeneric<T>(response: any): Array<any> {

    if (!response || !response.data) return [];

    if (response.data.statusCode) {
        verifyStatusCode(response.data.statusCode);
        throw [];
    }
    if (response.data[0]) {
        throw response.data
    };
    if (response.data.list) {
        return response.data.list
    }
    throw [];
}

interface PaginationResponse {
    list: Array<object>;
    total: number;
    msg?: string;
    statusCode?: string
}

export function verifyDataPagination(response: any): PaginationResponse {
    if (!response || !response.data || !response.data.list) return { list: [], total: 0, msg: response?.data?.msg, statusCode: response?.data?.statusCode };

    if (response.data.statusCode) {
        verifyStatusCode(response.data.statusCode);
        return { list: [], total: 0 };
    }

    if (response.data.list) return response.data;

    return { list: [], total: 0 };
}

export function verifyData(response: any): object | undefined {
    if (!response || !response.data) return undefined;

    if (response.data.statusCode) {
        verifyStatusCode(response.data.statusCode);
        return undefined;
    }

    if (typeof response.data === 'object') return response.data;

    return undefined;
}

export function verifyResponse(response: any, operation: string): DefaultResponse {

    if (!response || !response.data || !response.data.statusCode || !response.data.msg) {
        return {
            statusCode: 0,
            msg: 'Erro ao ' + operation + ', por favor tente novamente',
        };
    }

    verifyStatusCode(response.data.statusCode);

    return response.data;
}

function verifyStatusCode(code: number) {
    if (code === 401 && getDataToken()) {
        setAlert('Login expirado, por favor faça login novamente');
        document.getElementById('btn-logout')?.click();
    }
}