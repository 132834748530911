import fileDownload from 'js-file-download';
import api from './functions/api';
import { verifyDataPagination, verifyResponse } from './functions/verify-response';
import InmateBalanceLists from './model/inmateBalance';


interface PaginationResponse {
  list: Array<InmateBalanceLists>;
  total: number;
}
export async function getAll(filters: any): Promise<PaginationResponse> {
  return api.post('/inmateBalance/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}
export async function simplePDF(filters: any): Promise<object | void> {
  return api.get('/inmateBalance/simplePDF/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'saldo_detento.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}


export async function detailedPDF(filters: any): Promise<object | void> {
  return api.get('/inmateBalance/detailedPDF/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'saldo_detento_origem.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    verifyResponse(error.response, 'Pdf ');
  });
}


export async function excel(filters: any): Promise<object | void> {
  return api.get('/inmateBalance/excel/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'saldo_detento.xlsx');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}
