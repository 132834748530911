import api from './functions/api';
import { verifyDataList } from './functions/verify-response';

export async function generalListData(route: string, filters : any ): Promise<Array<any | undefined> > {
    return api.get(`/dados/${route}?` + new URLSearchParams(filters)).then(response => {
        return verifyDataList(response);
    }).catch(error => {
        return verifyDataList(error.response);
    });
}
