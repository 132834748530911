import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/global.css';

import Routes from './routes';

const App: React.FC = () => {
    return (
      <Routes />
    );
}

export default App;
