import api from "../functions/api";
import { verifyResponse } from "../functions/verify-response";
import InfoConfig from "../model/configurations/configurations";
import DefaultResponse from "../model/default-response";

export async function patchInfoConfig(contractData: InfoConfig): Promise<any> {
    return api.patch('/configurations/infoContact/', { data: contractData }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error)
        return verifyResponse(error.response, 'cadastrar/atualizar os dados do cadastro das informações');
    });
}

export async function getInfoConfig(): Promise<InfoConfig> {
    return api.get('/configurations/infoContact/').then(response => {
        return response.data;
    }).catch(error => {
        return verifyResponse(error.response, 'buscar os dados');
    });
}