import React, { InputHTMLAttributes, Key } from 'react';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    options: Array<{ value: number, name?: string }>;
    value: number;
    name?: string
    setValue: React.Dispatch<React.SetStateAction<{ value: number, name: string }>>;

}

const RadioWithValues: React.FC<RadioButtonProps> = ({ options, value, setValue, ...props }) => {
    return (
        <>
            {options.map((option) => (
                <label key={option.value} className={props.className}>
                    <input
                        type="radio"
                        value={option.value}
                        checked={value === option.value}
                        onChange={({ target }) => setValue({ value: Number(target.value), name: "teste" })}
                        {...props}
                    />
                    {option.name}
                </label>
            ))}
        </>
    );
};

export default RadioWithValues;
