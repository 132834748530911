import jwtDecode from 'jwt-decode';

import DefaultResponse from '../model/default-response';
import LoginData from '../model/login';
import Token from '../model/token';
import api from './api';

export const TOKEN_KEY = '@sagep-Token';
export const REGIONAL_KEY = '@sagep-Regional';
export const UNITY_KEY = '@sagep-Unity';
export const USER_KEY = '@sagep-User';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export async function login(userData: LoginData): Promise<DefaultResponse> {
	return api.post('/login/', userData).then(response => {
		let token: any = jwtDecode(response.data);
		if (typeof response.data === 'string') {
			if (token.adm.adm_id_regional) localStorage.setItem(REGIONAL_KEY, token.adm.adm_id_regional);
			if (token.adm.adm_id_unity) localStorage.setItem(UNITY_KEY, token.adm.adm_id_unity);
			if (token.adm.login.user) localStorage.setItem(USER_KEY, token.adm.login.user);
			localStorage.setItem(TOKEN_KEY, response.data);
			return {
				statusCode: 200,
				msg: 'Login efetuado com sucesso',
			};
		}
		return response.data;
	}).catch(error => {
		if (!error.response || !error.response.data || !error.response.data.statusCode || !error.response.data.msg) {
			return {
				statusCode: 0,
				msg: 'Erro ao efetuar login, por favor tente novamente',
			};
		}
		return error.response.data;
	});
}

export function getDataToken(): Token | undefined {
	let token = localStorage.getItem(TOKEN_KEY);
	if (token) {
		return jwtDecode(token);
	}
	return undefined;
}

export const logout = () => localStorage.clear();


export const ALERT_KEY = '@sagep-Alert';

export const getAlert = () => sessionStorage.getItem(ALERT_KEY);

export function setAlert(value: string) {
	sessionStorage.setItem(ALERT_KEY, value);
}

export const clearAlert = () => sessionStorage.clear();