import api from './functions/api';
import { verifyDataList, verifyData } from './functions/verify-response';
import fileDownload from 'js-file-download';
import Inmate from './model/inmateDetail';

export async function getInmatePerUnity(filtros?: any): Promise<any | undefined> {
  return api.post('/inmate/', filtros).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

export async function getInmatePerUnityPdf(inmateArray?: Array<Inmate>): Promise<Array<Inmate>> {
  return api.post('/inmate/pdf/', inmateArray, { responseType: 'blob' }).then(response => {
    fileDownload(response.data, 'detento_unidade.pdf')
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}
