import React, { useState, useEffect, useCallback, useRef } from 'react';

import { isAdmin } from '../../services/functions/user-info';
import Unity from '../../services/model/unity';
import DetExtract from '../../services/model/det-extract';
import { extrato, getAll, saldo } from '../../services/det_extract';
import SelectUnity from '../../components/SelectFilterUnity';

import PageTitle from '../../components/PageTitle';
import LinkIcon from '../../components/LinkIcon';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import firstPage from '../../assets/images/icons/rewind.svg';
import prevPage from '../../assets/images/icons/left.svg';
import lastPage from '../../assets/images/icons/forward.svg';
import nextPage from '../../assets/images/icons/right.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import cashRegisterIcon from '../../assets/images/icons/cash-register.png';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import FormInputGroup from '../../components/FormInputGroup';
import ContactDetail from '../../services/model/contact-detail';
import SelectDetento from '../../components/SelectDetento';
import Loading from '../../components/Loading';
import { Collapse } from 'react-bootstrap';
import Totals from '../../services/model/totals';
import { formatMoney } from '../../services/functions/format';
import Regional from '../../services/model/regional';
import GeneralBalance from '../../services/model/generalBalance';
import { useForm } from 'react-hook-form';
import Radio from '../../components/Radio';
import PaginationCP from '../../components/PaginationCP';
import Alert from '../../components/Alert';

const DetExtractList: React.FC = () => {

    const itemsPerPage = 15;
    const [pagination, setPagination] = useState<number>(0);
    const [listExtract, setListExtract] = useState<Array<DetExtract>>([])
    const [totals, setTotals] = useState<Totals | undefined>(undefined)
    const [nExtract, setNExtract] = useState<number>(0);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [selectedContact, setSelectedContact] = useState<ContactDetail | undefined>(undefined);
    const [selectedProntuario, setSelectedProntuario] = useState<string | undefined>(undefined)
    const [value, setValue] = React.useState('Geral');
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [openNoDataAlert, setOpenNoDataAlert] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const idTimer = useRef<any>();

    const [filters, setFilters] = useState<any>({
        operador: '',
        descricao: '',
        entrada: '',
        saida: '',
        total: '',
        dt_ini: '',
        dt_fim: '',
        radio_bt: 'Geral',
        pagination: '1',
    });


    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate()
    }

    const screenUpdate = useCallback(() => {
        setMsgAlertError('')
        setOpenAlertError(false)
        if (selectedProntuario || selectedContact) {
            let dados = {
                prontuario: selectedProntuario,
                unity: selectedUnity?.id_unity,
                filters: filters
            }
            clearTimeout(idTimer.current)
            idTimer.current = setTimeout(() => {
                setLoading(true)
                getAll(dados).then((response) => {
                    setLoading(false)
                    if (response.list[0]) {
                        setListExtract(response.list)
                        setTotals({
                            totaldebit: response.totaldebit,
                            totalcred: response.totalcred,
                            totalsaldo: response.totalsaldo
                        })
                        setNExtract(response.total);
                        if (response.total > 0) {
                            setPagination(Math.ceil(response.total / itemsPerPage));
                        } else {
                            setPagination(0);
                            setNExtract(0)
                        }
                    } else if (response.statusCode === 201) {
                        //Sem prontuario
                        setOpenAlertError(true)
                        setMsgAlertError(response.msg)
                    } else if (response.statusCode == 204) {
                        //Sem Saldo
                        setNExtract(0)
                        setPagination(0);
                        setListExtract([])
                        setOpenAlertError(true)
                        setMsgAlertError(response.msg)
                    } else if (response.statusCode == 200) {
                        //Sem dados depois de filtrar
                        setListExtract([])
                        setTotals(undefined)
                        setNExtract(0)
                        setPagination(0);
                        setOpenNoDataAlert(true)
                        setMsgAlertError(response.msg)
                    }
                });
            }, 500)


        }
    }, [selectedProntuario, selectedContact, filters])

    useEffect(() => {
        if (selectedProntuario && selectedProntuario.length == 6) {
            setLoading(true)
            setListExtract([])
            setFilters({
                operador: '',
                descricao: '',
                entrada: '',
                saida: '',
                total: '',
                dt_ini: '',
                dt_fim: '',
                radio_bt: 'Geral',
                pagination: '1',
            });
            setValue('Geral')
            screenUpdate();
        }
    }, [selectedProntuario, selectedContact])

    useEffect(() => {
        setSelectedContact(undefined)
        setNExtract(0)
        setListExtract([])
        setSelectedProntuario('')
    }, [selectedUnity]);

    function handleExportSaldo() {
        setLoading(true);
        let dados = {
            prontuario: selectedProntuario,
            unity: selectedUnity?.id_unity
        }
        saldo(dados).then(() => setLoading(false));
    }

    function handleExportRelatorio() {
        setLoading(true);
        let dados = {
            prontuario: selectedProntuario,
            unity: selectedUnity?.id_unity,
            filters: filters
        }
        extrato(dados).then(() => setLoading(false));
    }

    function handleCloseAlert() {
        setOpenAlertError(false);
        setSelectedProntuario('');
        setSelectedContact({
            numserie: '',
            contact_name: ''
        })
    }

    useEffect(() => {
        verifyFilters('radio_bt', value)
    }, [value]);

    return (

        <div id="page-det-extract">
            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} handleOk={() => handleCloseAlert()} />
            <Alert showAlert={openNoDataAlert} setShowAlert={setOpenNoDataAlert} message={<p className="alert-error">{msgAlertError}</p>} handleOk={() => setOpenNoDataAlert(false)} />

            <main>
                <div className="ts-gutter-t container">
                    <Row>
                        <Col lg={6}>
                            <PageTitle text={"Saldo do detento na Unidade"} icon={cashRegisterIcon} />
                            <Row className="justify-content-between align-items-center">
                                <Col sm="auto" className="ts-gutter-t">
                                </Col>
                            </Row>
                        </Col>
                        {selectedContact && listExtract[0] &&
                            <>
                                <Col lg={6} className="printButton">
                                    <button className="ts-link-icon" onClick={handleExportSaldo} disabled={loading}>
                                        <LinkIcon text="Saldo" icon={printIcon} />
                                    </button>
                                    <button className="ts-link-icon" onClick={handleExportRelatorio} disabled={loading}>
                                        <LinkIcon text="Extrato" icon={printIcon} />
                                    </button>
                                </Col>
                            </>
                        }
                    </Row>
                    <SelectUnity
                        selectedUnity={selectedUnity}
                        setSelectedUnity={setSelectedUnity}
                        selectedRegional={filterRegional}
                        setSelectedRegional={setFilterRegional}
                        id="contact"
                        size={4}
                        disabled={loading}
                        all={false}
                    >
                        {selectedUnity && (
                            <SelectDetento
                                selectedUnity={isAdmin() ? selectedUnity : false}
                                selectedContact={selectedContact}
                                setSelectedContact={setSelectedContact}
                                selectedProntuario={selectedProntuario}
                                setSelectedProntuario={setSelectedProntuario}
                                id="contact_name"
                                size={3}
                            />
                        )}

                    </SelectUnity>

                    {selectedContact?.numserie && (
                        <>
                            <Row>
                                {!!listExtract.length &&
                                    <>
                                        <Col lg={10} className="ts-gutter-t"><p className="ts-page-text">{nExtract} registros encontrados</p></Col>
                                        <Col lg={2} className="ts-gutter-t" style={{ paddingLeft: '5%' }}>
                                            <button className="ts-link-icon " onClick={() => setOpenSearch(!openSearch)}>
                                                <LinkIcon text="Pesquisar" icon={searchIcon} />
                                            </button>
                                        </Col>
                                    </>}
                            </Row>
                            <Collapse in={openSearch} appear={true}>
                                <div className="ts-search ts-form-box ts-gutter">
                                    <p className="ts-page-text mb-3">Pesquisar por:</p>
                                    <Row className="mx-n2" onKeyUp={(e: any) => { if (e.keyCode == 13 && openSearch) { screenUpdate() } }}>

                                        <Col lg={5} className="px-2">
                                            <FormInputGroup label="Descrição" id="descricao">

                                                <input type="text" autoFocus id="descricao" defaultValue={filters.descricao} key={filters.descricao} onChange={(e) => verifyFilters('descricao', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={3} className="px-2">
                                            <FormInputGroup label="Operador" id="operador">
                                                <input type="text" autoFocus id="operador" defaultValue={filters.operador} key={filters.operador} onChange={(e) => verifyFilters('operador', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={2} className="px-2">
                                            <FormInputGroup label="Entrada" id="entrada">
                                                <input type="number" autoFocus id="entrada" defaultValue={filters.entrada} key={filters.entrada} readOnly={filters.saida ? true : false} onChange={(e) => verifyFilters('entrada', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={2} className="px-2">
                                            <FormInputGroup label="Saída" id="saida">
                                                <input type="number" autoFocus id="saida" defaultValue={filters.saida} key={filters.saida} readOnly={filters.entrada ? true : false} onChange={(e) => verifyFilters('saida', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={5} className="px-2"><b>Data</b></Col>
                                        <Col lg={7} className="px-2"><b>Tipo de Valor</b></Col>
                                        <Col lg={2} className="px-2">
                                            <FormInputGroup label="De" id="dt_ini">
                                                <input type="date" autoFocus id="dt_ini" defaultValue={filters.dt_ini} key={filters.dt_ini} onChange={(e) => verifyFilters('dt_ini', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={2} className="px-2">
                                            <FormInputGroup label="até" id="dt_fim">
                                                <input type="date" autoFocus id="dt_fim" defaultValue={filters.dt_fim} key={filters.dt_fim} onChange={(e) => verifyFilters('dt_fim', e.target.value)} />
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={4} style={{ marginTop: '28px' }}>
                                            <Radio options={['Geral', 'Disponivel', 'Poupanca']} value={value} setValue={setValue} style={{ marginRight: '4px', marginLeft: '12px' }} ></Radio>
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                            {!!listExtract.length &&
                                <div className="ts-gutter" style={loading ? { opacity: '0.3' } : { opacity: '1.0' }}>
                                    <Table striped size="sm" className="ts-items-table">
                                        <thead>
                                            <tr className='inmateTittleFormated'>
                                                <th colSpan={6} className="inmateTittle">{selectedContact.contact_name?.toUpperCase()}</th>
                                                <th colSpan={1} className="inmateTittle">{selectedProntuario}</th>
                                            </tr>
                                            <tr>
                                                <th>Id</th>
                                                <th>Descrição</th>
                                                <th>Operador</th>
                                                <th>Data Pagamento</th>
                                                <th>Entrada</th>
                                                <th>Saida</th>
                                                <th>Saldo Atual</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {!!listExtract && listExtract.map((item, key) => (
                                                <tr>
                                                    {<td><p>{item.counter}</p></td>}
                                                    <td><p>{item.descricao}</p></td>
                                                    <td><p>{item.operador}</p></td>
                                                    <td><p>{item.dtpag}</p></td>
                                                    <td className='text-right'><p>R$ {item.vlent ? formatMoney(String(item.vlent)) : '0,00'}</p></td>
                                                    <td className='text-right'><p>R$ {item.vlsai ? formatMoney(String(item.vlsai)) : '0,00'}</p></td>
                                                    {Number(item.counter) === Number(nExtract) ? <td className='text-right' style={{ backgroundColor: '#76b64f' }}><p> R$ {item.totalsum}</p></td> : <td className='text-right'><p>R$ {formatMoney(String(item.totalsum))}</p></td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {totals && !!listExtract.length &&
                                        <Table striped size="sm" style={{ textAlign: 'center', paddingLeft: '202px' }}>
                                            <thead>
                                                <tr >
                                                    <th colSpan={3}>Total Geral</th>
                                                </tr>
                                                <tr>
                                                    <th className='totalTable'>Entrada</th>
                                                    <th className='totalTable'>Saída</th>
                                                    <th className='totalTable'>Saldo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>R$ {totals.totaldebit ? formatMoney(String(totals.totaldebit)) : '0,00'}</td>
                                                    <td>R$ {totals.totalcred ? formatMoney(String(totals.totalcred)) : '0,00'}</td>
                                                    <td>R$ {totals.totalsaldo ? formatMoney(String(totals.totalsaldo)) : '0,00'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>}
                                    <PaginationCP
                                        pagination={pagination}
                                        filter={filters.pagination}
                                        filterKey={'pagination'}
                                        verifyFilters={verifyFilters}
                                    />
                                </div>
                            }
                        </>
                    )}
                </div>
            </main >
            {loading && (<Loading />)}
        </div >
    )
}
export default DetExtractList;

