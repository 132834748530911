import fileDownload from 'js-file-download';
import api from './functions/api';
import {verifyDataPagination } from './functions/verify-response';
import Log from './model/Log';

interface PaginationResponse {
  list: Array<Log>;
  total: number;
}

export async function getLog(filters: any): Promise<PaginationResponse> {
  return api.post('/log/', filters).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export async function getLogPdf(filters: any): Promise<object | void> {
  return api.post('/log/pdf/', filters, { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'Log_unidade.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}
