import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

import { getDataToken } from '../../services/functions/auth';
import { getRegional, getUnity, isAdminMaster } from '../../services/functions/user-info';
import Regional from '../../services/model/regional';
import { getRegionals } from '../../services/regional';
import FormInputGroup from '../FormInputGroup';
import { ArrowIcon } from '../Icons';

export const REGIONAL_KEY = '@sagep-Regional';
export const UNITY_KEY = '@sagep-Unity';
interface SelectRegionalProps {
    selectedRegional: Regional | undefined;
    setSelectedRegional: React.Dispatch<React.SetStateAction<Regional | undefined>>;
    id: string;
    filter?: boolean;
    disabled?: boolean;
    size?: number;
    all?: boolean;
}

const SelectRegional: React.FC<SelectRegionalProps> = ({ selectedRegional, setSelectedRegional, id, filter, disabled, size, all }) => {

    const [listRegional, setListRegional] = useState<Array<Regional>>([]);
    const [showRegional, setShowRegional] = useState<boolean>(false);
    let timeOutId: number = 0;

    const regionalFilters = {
        contact_name: '',
        pagination: '1',
        inactive: '1'
    };
    function changeFilters(newValue?: string) {
        if (!disabled) {
            let newFilters = regionalFilters;
            if (newValue) newFilters.contact_name = newValue;
            getRegionals(newFilters).then((response) => setListRegional(response));
        }
    }

    useEffect(() => {
        if (isAdminMaster()) {
            getRegionals().then(response => { setListRegional(response) })
        } else {
            let token = getDataToken();
            getRegional().then(response => {
                setSelectedRegional({
                    id_regional: token?.adm?.adm_id_regional,
                    regional_name: response
                })
            })
        }
    }, []);

    return (
        <>
            <Col lg={size ? size : 3} className="px-2" onMouseEnter={() => { clearTimeout(timeOutId); changeFilters(''); }}
                onMouseLeave={() => { timeOutId = Number(setTimeout(() => { setShowRegional(false) }, 600)) }}>
                <FormInputGroup label="Regional" id={"regional_" + id}>
                    <div className="ts-select-list">
                        <span className={"show-selected" + (disabled ? ' disabled' : '')} onClick={() => !disabled && setShowRegional(!showRegional)}>
                            {selectedRegional?.regional_name ? selectedRegional.regional_name : (filter ? "Todos" : "Selecione")}
                            <ArrowIcon />
                        </span>
                        {showRegional && !disabled && (
                            <div className="show-list">
                                <input className="search-list" type="text" placeholder="Pesquisar" onChange={(e) => changeFilters(e.target.value)} id={"regional_search_" + id} />
                                <div className="list">

                                    {!!listRegional.length && (
                                        <div className="item" onClick={() => { setSelectedRegional(undefined); setShowRegional(false); changeFilters(''); }}>
                                            <p>{filter ? "Todos" : "Selecione"}</p>
                                        </div>
                                    )}
                                    {!!listRegional.length && all && (
                                        <div className="item" onClick={() => { setSelectedRegional({ id_regional: -1, regional_name: "Todas as Regionais" }); setShowRegional(false); changeFilters(''); }}>
                                            <p>{"Todas as Regionais"}</p>
                                        </div>
                                    )}
                                    {!!listRegional && listRegional.map((item, key) => (
                                        <div className="item" onClick={() => { setSelectedRegional(item); setShowRegional(false); changeFilters(''); }} key={item.id_regional}>
                                            <p>{item.regional_name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </FormInputGroup>
            </Col>

        </>
    );
}
export default SelectRegional;