import React, { useEffect, useMemo, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import checkedIcon from '../../../assets/images/icons/check.png';
import configIcon from '../../../assets/images/icons/configs.svg';
import checkdIcon from '../../../assets/images/icons/okIcon.svg';
import printIcon from '../../../assets/images/icons/printer.svg';
import checkdNoIcon from '../../../assets/images/icons/unavailableIcon.svg';
import Alert from '../../../components/Alert';
import FormInputGroup from '../../../components/FormInputGroup';
import LinkIcon from '../../../components/LinkIcon';
import Loading from '../../../components/Loading';
import PageTitle from '../../../components/PageTitle';
import SelectUnity from '../../../components/SelectFilterUnity';
import { getLastMovimentations, getLastMovimentationsPdf } from '../../../services/lastMovimentation';
import { ChamadaLog, DetailedListChamadaLog, ListChamadaLog } from '../../../services/model/chamadaLog';
import { DetailedLastMovimentation, SimpleLastMovimentation } from '../../../services/model/lastMovimentation';
import Regional from '../../../services/model/regional';
import Unity from '../../../services/model/unity';
import { postCheckHasFiles } from '../../../services/reports/attendance';
import RegionalList from '../../RegionalList';
import ModalInfo from './components/ModalInfo';

import '../../../assets/styles/list.css';
import './styles.css';

const ReportAttendance: React.FC = () => {
    const [attendanceLog, setAttendanceLog] = useState<DetailedListChamadaLog>()
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [month, setMonth] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ListChamadaLog | undefined>(undefined);
    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();

    useMemo(() => {
        if (selectedUnity?.id_unity) {
            setLoading(true)
            let filter = {
                id_regional: filterRegional?.id_regional,
                id_unity: selectedUnity?.id_unity,
                month
            }
            postCheckHasFiles(filter).then(response => {
                if (response) {
                    setAttendanceLog(response);
                }
                setLoading(false)
            }).catch((error) => {
                if (error?.erro.status != 200) {
                    setOpenAlertError(true);
                    setMsgAlertError(String(error.erro.mensagem))
                }

            })
        }
    }, [selectedUnity, month]);

    function handleModal(selectedItem: ListChamadaLog) {
        setSelectedItem(selectedItem ? selectedItem : undefined);
        setOpenModal(true);
    }

    function handleCloseAlert() {
        setOpenAlertError(false);
        setLoading(false);
    }

    return (
        <>
            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} handleOk={() => handleCloseAlert()} />
            <div id="page-lastMovimentation">
                {loading && (<Loading />)}
                <main className="container">
                    <div className="ts-gutter">
                        <Row>
                            <Col lg={10}>
                                <PageTitle text="Relatórios de chamadas" icon={configIcon} />
                            </Col>
                        </Row>
                    </div>
                    <SelectUnity
                        selectedUnity={selectedUnity}
                        setSelectedUnity={setSelectedUnity}
                        selectedRegional={filterRegional}
                        setSelectedRegional={setFilterRegional}
                        id="contact"
                        size={3}
                        disabled={loading}
                        all={true}
                    >
                        {selectedUnity?.id_unity &&
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Período" id="month">
                                    <select id="months" onChange={(e) => { setMonth(Number(e.target.value)) }}>
                                        <option value="0">mês atual</option>
                                        <option value="1">1 mês</option>
                                        <option value="3">3 meses</option>
                                        <option value="6">6 meses</option>
                                        <option value="12">12 meses</option>
                                    </select>
                                </FormInputGroup>
                            </Col>
                        }
                    </SelectUnity>
                    <p className='lm-circle-false'> </p>
                    {!!selectedUnity?.id_unity && attendanceLog &&
                        <div className="ts-gutter" style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>

                            <table>
                                <tr>
                                    <td ><img className='lr-circle' src={checkdIcon}></img></td>
                                    <td className='p-1'>Há relatórios</td>
                                </tr>
                                <tr>
                                    <td><img className='lr-circle' src={checkdNoIcon}></img></td>
                                    <td className='p-1'>Não há relatórios</td>
                                </tr>
                            </table>


                            <Table striped size="sm" className="report ts-items-table ts-gutter ">
                                <thead>
                                    <tr>
                                        <th className='lm-th lm-greenTitles' colSpan={4}>
                                            <p className='Regititle'>
                                                {filterRegional?.id_regional !== -1 && selectedUnity?.id_unity === -1 ? '  ' + filterRegional?.regional_name :
                                                    filterRegional?.id_regional === -1 && selectedUnity?.id_unity !== -1 ? ' ' + selectedUnity?.contact_name :
                                                        filterRegional?.id_regional !== -1 && Number(selectedUnity) !== -1 ? ' ' + selectedUnity?.contact_name : ' Todas as Unidades'}
                                            </p>
                                        </th>
                                    </tr>
                                </thead>

                                {attendanceLog && attendanceLog.list.map((regionalItem, key: any) => (
                                    <>
                                        {regionalItem && regionalItem.lastAttendanceFiles.length &&
                                            <>
                                                <tr key={key}>
                                                    <td className="lm-th coloredTitles" colSpan={4}>{regionalItem.lastAttendanceFiles[0].regional_name} </td>
                                                </tr>
                                                <thead style={{ zIndex: 1, position: 'sticky' }}>
                                                    <tr className='lm-fixed '>
                                                        <th className='lm-th ' >Empresa</th>
                                                        <th className={"lm-th left right lm-width"}>Pecúlio</th>
                                                        <th className='lm-th lm-width'>Laboral</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ zIndex: 1 }}>


                                                    {!!regionalItem && regionalItem.lastAttendanceFiles.map((item, key2: any) => {

                                                        return <>
                                                            {selectedUnity.id_unity === -1 ? <tr className="coloredTitles text-center"><td colSpan={3}><p className="w-100">{item.unity_name}</p></td></tr> : undefined}
                                                            <tr className="text-center" key={key2} onClick={() => { handleModal(item) }}>
                                                                <td><p className='colun1'>{item.chamada_empresa_nome}</p></td>
                                                                <td className={"lm-td left right"}>{item.hasPeculio ? <img className='lr-circle' src={checkedIcon}></img> : <img className='lr-circle' src={checkdNoIcon}></img>}</td>
                                                                <td className='lm-td'>{item.hasLaboral ? <img className='lr-circle' src={checkedIcon}></img> : <img className='lr-circle' src={checkdNoIcon}></img>}</td>
                                                            </tr >
                                                        </>

                                                    })}
                                                </tbody>
                                                <br></br>
                                            </>
                                        }

                                    </>
                                ))}

                            </Table >
                        </div>
                    }
                </main >
            </div>
            {!!selectedItem &&
                <ModalInfo openModal={openModal} onHide={setOpenModal} selectedItem={selectedItem} setLoading={setLoading} loading={loading} monthsSelected={month} />}

        </>
    );
}

export default ReportAttendance;