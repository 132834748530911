import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { HtmlHTMLAttributes } from 'react';
import arrowfliped from '../../assets/images/icons/arrow-fliped.png';


interface ArrowIconProps {
    position?: "absolute"|"relative"|"inherit",
    height?: string,
    top?: string,
    left?: string,
    className?: string
}

export const ArrowIcon: React.FC<Partial<ArrowIconProps>> = ({ height, children, left, position, top, className }) => {
    return (
        <>
            < img style={{
                position: position ? position : 'absolute',
                height: '14px',
                top: '10px',
                left: !left ? '93%' : '',
                color: 'black'
            }}
                src={arrowfliped} alt="seta para baixo"
                className={className}
            />
            {children && children}
        </>
    )

}
