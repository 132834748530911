import React, { useEffect, useRef, useState } from 'react';

import SelectUnity from '../../components/SelectFilterUnity';
import { detailedPDF, excel, getAll, simplePDF } from '../../services/inmateBalance';
import Unity from '../../services/model/unity';

import LinkIcon from '../../components/LinkIcon';
import PageTitle from '../../components/PageTitle';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import $ from 'jquery';
import { Collapse } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { paramIcons } from '../../assets/beans/binds';
import cashRegisterIcon from '../../assets/images/icons/cash-register.png';
import printIcon from '../../assets/images/icons/printer.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import Alert from '../../components/Alert';
import DropDownButton from '../../components/DropDownButton';
import FormInputGroup from '../../components/FormInputGroup';
import Loading from '../../components/Loading';
import PaginationCP from '../../components/PaginationCP';
import RadioWithValues from '../../components/RadioWithValues';
import { formatMoney } from '../../services/functions/format';
import InmateBalanceLists from '../../services/model/inmateBalance';
import Regional from '../../services/model/regional';
import './styles.css';

const InmateBalanceList: React.FC = () => {

    const itemsPerPage = 30;
    const [pagination, setPagination] = useState<number>(0);
    const [listBalance, setListBalance] = useState<Array<InmateBalanceLists>>([])
    const [nBalance, setNBalance] = useState<number>(0);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [valueListed, setValueListed] = React.useState({ value: 3, name: 'Total' });
    const [valueType, setValueType] = React.useState({ value: 3, name: 'Todos' });
    const [isImplanted, setIsImplanted] = React.useState({ value: 3, name: 'Todos' });
    const [withZero, setWithZero] = useState<boolean>(true);
    const [importation, setImportation] = useState<boolean>(true);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [openNoDataAlert, setOpenNoDataAlert] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const idTimer = useRef<any>();

    const [filters, setFilters] = useState<any>({
        id_unity: '',
        dt_emission_ini: '',
        dt_emission_fim: '',
        gallery: '',
        code_pront: '',
        document: '',
        value_type: 3, //1 = negativos,  2 = positivos,  3 = todos,
        value_listed: 3, //1 = disponivel,  2 = poupança, 3 = total
        withZero: '',// boolean
        active: 3, // 0 = inativo  1 = ativo  
        importation: true, //  0 = sem importação, 1 = com importação
        pagination: '1',
    });

    useEffect(() => {
        verifyFilters('value_listed', valueListed.value)
    }, [valueListed]);

    useEffect(() => {
        verifyFilters('value_type', valueType.value)
    }, [valueType]);

    useEffect(() => {
        verifyFilters('active', isImplanted.value)
    }, [isImplanted]);

    useEffect(() => {
        verifyFilters('withZero', withZero)
    }, [withZero]);

    useEffect(() => {
        verifyFilters('importation', importation)
    }, [importation]);

    useEffect(() => {
    }, [selectedUnity]);


    function verifyFilters(key: string, newValue: string | boolean | number) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate()
    }

    function screenUpdate() {
        if (selectedUnity?.id_unity) {
            setLoading(true)
            getAll(filters).then((response) => {
                if (response) {
                    setLoading(false)
                    setListBalance(response.list)
                    setNBalance(response.total);
                    response.total > 0 ? setPagination(Math.ceil(response.total / itemsPerPage)) : setPagination(0);
                }
            })
        }
    }


    useEffect(() => {

        $('#dt_time_from').val('');
        $('#dt_time_to').val('');
        cleanFilters();
        if (selectedUnity?.id_unity)
            verifyFilters('id_unity', String(selectedUnity?.id_unity))
    }, [selectedUnity]);

    function handleExportAll(type: number) {
        if (type === 3) {
            simplePDF(filters).then(() => { setLoading(false) });
        } else if (type === 4) {
            detailedPDF(filters).then(() => { setLoading(false) });
        } else if (type === 5) {
            excel(filters).then(() => { setLoading(false) });

        } else {
            setMsgAlertError('Não há registros para imprimir');
            setOpenAlertError(true);
        }
    }

    function cleanFilters() {
        setFilters({
            dt_emission_ini: '',
            dt_emission_fim: '',
            gallery: '',
            code_pront: '',
            document: '',
            value_type: 'Total',
            value_listed: 'Todos',
            withZero: true,
            active: 3,
            importation: true,
            pagination: '1',
        })
    }

    function handleCloseAlert() {
        setOpenAlertError(false);
    }

    const { control } = useForm();

    return (

        <div id="page-det-extract">
            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} handleOk={() => handleCloseAlert()} />
            <Alert showAlert={openNoDataAlert} setShowAlert={setOpenNoDataAlert} message={<p className="alert-error">{msgAlertError}</p>} handleOk={() => setOpenNoDataAlert(false)} />

            <main>
                <div className="ts-gutter-t container">
                    <Row>
                        <Col lg={6}>
                            <PageTitle text={"Saldo dos detentos"} icon={cashRegisterIcon} />
                            <Row className="justify-content-between align-items-center">
                                <Col sm="auto" className="ts-gutter-t">
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <SelectUnity
                        selectedUnity={selectedUnity}
                        setSelectedUnity={setSelectedUnity}
                        selectedRegional={filterRegional}
                        setSelectedRegional={setFilterRegional}
                        id="contact"
                        size={4}
                        disabled={loading}
                        all={false} />

                    {!!selectedUnity?.id_unity &&

                        <Row className="align-items-center mx-n2" >

                            <Col lg={9} className="ts-gutter-t"><p className="ts-page-text">{nBalance} registros encontrados</p></Col>
                            <Col sm="auto" className="ts-gutter-t pt-3">
                                <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                    <LinkIcon text="Pesquisar" icon={searchIcon} />
                                </button>
                            </Col>
                            <DropDownButton
                                icon={printIcon}
                                loading={loading}
                                textIcon="Imprimir"
                                idDropDown='print_id'
                                viewType={1}
                                propsDropItem={[
                                    {
                                        title: "Simples",
                                        paramsFunc: paramIcons.simples
                                    },
                                    {
                                        title: "Detalhado",
                                        paramsFunc: paramIcons.detalhado
                                    },
                                    {
                                        title: "Excel",
                                        paramsFunc: paramIcons.excel
                                    },
                                ]}
                                func={handleExportAll}
                            />
                        </Row>

                    }
                    <Collapse in={openSearch} appear={true}>
                        <div className="ts-search ts-form-box ts-gutter ">
                            <p className="ts-page-text mb-3">Pesquisar por:</p>
                            <Row className="mx-n2" onKeyUp={(e: any) => { if (e.keyCode == 13 && openSearch) { screenUpdate() } }}>
                                <Col lg={5} className="px-2"> </Col>
                                <Col lg={7} className="px-2"><b>Data</b></Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Documento" id="document">
                                        <input type="text" autoFocus id="document" defaultValue={filters.document} key={filters.document} onChange={(e) => verifyFilters('document', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={1} className="px-2">
                                    <FormInputGroup label="Galeria" id="gallery">
                                        <input type="text" autoFocus id="gallery" defaultValue={filters.gallery} key={filters.gallery} onChange={(e) => verifyFilters('gallery', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Prontuário" id="code_pront">
                                        <input type="text" autoFocus id="code_pront" defaultValue={filters.code_pront} key={filters.code_pront} onChange={(e) => verifyFilters('code_pront', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="De" id="dt_emission_ini">
                                        <input type="date" autoFocus id="dt_emission_ini" onChange={(e) => verifyFilters('dt_emission_ini', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="até" id="dt_emission_fim">
                                        <input type="date" autoFocus id="dt_emission_fim" onChange={(e) => verifyFilters('dt_emission_fim', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={3}>
                                    <Row>
                                        <Col lg={6} className="py-4 my-2" >
                                            <label><input type='checkbox' checked={importation} onClick={() => { setImportation(!importation) }} /> Importação</label>
                                        </Col>
                                        <Col lg={6} className="py-4 my-2">
                                            <label><input type='checkbox' checked={withZero} onClick={() => { setWithZero(!withZero) }} /> Com Zero</label>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="text-center justify-content-right">
                                        <Col className="">
                                            <p><b>Situação (Trabalho)</b></p>
                                            <Row className="py-2">
                                                <Col >
                                                    <RadioWithValues className={"p-2"} options={[
                                                        { value: 1, name: ' Ativo' },
                                                        { value: 2, name: ' Inativo' },
                                                        { value: 3, name: ' Todos' }]}
                                                        value={isImplanted.value} setValue={setIsImplanted}  ></RadioWithValues>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col className="">
                                            <p><b>Tipo de Valor</b></p>
                                            <Row className="py-2">
                                                <Col >
                                                    <RadioWithValues className={"p-2"} options={[
                                                        { value: 1, name: ' Disponivel' },
                                                        { value: 2, name: ' Poupança' },
                                                        { value: 3, name: ' Total' }]}
                                                        value={valueListed.value} setValue={setValueListed}  ></RadioWithValues>
                                                </Col>

                                            </Row>
                                        </Col>

                                        <Col >
                                            <p><b>Tipo de Valor</b></p>
                                            <Row className="py-2">
                                                <Col >
                                                    <RadioWithValues className={"p-2"}
                                                        options={[
                                                            { value: 1, name: ' Negativos' },
                                                            { value: 2, name: ' Positivos' },
                                                            { value: 3, name: ' Todos' }
                                                        ]} value={valueType.value} setValue={setValueType} ></RadioWithValues>
                                                </Col>
                                            </Row>
                                        </Col>


                                    </Row>
                                </Col>

                            </Row>
                        </div>
                    </Collapse>


                    {!!listBalance.length &&
                        <div className="ts-gutter" style={loading ? { opacity: '0.3' } : { opacity: '1.0' }}>
                            <Table striped size="sm" className="ts-items-table">
                                <thead>
                                    <tr>
                                        <th>Prontuário</th>
                                        <th>Interno</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {!!listBalance && listBalance.map((item, key) => (
                                        <tr>
                                            <td><p>{item.prontuario}</p></td>
                                            <td><p>{item.nome}</p></td>
                                            <td className='text-right'><p>R$ {item.valores && formatMoney(item.valores)}</p></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <PaginationCP
                                pagination={pagination}
                                filter={filters.pagination}
                                filterKey={'pagination'}
                                verifyFilters={verifyFilters}
                            />
                        </div>
                    }
                </div>
            </main >
            {loading && (<Loading />)}
        </div >
    )
}
export default InmateBalanceList;

