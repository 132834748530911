import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { isAdminRegional } from '../../services/functions/user-info';
import Regional from '../../services/model/regional';
import { getRegionals } from '../../services/regional';
import Admin from '../../services/model/admin';
import { getAdmins, getAdmin } from '../../services/admin';

import PageTitle from '../../components/PageTitle';
import FormInput from '../../components/FormInput';
import FormInputGroup from '../../components/FormInputGroup';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';

import adminIcon from '../../assets/images/icons/admin-list.svg';
import adminEdit from '../../assets/images/icons/user-edit.svg';


import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import moment from 'moment';

const AdminList: React.FC = () => {

    const [nAdmins, setNAdmins] = useState<number>(0);
    const [listAdmins, setListAdmins] = useState<Array<Admin>>([]);

    function screenUpdate() {
        getAdmins().then(response => {
            setListAdmins(response);
            setNAdmins(response.length);
        });
    }
    useEffect(() => {
        screenUpdate();
    }, []);

    const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>(0);

    const [admin, setAdmin] = useState<Admin>();

    const [errorSubmit, setErrorSubmit] = useState<string>();
    const { control, handleSubmit, errors, reset } = useForm<Admin>({ defaultValues: admin });

    useEffect(() => {
        if (selectedItem !== 0) {
            getAdmin(selectedItem).then(response => {
                if (response) {
                    setAdmin(response);
                    setOpenModalAdd(true);
                }
            });
        } else {
            setAdmin({});
        }
    }, [selectedItem]);

    useEffect(() => {
        reset(admin, { errors: false });
    }, [admin, reset]);

    function handleCloseModal() {
        screenUpdate();
        setSelectedItem(0);
        setOpenModalAdd(false);
        setErrorSubmit('');
    }

    return (
        <div id="page-admin">

            <main className="container">

                <div className="ts-gutter">
                    <PageTitle text="Lista de administradores" icon={adminIcon} />
                    <Row className="justify-content-between align-items-center">
                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nAdmins} registros encontrados</p></Col>
                        <Col sm="auto" className="ts-gutter-t">
                        </Col>
                    </Row>
                </div>

                <div className="ts-gutter">
                    <Table striped hover size="sm" className="ts-items-table">
                        <thead>
                            <tr>
                                <th>Cód.</th>
                                <th>Nome</th>
                                <th>Usuário</th>
                                <th>Tipo</th>
                                <th>Último login</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!listAdmins && listAdmins.map((item, key) => (
                                <tr onClick={() => setSelectedItem(item.id_adm ? item.id_adm : 0)} key={key}>
                                    <td><p>{item.id_adm}</p></td>
                                    <td><p>{item.adm_name}</p></td>
                                    <td><p>{item.user}{item.inactive ? (<span style={{ color: '#d75a4a' }}> - Inativo</span>) : ''}</p></td>
                                    <td><p>{item.type === 1 ? 'Master' : item.type === 2 ? 'Regional' : 'Unidade'}</p></td>
                                    <td><p>{item.last_login ? moment(item.last_login).format('HH:mm:ss DD/MM/YYYY') : ''}</p></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

            </main>

            <Modal dialogClassName="ts-modal ts-modal-small" show={openModalAdd} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <PageTitle text={("Visualizar Administrador")} icon={adminEdit} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mx-n2" as="form">
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Código" id="id_adm">
                                <FormInput
                                    id="id_adm"
                                    readOnly={true}
                                    name="id_adm"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={6} className="px-2">
                            <FormInputGroup label="Nome" id="adm_name">
                                <FormInput
                                    disabled={true}
                                    id="adm_name"
                                    name="adm_name"
                                    control={control}
                                    rules={{ required: 'Nome é obrigatório' }}
                                />
                                {errors.adm_name?.message && (
                                    <p className="p-form-error">{errors.adm_name.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={5} className="px-2">
                            <FormInputGroup label="Usuário" id="user">

                                <FormInput
                                    disabled={true}
                                    id="user"
                                    name="login.user"
                                    control={control}
                                    rules={{ required: 'Usuário é obrigatório' }}
                                />
                                {errors.login?.user?.message && (
                                    <p className="p-form-error">{errors.login.user.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={6} className="px-2">
                            <FormInputGroup label={"Senha" + (selectedItem ? '(Deixe vazio para não alterar)' : '')} id="password">
                                <FormInput
                                    disabled={true}
                                    id="password"
                                    name="login.password"
                                    control={control}
                                    rules={selectedItem ? "" : { required: 'Senha é obrigatória' }}
                                    type="password"

                                />
                                {errors.login?.password?.message && (
                                    <p className="p-form-error">{errors.login.password.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={6} className="px-2">
                            <FormInputGroup label="Confirmar senha" id="confirm-password">
                                <FormInput
                                    disabled={true}
                                    id="confirm-password"
                                    name="login.confirm_password"
                                    control={control}
                                    rules={selectedItem ? "" : { required: 'Confirmação de senha é obrigatória' }}
                                    type="password"
                                />
                                {errors.login?.confirm_password?.message && (
                                    <p className="p-form-error">{errors.login.confirm_password.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={12}>
                            {errorSubmit && (
                                <p className="p-form-error text-center mb-3">{errorSubmit}</p>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AdminList;