import React from 'react';

import './styles.css';

interface LinkIconProps {
  icon: string;
  text: string;
  right?: boolean;
}

const LinkIcon: React.FC<LinkIconProps> = ({icon, text, right}) => {

    return (
        <div className="d-flex align-items-center">
            {!right && <img src={icon} alt={text}/>}
            <p>{text}</p>
            {right && <img src={icon} alt={text}/>}
        </div>
    );
}

export default LinkIcon;