import React, { useState, useMemo, useEffect, useCallback, useRef, } from 'react';

import { getSaldo, getSaldoPdf } from '../../services/unity';

import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import configIcon from '../../assets/images/icons/configs.svg';

import LinkIcon from '../../components/LinkIcon';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import SelectUnity from '../../components/SelectFilterUnity';
import Unity from '../../services/model/unity';
import { formatMoney } from '../../services/functions/format';
import Regional from '../../services/model/regional';
import Loading from '../../components/Loading';
import Radio from '../../components/Radio';
import { DetailedBalance, SimpleBalance } from '../../services/model/saldoDetail';


const SaldoList: React.FC = () => {
    const pageId = 13;
    const [saldo, setSaldo] = useState<DetailedBalance | SimpleBalance | undefined>(undefined);
    const [totals, setTotals] = useState<any | undefined>();
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');

    useMemo(() => {
        if (value != '') {
            if (selectedUnity?.id_unity) {
                setLoading(true)
                let filter: any = {
                    id_regional: filterRegional?.id_regional,
                    id_unity: selectedUnity?.id_unity,
                    type: value
                }
                getSaldo(filter).then(response => {
                    if (response) {
                        setSaldo(response);
                        setTotals(response.totals)
                    }
                    setLoading(false)
                });
            }
        }
    }, [selectedUnity, value]);

    useEffect(() => {
        setValue('Simples')
    }, [selectedUnity?.id_unity !== -1])

    function handleExport() {
        setLoading(true)
        let saldoArray: any = {
            id_regional: filterRegional?.id_regional,
            id_unity: selectedUnity?.id_unity,
            type: value
        }
        getSaldoPdf(saldoArray).then(() => setLoading(false));
    }



    return (
        <div id="page-saldo">

            {loading && (<Loading />)}
            <main className="container saldoContainer">
                <div className="ts-gutter">
                    <Row>
                        <Col lg={10}>
                            <PageTitle text="Saldo das Unidades" icon={configIcon} />
                        </Col>
                        {!!selectedUnity?.id_unity &&
                            <>
                                <Col lg={1}  >
                                    {<button className="ts-link-icon" onClick={handleExport} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>}
                                </Col>
                            </>
                        }
                    </Row>
                </div>
                {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                    <Col style={{ paddingLeft: '80%' }}><b>Visualização</b></Col>
                }
                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact"
                    size={4}
                    disabled={loading}
                    all={true}
                >
                    {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                        <Col lg={4} style={{ textAlign: 'center', marginTop: '2.5%' }}>
                            <Radio options={['Simples', 'Detalhado']} value={value} setValue={setValue} style={{ marginRight: '4px', marginLeft: '12px' }} />
                        </Col>}
                </SelectUnity>

                {!!selectedUnity?.id_unity && value && saldo?.list &&
                    <div className="ts-gutter sl-zindex" style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>
                        {value === 'Simples' && (
                            <Table striped size="sm" className="ts-items-table tabelaSaldo">
                                <thead style={{ zIndex: 1, position: 'sticky' }}>
                                    <tr className='greenTitles sl-fixed' style={{ textAlign: 'right' }}>
                                        <th style={{ textAlign: 'left', width: '25%' }}>Nome</th>
                                        <th>Unidade</th>
                                        <th>Não Identificado</th>
                                        <th>Tarifas Bancárias</th>
                                        <th>Rendimentos</th>
                                        <th>Detento</th>
                                        <th>Diferença</th>
                                    </tr>
                                </thead>
                                <tbody style={{ zIndex: -1 }}>
                                    {!!saldo && (saldo?.list as Array<SimpleBalance['list'][0]>).map((item, key: any) => (
                                        <>
                                            <tr key={key} style={{ textAlign: 'right' }}>
                                                <td><p style={{ textAlign: 'left' }}>{item.unity_name}</p></td>
                                                <td><p>R$ {item?.unityBalance ? formatMoney(item?.unityBalance.toString()) : '0,00'}</p></td>
                                                <td><p>R$ {item?.notIdentified ? formatMoney(item?.notIdentified.toString()) : '0,00'}</p></td>
                                                <td><p>R$ {item?.bankFees ? formatMoney(item?.bankFees.toString()) : '0,00'}</p></td>
                                                <td><p>R$ {item?.earnings ? formatMoney(item?.earnings.toString()) : '0,00'}</p></td>
                                                <td><p>R$ {item?.inmateBalance ? formatMoney(item?.inmateBalance.toString()) : '0,00'}</p></td>
                                                <td><p>R$ {item?.difference ? formatMoney(item?.difference.toString()) : '0,00'}</p></td>
                                            </tr>
                                        </>
                                    ))}
                                    {saldo.list.length && totals && !!((filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1) || (filterRegional?.id_regional !== -1 && selectedUnity?.id_unity === -1)) && (
                                        <tr className='greenTitles' style={{ textAlign: 'right' }}>
                                            <td style={{ textAlign: 'center', width: '10%' }}><p>Total</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumUni)) : '0,00'}</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumNIdent)) : '0,00'}</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumBank)) : '0,00'}</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumEarns)) : '0,00'}</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumInm)) : '0,00'}</p></td>
                                            <td><p>R$ {totals ? formatMoney(String(totals.sumDif)) : '0,00'}</p></td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        )}

                        {value === 'Detalhado' &&
                            <Table striped size="sm" className="ts-items-table tabelaSaldo">
                                <thead>
                                    <tr>
                                        <th className='greenTitles text-center' colSpan={7}><p className='Regititle'> Saldo de todas as Unidades</p></th>
                                    </tr>
                                </thead>

                                {saldo && value === 'Detalhado' && (saldo.list as Array<DetailedBalance['list'][0]>).map((regionalItem: any, key: any) => (
                                    <>
                                        {regionalItem && regionalItem.unityBalanceArray &&
                                            <>
                                                <tr key={key}>
                                                    <td className="sl-th coloredTitles" colSpan={7}><p className='regional'>{regionalItem?.unityBalanceArray[0].regional_name}</p></td>
                                                </tr>
                                                <thead>
                                                    <tr className='tr' style={{ textAlign: 'right' }}>
                                                        <th style={{ textAlign: 'left', width: '25%' }}>Nome</th>
                                                        <th>Unidade</th>
                                                        <th>Não Identificado</th>
                                                        <th>Tarifas Bancárias</th>
                                                        <th>Rendimentos</th>
                                                        <th>Detento</th>
                                                        <th>Diferença</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!!regionalItem && regionalItem.unityBalanceArray.map((item: any, key2: any) => {
                                                        return (
                                                            <tr key={key2} style={{ textAlign: 'right' }}>
                                                                <td><p style={{ textAlign: 'left' }}>{item.unity_name}</p></td>
                                                                <td><p>R$ {item?.unityBalance ? formatMoney(item?.unityBalance.toString()) : '0,00'}</p></td>
                                                                <td><p>R$ {item?.notIdentified ? formatMoney(item?.notIdentified.toString()) : '0,00'}</p></td>
                                                                <td><p>R$ {item?.bankFees ? formatMoney(item?.bankFees.toString()) : '0,00'}</p></td>
                                                                <td><p>R$ {item?.earnings ? formatMoney(item?.earnings.toString()) : '0,00'}</p></td>
                                                                <td><p>R$ {item?.inmateBalance ? formatMoney(item?.inmateBalance.toString()) : '0,00'}</p></td>
                                                                <td><p>R$ {item?.difference ? formatMoney(item?.difference.toString()) : '0,00'}</p></td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                                <tr className='coloredTitles' style={{ textAlign: 'right' }}>
                                                    <td><p style={{ textAlign: 'left', fontSize: '100%' }}>Total Regional</p></td>
                                                    <td><p>R$ {saldo ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumUni)) : '0,00'}</p></td>
                                                    <td><p>R$ {saldo ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumNIdent)) : '0,00'}</p></td>
                                                    <td><p>R$ {saldo ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumBank)) : '0,00'}</p></td>
                                                    <td><p>R$ {saldo ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumEarns)) : '0,00'}</p></td>
                                                    <td><p>R$ {saldo ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumInm)) : '0,00'}</p></td>
                                                    <td><p>R$ {totals ? formatMoney(String((saldo.list as Array<DetailedBalance['list'][0]>)[key].sumDif)) : '0,00'}</p></td>
                                                </tr>
                                                <br></br>
                                            </>
                                        }
                                    </>
                                ))}
                                {saldo && totals &&
                                    <tr className='greenTitles text-right'>
                                        <th style={{ textAlign: 'center' }}>Total Geral</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumUniFinal)) : '0,00'}</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumNIdentFinal)) : '0,00'}</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumBankFeesFinal)) : '0,00'}</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumEarningFinal)) : '0,00'}</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumInmFinal)) : '0,00'}</th>
                                        <th>R$ {totals ? formatMoney(String(totals.sumDifFinal)) : '0,00'}</th>
                                    </tr>}
                            </Table>
                        }
                    </div >
                }
            </main >
        </div >
    );
}

export default SaldoList;