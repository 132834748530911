// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/fundoLogin.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-content {\n    min-height: 100vh;\n}\n\n.login-content .form {\n    width: 100%;\n    max-width: 350px;\n    padding: 1rem;\n    border-radius: 1rem;\n    border: 1px #b5b5b5 solid;\n    margin: 0 auto 2em;\n    position: relative;\n    background-color: #fff;\n}\n\n.login-content .logo {\n    margin: 0 auto 2em;\n    display: block;\n}\n\n.background-image {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n\n}\n\n.logo1 {\n    max-width: 10%;\n}\n\n.portal {\n    color: #124898be;\n}\n\n.ts-img {\n    opacity: 0.64;\n    filter: blur(5px);\n    position: fixed;\n    width: 100%;\n    height: 101%;\n    marginTop: -9px;\n    object-fit: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/LoginList/styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,yDAAyD;IACzD,4BAA4B;;AAEhC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".login-content {\n    min-height: 100vh;\n}\n\n.login-content .form {\n    width: 100%;\n    max-width: 350px;\n    padding: 1rem;\n    border-radius: 1rem;\n    border: 1px #b5b5b5 solid;\n    margin: 0 auto 2em;\n    position: relative;\n    background-color: #fff;\n}\n\n.login-content .logo {\n    margin: 0 auto 2em;\n    display: block;\n}\n\n.background-image {\n    background-image: url(../../assets/images/fundoLogin.jpg);\n    background-repeat: no-repeat;\n\n}\n\n.logo1 {\n    max-width: 10%;\n}\n\n.portal {\n    color: #124898be;\n}\n\n.ts-img {\n    opacity: 0.64;\n    filter: blur(5px);\n    position: fixed;\n    width: 100%;\n    height: 101%;\n    marginTop: -9px;\n    object-fit: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
