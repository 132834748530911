import React, { useState, useEffect } from 'react';

import PageTitle from '../../components/PageTitle';
import LinkIcon from '../../components/LinkIcon';
import printIcon from '../../assets/images/icons/printer.svg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import configIcon from '../../assets/images/icons/configs.svg';
import firstPage from '../../assets/images/icons/rewind.svg';
import prevPage from '../../assets/images/icons/left.svg';
import lastPage from '../../assets/images/icons/forward.svg';
import nextPage from '../../assets/images/icons/right.svg';
import searchIcon from '../../assets/images/icons/search.svg';


import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import FormInputGroup from '../../components/FormInputGroup';
import { Collapse } from 'react-bootstrap';
import { getLog, getLogPdf } from '../../services/log';
import Log from '../../services/model/Log';
import SelectUnity from '../../components/SelectFilterUnity';
import Unity from '../../services/model/unity';
import Regional from '../../services/model/regional';
import PaginationCP from '../../components/PaginationCP';

const LogList: React.FC = () => {

    const pageId = 12;
    const itemsPerPage = 30;

    const [loading, setLoading] = useState<boolean>(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [paginationNumbers, setPaginationNumbers] = useState<React.FC>();
    const [pagination, setPagination] = useState<number>(0);
    const [nLog, setNLog] = useState<number>(0);
    const [listLog, setListLog] = useState<Array<Log>>([]);
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);

    const [filters, setFilters] = useState<any>({
        tabela_nome: '',
        tabela_id: '',
        tabela_referencia: '',
        descricao: '',
        operacao: '',
        operador: '',
        dt_from: '',
        dt_to: '',
        hr_from: '',
        hr_to: '',
        pagination: '1',
    });

    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate()
    }

    function screenUpdate() {
        let dados = {
            id_unity: selectedUnity?.id_unity,
            filters: filters
        }
        getLog(dados).then(response => {
            setListLog(response.list);
            setNLog(response.total);
            response.total > 0 ? setPagination(Math.ceil(response.total / itemsPerPage)) : setPagination(0);

        });
    }


    function handleExportLog() {
        setLoading(true);
        let dados = {
            id_unity: selectedUnity?.id_unity,
            filters: filters,
            print: true
        }
        getLogPdf(dados).then(() => setLoading(false));
    }

    useEffect(() => {
        document.addEventListener('keypress', function (e) {
            if (e.which == 13) {
                screenUpdate()
            }
        }, false);
    }, []);

    useEffect(() => {
        screenUpdate()
    }, [selectedUnity]);

    return (
        <div id="page-log-usuario">
            <main className="container tabela">
                <div className="ts-gutter">
                    <PageTitle text="Log" icon={configIcon} />
                </div>

                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact_filter"
                    filter={false}
                    size={4} />

                <div className="ts-gutter">
                    <Row className="justify-content-between align-items-center">
                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nLog} registros encontrados</p></Col>
                        {selectedUnity &&
                            <>
                                <Col lg={4} sm="auto" className="ts-gutter-t" style={{ paddingLeft: '14%' }}>
                                    <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                        <LinkIcon text="Pesquisar" icon={searchIcon} />
                                    </button>
                                    <button className="ts-link-icon" onClick={handleExportLog} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>
                                </Col>
                            </>}
                    </Row>
                </div>
                <Collapse in={openSearch} appear={true}>
                    <div className="ts-search ts-form-box ts-gutter">
                        <p className="ts-page-text mb-3">Pesquisar por:</p>
                        <Row className="mx-n2">
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Tabela" id="tabela_nome">
                                    <input type="text" id="tabela_nome" onChange={(e) => verifyFilters('tabela_nome', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Referencia" id="tabela_id">
                                    <input type="text" id="tabela_id" onChange={(e) => verifyFilters('tabela_id', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Id tabela" id="tabela_referencia">
                                    <input type="text" id="tabela_referencia" onChange={(e) => verifyFilters('tabela_referencia', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={5} className="px-2">
                                <FormInputGroup label="Descrição" id="descricao">
                                    <input type="text" id="descricao" onChange={(e) => verifyFilters('descricao', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Operador" id="operador">
                                    <input type="text" id="operador" onChange={(e) => verifyFilters('operador', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Operação" id="operacao">
                                    <input type="text" id="operacao" onChange={(e) => verifyFilters('operacao', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={4} className="px-2"><b>Data </b></Col>
                            <Col lg={8} className="px-2"><b>Hora </b></Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="De" id="dt_from">
                                    <input type="date" id="dt_from" onChange={(e) => verifyFilters('dt_from', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Até" id="dt_to">
                                    <input type="date" id="dt_to" onChange={(e) => verifyFilters('dt_to', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="De" id="hr_from">
                                    <input type="time" id="hr_from" onChange={(e) => verifyFilters('hr_from', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Até" id="hr_to">
                                    <input type="time" id="hr_to" onChange={(e) => verifyFilters('hr_to', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                {selectedUnity &&
                    <div className="ts-gutter">
                        <Table striped hover size="sm" className="ts-items-table">
                            <thead>
                                <tr>
                                    <th>Tabela</th>
                                    <th>Referência</th>
                                    <th>Id tabela</th>
                                    <th>Descrição</th>
                                    <th>Operação</th>
                                    <th>Data/Hora</th>
                                    <th>Operador</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!listLog && listLog.map((item, key) => (
                                    <tr >
                                        <td><p>{item.tabela_nome}</p></td>
                                        <td><p>{item.tabela_id}</p></td>
                                        <td><p>{item.tabela_referencia}</p></td>
                                        <td><p>{item.tabela_descricao}</p></td>
                                        <td><p>{item.operacao}</p></td>
                                        <td><p>{item.hora + ' ' + item.data}</p></td>
                                        <td><p>{item.operador}</p></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <PaginationCP
                            pagination={pagination}
                            filter={filters.pagination}
                            filterKey={'pagination'}
                            verifyFilters={verifyFilters}
                        />
                    </div>}
            </main>
        </div>
    );
}

export default LogList;