export enum paramIcons {
    contasPagas = 1,
    contasRecebidas = 2,
    simples = 3,
    detalhado = 4,
    excel = 5
}

export enum valueType {
    notIdentified = 1,
    bankFees = 2,
    earnings = 3
}
