import api from './functions/api';
import { verifyDataList, verifyData, verifyResponse } from './functions/verify-response';
import Status from './model/status';
import DefaultResponse from './model/default-response';

export async function getStatusAll(filters?:any): Promise<Array<Status>> {
  return api.get('/status/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

export async function getStatus(id: number): Promise<Status | undefined> {
  return api.get('/status/'+id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}
