import { memo } from "react";

import { formatMoney } from "../../services/functions/format";

interface ToolTipProps {
    active: any,
    payload: any,
    barChart?: boolean
}

const CustomTooltip: React.FC<any> = ({ active, payload, barChart }: any) => {

    return (

        <div>
            {active && payload && payload[1] && (<p> {payload[1].payload.name}</p>)}

            {(active && payload && payload.length) && payload.map((item: any) => {

                return (
                    <>
                        {barChart
                            ? (<>
                                <label style={{ color: item?.payload?.color ? item?.payload?.color : '#000' }}><b>{item.payload.name}</b></label>
                                <p style={{ color: item?.payload?.color ? item?.payload?.color : '#000' }}>{`Valor : R$ ${item?.payload?.uv ? formatMoney(item?.payload?.uv.toString()) : "0"}`}</p>
                                <p style={{ color: item?.payload?.color ? item?.payload?.color : '#000' }}>{`Porcentagem : ${Number(item?.value).toFixed(2)}%`}</p>

                            </>)
                            : <><p style={{ color: item.fill }}>{`${item.name} : ${formatMoney(Number(item.value).toFixed(2))}`}</p></>}


                    </>
                )
            })}

        </div>
    )

}

export default memo(CustomTooltip)