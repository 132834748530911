import moment from "moment";
import { memo, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Area, Bar, BarChart, Brush, CartesianGrid, Cell, ComposedChart, Legend, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { formatMoney } from "../../../services/functions/format";
import BarChartObject from '../../../services/model/bar-chart';
import FormInputGroup from "../../FormInputGroup";
import Loading from "../../Loading";
import LegendCustomizedPie from "../CustomizedLegendPie";
import CustomTooltip from "../CustomToolTip";
import RenderCustomizedLabel from "../RenderCustomizedLabel";

import './styles.css';
import { FiltersAccountList } from "../../../services/model/AccountList";

interface AccountListChartData {
    dataChart: Array<BarChartObject> | undefined,
    dataChartContasChpag: Array<BarChartObject> | undefined,
    dataChartContasChrec: Array<BarChartObject> | undefined,
    dataChartPeriod: any,
    chartPeriod: number,
    loading: boolean,
    setChartPeriod: React.Dispatch<any>,
    chartsRef: React.Ref<any>,
    filters: FiltersAccountList
}

const AccountListCharts: React.FC<AccountListChartData> = ({ dataChartContasChpag, dataChartContasChrec, dataChart, chartPeriod, loading, dataChartPeriod, setChartPeriod, chartsRef, filters }) => {


    const colorsValues = ['#d75a4a', '#76b64f'];

    return (

        <div ref={chartsRef} className="container-fluid print-content" >

            <Row className="ts-gutter-b justify-content-center">
                <Col xl={6}>
                    {!!dataChartContasChpag?.length ? (
                        <Row>
                            <Col xl={12} className="ts-page-text ts-gutter-t text-center text-print-2">
                                <h4>Contas Recebidas entre {filters.dt_From} até {filters.dt_To}</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <ResponsiveContainer width="99%" height="100%" aspect={2}>
                                    <BarChart
                                        width={500}
                                        height={200}
                                        data={dataChartContasChrec}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <YAxis tickFormatter={(value) => `${value}%`} />
                                        <Tooltip labelStyle={{ fontSize: 12 }} content={<CustomTooltip barChart={true} />} />
                                        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                        <ReferenceLine y={0} stroke="#000" />
                                        <Brush dataKey="total" height={30} stroke="#8884d8" />
                                        <Bar fill="#fff" dataKey="total" stackId="stack" >
                                            {
                                                dataChartContasChrec?.map((entry, index) => (
                                                    <Cell fill={entry.color} key={`cell-${index}`} />
                                                ))
                                            }
                                        </Bar>

                                    </BarChart>
                                </ResponsiveContainer>


                            </Col>
                        </Row>

                    ) : (<Loading notFullScreen={true} text="Carregando gráfico de contas recebidas" />)}
                </Col>

                <Col xl={6}>
                    {!!dataChartContasChpag?.length ? (
                        <Row>
                            <Col xl={12} className="ts-page-text ts-gutter-t text-center text-print-2">
                                <h4>Contas pagas entre {filters.dt_From} até {filters.dt_To}</h4>
                            </Col>
                            <Col className="d-flex justify-content-center">

                                <ResponsiveContainer width="99%" height="100%" aspect={2}>
                                    <BarChart
                                        width={500}
                                        height={200}
                                        data={dataChartContasChpag}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <YAxis tickFormatter={(value) => `${value}%`} />
                                        <Tooltip labelStyle={{ fontSize: 12 }} content={<CustomTooltip barChart={true} />} />
                                        <ReferenceLine y={0} stroke="#000" />
                                        <Brush dataKey="total" height={30} stroke="#8884d8" />
                                        <Bar fill="#000" dataKey="total" stackId="stack" >
                                            {
                                                dataChartContasChpag?.map((entry, index) => (
                                                    <Cell fill={entry.color} />
                                                ))
                                            }
                                        </Bar>

                                    </BarChart>
                                </ResponsiveContainer>


                            </Col>

                        </Row>
                    ) : (<Loading notFullScreen={true} text="Carregando gráfico de contas pagas" />)}
                </Col>
            </Row>

            <Row className="ts-gutter-b justify-content-center">
                {!!dataChartPeriod.length && (
                    <>
                        <Col xl={12} className="ts-page-text ts-gutter-t text-center text-print-2">
                            <h3>Contas pagas e recebidas por periodo de {chartPeriod}{chartPeriod > 1 ? ' meses.' : ' mês.'} </h3>
                        </Col>
                        <Col xl='auto' className="ts-page-text ts-gutter-t text-center print-none">
                            <FormInputGroup label="Mesês" id="chart-period">
                                <select id="chart-period" onChange={(e) => { setChartPeriod(Number(e.target.value)); }} value={chartPeriod} disabled={loading}>
                                    {
                                        moment.months().map((month, index) => {
                                            return (
                                                <>
                                                    <option key={index} value={index + 1}>{index + 1}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                            </FormInputGroup>
                        </Col>
                        <Col xl={12}>
                            <div className="ts-charts big">
                                <ResponsiveContainer width="100%" height="100%" aspect={3}>
                                    <ComposedChart
                                        data={dataChartPeriod}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis interval={0} dataKey="name" />
                                        <YAxis interval={0} scale="linear" />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Legend iconType="line" />
                                        <ReferenceLine x={0} stroke="#6c757d" />
                                        {
                                            dataChartPeriod.length > 1 ? (
                                                <>
                                                    <Area type="monotone" dataKey="pagas" name={"Pagas"} fill={colorsValues[0]} stroke={colorsValues[0]} />
                                                    <Area type="monotone" dataKey="recebidas" name={"Recebidas"} fill={colorsValues[1]} stroke={colorsValues[1]} />
                                                </>
                                            ) :
                                                (
                                                    <>
                                                        <Bar type="natural" dataKey="pagas" barSize={50} name={"Pagas"} fill={colorsValues[0]} stroke={'#6c757d'} />
                                                        <Bar type="natural" dataKey="recebidas" barSize={50} name={"Recebidas"} fill={colorsValues[1]} stroke={'#6c757d'} />
                                                    </>
                                                )
                                        }
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </Col>
                    </>
                )}



                {
                    (!dataChartContasChrec || !dataChartContasChpag) && (
                        <div className="print-space-pgrc"></div>
                    )
                }

                <Col sm={12} lg={4} >
                    <ResponsiveContainer className={"pie-element-print"} width="100%" aspect={1.5}>
                        {!!dataChart?.length ? (<PieChart>
                            <Pie
                                dataKey="valor"
                                isAnimationActive={false}
                                data={dataChart}
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#385a7d"
                                label={RenderCustomizedLabel}
                            >
                                {!!dataChart && dataChart.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colorsValues[index]} stroke={'#6c757d'} />
                                ))}
                            </Pie>
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />

                        </PieChart>) : (<Loading notFullScreen={true} text="Carregando gráfico..." />)}



                    </ResponsiveContainer>
                </Col>
            </Row>
        </div>
    )
}

export default AccountListCharts;