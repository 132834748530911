import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import downloadIcon from '../../../../assets/images/icons/download.svg';
import LinkIcon from '../../../../components/LinkIcon';
import { FilterAttendaceReportDownload, ListChamadaLog, SelectedListChamadaLog } from '../../../../services/model/chamadaLog';
import { downloadFile, listFilesReportAttendance } from '../../../../services/reports/attendance';

interface Props {
    openModal: boolean;
    onHide: React.Dispatch<React.SetStateAction<boolean>>;
    selectedItem: ListChamadaLog;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    monthsSelected: number;

}

const ModalInfo: React.FC<Props> = ({ openModal, onHide, selectedItem, setLoading, loading, monthsSelected, ...rest }) => {

    const [listOfReports, setListOfReports] = useState<Array<SelectedListChamadaLog>>([]);
    const [fileDownload, setFileDownload] = useState<boolean>(false)

    function handleFileDownload(numserie: number, typeReport: FilterAttendaceReportDownload['typeReport']) {
        let filter: FilterAttendaceReportDownload = {
            numserie,
            id_unity: Number(selectedItem.id_unity),
            typeReport
        }
        setLoading(true);
        downloadFile(filter).then(() => setLoading(false)).then(() => { setFileDownload(false) }).catch(() => { setFileDownload(false) });
    }

    useEffect(() => {
        if (openModal) {
            listFilesReportAttendance(Number(selectedItem.id_unity), monthsSelected).then((dados) => {
                setListOfReports(dados)
            })
        }
    }, [openModal]);

    return (
        <Modal size="xl" show={openModal} onHide={onHide} centered>
            <Modal.Body>
                <h3 className='text-center'>{selectedItem.chamada_empresa_nome}</h3>
                <h4 className="text-center">Relatórios</h4>
                <Table striped size="sm" className="ts-items-table tabelaLastMovimentation">

                    <thead>
                        <tr>
                            <th>
                                Competência
                            </th>
                            <th>
                                Peculio
                            </th>
                            <th>
                                Laboral
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!listOfReports && listOfReports.map((item) => {
                            return (
                                <tr>
                                    <td><p>{item.chamada_competencia}</p></td>
                                    <td><p> {item.hasPeculio ? <button className="ts-link-icon" onClick={() => { handleFileDownload(item.numserie, "peculio") }} disabled={loading}>
                                        <LinkIcon text="Baixar" icon={downloadIcon} />
                                    </button> : ''} </p></td>
                                    <td><p> {item.hasLaboral ? <button className="ts-link-icon" onClick={() => { handleFileDownload(item.numserie, "laboral") }} disabled={loading}>
                                        <LinkIcon text="Baixar" icon={downloadIcon} />
                                    </button> : ''} </p></td>

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfo;