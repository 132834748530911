import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Regional from '../../services/model/regional';
import { getRegionals } from '../../services/regional';
import Unity from '../../services/model/unity';
import { getUnity, getUnitiesList } from '../../services/unity';

import PageTitle from '../../components/PageTitle';
import LinkIcon from '../../components/LinkIcon';
import FormInput from '../../components/FormInput';
import FormInputGroup from '../../components/FormInputGroup';
import FormInputMask from '../../components/FormInputMask';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';

import unityIcon from '../../assets/images/icons/unity-list.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import editIcon from '../../assets/images/icons/edit.svg';
import firstPage from '../../assets/images/icons/rewind.svg';
import prevPage from '../../assets/images/icons/left.svg';
import lastPage from '../../assets/images/icons/forward.svg';
import nextPage from '../../assets/images/icons/right.svg';

import './styles.css';
import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import PaginationCP from '../../components/PaginationCP';
import Status from '../../services/model/status';
import { getStatusAll } from '../../services/status';

const UnityList: React.FC = () => {

    const itemsPerPage = 30;
    const [pagination, setPagination] = useState<number>(0);
    const [nUnities, setNUnities] = useState<number>(0);
    const [listUnities, setListUnities] = useState<Array<Unity>>([]);

    const [regional, setRegional] = useState<Array<Regional>>([]);
    const [statusList, setStatusList] = useState<Array<Status>>([]);


    const [filters, setFilters] = useState<any>({
        id_unity: '',
        contact_name: '',
        city_name: '',
        state_name: '',
        id_regional: '',
        id_status: '',
        status: 'All',
        pagination: '1',
    });

    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate();
    }

    function screenUpdate() {
        getUnitiesList(filters).then(response => {
            setListUnities(response.list);
            setNUnities(response.total);
            setPagination(response.total > 0 ? Math.ceil(response.total / itemsPerPage) : 0);
        });
    }
    useEffect(() => {
        screenUpdate();
        getRegionals().then((response) => setRegional(response));
        getStatusAll({displayed:'filtered'}).then((response) => setStatusList(response))
    }, []);



    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [openModalAdd, setOpenModalAdd] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number>(0);

    const [unity, setUnity] = useState<Unity>();

    const { control, handleSubmit, errors, reset, setValue } = useForm<Unity>({ defaultValues: unity });

    const [selectedStatus, setSelectedStatus] = useState<number | undefined>(1);
    const [status, setStatus] = useState<string | undefined>('');
    const [selectedRegional, setSelectedRegional] = useState<number | undefined>(0);

    useEffect(() => {
        if (selectedItem !== 0) {
            getUnity(selectedItem).then(response => {
                if (response) {
                    setSelectedStatus(response.contact?.contact_id_status)
                    setStatus(response.status_name)
                    setSelectedRegional(response.unity_id_regional)
                    setUnity(response);
                    setOpenModalAdd(true);
                }
            });
        } else {
            setUnity({});
        }
    }, [selectedItem]);

    useEffect(() => {
        reset(unity, { errors: false });
    }, [unity, reset]);

    function handleCloseModal() {
        screenUpdate();
        setSelectedItem(0);
        setSelectedRegional(0)
        setSelectedStatus(1)
        setOpenModalAdd(false);
    }

    return (
        <div id="page-unity">

            <main className="container">

                <div className="ts-gutter">
                    <PageTitle text="Lista de unidades" icon={unityIcon} />
                    <Row className="justify-content-between align-items-center">
                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nUnities} registros encontrados</p></Col>
                        <Col sm="auto" className="ts-gutter-t">
                            <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                <LinkIcon text="Pesquisar" icon={searchIcon} />
                            </button>
                        </Col>
                    </Row>
                </div>
                <Collapse in={openSearch} appear={true}>
                    <div className="ts-search ts-form-box ts-gutter">
                        <p className="ts-page-text mb-3">Pesquisar por:</p>
                        <Row className="mx-n2">

                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Regional" id="filter_regional">
                                    <select id="filter_regional" onChange={(e) => verifyFilters('id_regional', e.target.value)}>
                                        <option value="">Todas</option>
                                        {regional.map((item) => (
                                            <option value={item.id_regional}>{item.regional_name}</option>
                                        ))}
                                    </select>
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="Código" id="filter_id_unity">
                                    <input type="text" id="filter_id_unity" onChange={(e) => verifyFilters('id_unity', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={3} className="px-2">
                                <FormInputGroup label="Nome" id="filter_contact_name">
                                    <input type="text" id="filter_contact_name" onChange={(e) => verifyFilters('contact_name', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={3} className="px-2">
                                <FormInputGroup label="Cidade" id="filter_city">
                                    <input type="text" id="filter_city" onChange={(e) => verifyFilters('city_name', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={1} className="px-2">
                                <FormInputGroup label="UF" id="filter_state">
                                    <input type="text" id="filter_state" onChange={(e) => verifyFilters('state_name', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Status" id="id_status">
                                    <select id="id_status" onChange={(e) => verifyFilters('id_status', e.target.value)}>
                                        <option value="">Todas</option>
                                        {statusList.map((item) => (
                                            <option value={item.id_status}>{item.status_name}</option>
                                        ))}
                                    </select>
                                </FormInputGroup>
                            </Col>

                        </Row>
                    </div>
                </Collapse>

                <div className="ts-gutter">
                    <Table striped hover size="sm" className="ts-items-table">
                        <thead>
                            <tr>
                                <th>Cód.</th>
                                <th>Nome</th>
                                <th>Regional</th>
                                <th>Cidade</th>
                                <th>Estado</th>
                                <th>Status</th>
                                <th>Último acesso</th>

                            </tr>
                        </thead>
                        <tbody>
                            {!!listUnities && listUnities.map((item, key) => (
                                <tr onClick={() => setSelectedItem(item.id_unity ? item.id_unity : 0)} key={key}>
                                    <td><p>{item.id_unity}</p></td>
                                    <td><p>{item.contact_name}{item.inactive ? (<span className="red"> - Inativo</span>) : ''}</p></td>
                                    <td><p>{item.regional_name}</p></td>
                                    <td><p>{item.city_name}</p></td>
                                    <td><p>{item.state_name}</p></td>
                                    <td><p>{item.status_name}</p></td>
                                    <td><p>{item.last_time}</p>
                                        <img src={editIcon} alt="Editar" className="img-edit" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <PaginationCP
                        pagination={pagination}
                        filter={filters.pagination}
                        filterKey={'pagination'}
                        verifyFilters={verifyFilters}
                    />


                </div>
            </main>

            <Modal dialogClassName="ts-modal ts-modal-large" show={openModalAdd} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <PageTitle text={(selectedItem === 0 ? "Adicionar" : "Editar") + " unidade"} icon={unityIcon} />
                    </Modal.Title>
                    <div className='labelUnidade'>Status:
                        <label className='statusUnity'>  {status}</label>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mx-n2" as="form">
                        <Col lg={1} className="px-2" style={{ display: 'none' }}>
                            <FormInputGroup label="Código" id="id_contact">
                                <FormInput
                                    id="contact.id_contact"
                                    readOnly={true}
                                    name="contact.id_contact"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Id" id="id_unity">
                                <FormInput
                                    id="id_unity"
                                    readOnly={true}
                                    name="id_unity"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Id Public" id="id_public">
                                <FormInput
                                    id="id_public"
                                    readOnly={true}
                                    name="id_public"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Código" id="id_unity_in_regional">
                                <FormInput
                                    id="id_unity_in_regional"
                                    readOnly={true}
                                    name="id_unity_in_regional"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={2} className="px-2">
                            <FormInputGroup label="Regional" id="id_regional">
                                <select id="id_regional" onChange={(e) => setSelectedRegional(Number(e.target.value))} value={selectedRegional} disabled={(true)}>
                                    <option value="0">Selecione</option>
                                    {regional.map((item) => (
                                        <option value={item.id_regional}>{item.regional_name}</option>
                                    ))}
                                </select>
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="Razão social" id="contact_name">
                                <FormInput
                                    disabled={(true)}
                                    id="contact_name"
                                    name="contact.contact_name"
                                    control={control}
                                    rules={{ required: "Razão social é obrigatório" }}
                                />
                                {errors.contact?.contact_name?.message && (
                                    <p className="p-form-error">{errors.contact.contact_name?.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Nome fantasia" id="nickname">
                                <FormInput
                                    disabled={(true)}
                                    id="nickname"
                                    name="contact.nickname"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={2} className="px-2">
                            <FormInputGroup label={"CNPJ"} id="contact_document_1">
                                <FormInputMask
                                    disabled={(true)}
                                    id="contact_document_1"
                                    name="contact.document_1"
                                    control={control}
                                    rules={{ required: true }}
                                    mask={"99.999.999/9999-99"}
                                />
                                {errors.contact?.document_1 && (
                                    <p className="p-form-error">{"CNPJ inválido"}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={2} className="px-2">
                            <FormInputGroup label="CEP" id="zipcode">
                                <FormInput
                                    disabled={(true)}
                                    id="zipcode"
                                    name="address.zipcode"
                                    type="text"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="UF" id="address_uf">
                                <FormInput
                                    id="address_uf"
                                    name="state"
                                    control={control}
                                    readOnly={true}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Cidade" id="address_city">
                                <FormInput
                                    id="address_city"
                                    name="city"
                                    control={control}
                                    readOnly={true}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="Bairro" id="address_neighborhood">
                                <FormInput
                                    disabled={(true)}
                                    id="address_neighborhood"
                                    name="address.neighborhood"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Endereço" id="address_line_1">
                                <FormInput
                                    disabled={(true)}
                                    id="address_line_1"
                                    name="address.line_1"
                                    control={control}
                                    rules={{ required: 'Endereço é obrigatório' }}
                                />
                                {errors.address?.line_1?.message && (
                                    <p className="p-form-error">{errors.address?.line_1?.message}</p>
                                )}
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="Complemento" id="line_2">
                                <FormInput
                                    disabled={(true)}
                                    id="line_2"
                                    name="address.line_2"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={1} className="px-2">
                            <FormInputGroup label="Numero" id="address_number">
                                <FormInput
                                    disabled={(true)}
                                    id="address_number"
                                    name="address.number"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={4} className="px-2">
                            <FormInputGroup label="E-mail" id="email">
                                <FormInput
                                    disabled={(true)}
                                    id="email"
                                    name="contact.email"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Telefone 1" id="phone_1">
                                <FormInputMask
                                    disabled={(true)}
                                    id="phone_1"
                                    name="contact.phone_1"
                                    control={control}
                                    rules=""
                                    mask="(99) 9 9999-9999"
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Telefone 2" id="phone_2">
                                <FormInputMask
                                    disabled={(true)}
                                    id="phone_2"
                                    name="contact.phone_2"
                                    control={control}
                                    rules=""
                                    mask="(99) 9 9999-9999"
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Contato 1" id="additional_name_1">
                                <FormInput
                                    disabled={(true)}
                                    id="additional_name_1"
                                    name="contact.additional_name_1"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                        <Col lg={3} className="px-2">
                            <FormInputGroup label="Contato 2" id="additional_name_2">
                                <FormInput
                                    disabled={(true)}
                                    id="additional_name_2"
                                    name="contact.additional_name_2"
                                    control={control}
                                    rules=""
                                />
                            </FormInputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default UnityList;