import api from './functions/api';
import fileDownload from 'js-file-download';
import { verifyData, verifyDataList } from './functions/verify-response';
import Accounts from './model/accounts-return';
import { AccountPeriodChart } from './model/account-data-chart-period';

export async function getAccounts(filters: any): Promise<Accounts | undefined> {
	return api.get('/lists/accounts/?' + new URLSearchParams(filters).toString()).then(response => {
		return verifyData(response);
	}).catch(error => {
		return verifyData(error.response);
	});
}

export async function getAccountsDataChartPeriod(filters: any): Promise<Partial<AccountPeriodChart> | undefined> {
	return api.get('/lists/chartAccountsData/?' + new URLSearchParams(filters).toString()).then(response => {
		return verifyData(response);
	}).catch(error => {
		return verifyData(error.response);
	});
}
  
export async function getAccountsPdf(filters: any): Promise<object | void> {
	return api.get('/lists/download_accounts/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
		try {
			fileDownload(response.data, 'consulta-de-contas.pdf');
		} catch (error: any) {
			console.log(error);
		}
	}).catch(error => {
		console.log(error);
	});
}