import api from './functions/api';
import { verifyDataList, verifyData, verifyDataPagination } from './functions/verify-response';
import Unity from './model/unity';
import { Saldo } from './model/saldoDetail';
import fileDownload from 'js-file-download';

export async function getSaldo(filtros?: any): Promise<any | undefined> {
  return api.post('/unity/saldo/', filtros).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}


export async function getSaldoPdf(saldoArray?: Array<Saldo>): Promise<Array<Saldo>> {
  return api.post('/unity/pdf/', saldoArray, { responseType: 'blob' }).then(response => {
    fileDownload(response.data, 'saldo_unidades.pdf')
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

interface PaginationResponseUnity {
  list: Array<Unity>;
  total: number;
}
export async function getUnitiesList(filters: any): Promise<PaginationResponseUnity> {
  return api.get('/unity/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export async function getUnities(): Promise<Array<Unity>> {
  return api.get('/unity/?inactive=1').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

export async function getUnity(id: number): Promise<Unity | undefined> {
  return api.get('/unity/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}
