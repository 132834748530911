import React, { memo, useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';

import { getDataToken } from '../../services/functions/auth';
import { getUnity, isAdminMaster, isAdminUnidade } from '../../services/functions/user-info';
import Regional from '../../services/model/regional';
import UnityDetail from '../../services/model/unityFilter';
import { getUnitiesList } from '../../services/unity';
import FormInputGroup from '../FormInputGroup';
import { ArrowIcon } from '../Icons';
import SelectRegional from '../SelectRegional';

import './style.css';

interface SelectUnityProps {
    selectedUnity: UnityDetail | undefined;
    setSelectedUnity: React.Dispatch<React.SetStateAction<UnityDetail | undefined>>;
    selectedRegional: Regional | undefined;
    setSelectedRegional?: React.Dispatch<React.SetStateAction<Regional | undefined>>;
    id: string;
    filter?: boolean;
    disabled?: boolean;
    size?: number;
    labelName?: string;
    all?: boolean;
}

const SelectUnity: React.FC<SelectUnityProps> = ({ selectedUnity, setSelectedUnity, selectedRegional, setSelectedRegional, id, filter, disabled, size, labelName, all, children }) => {

    const [listUnity, setListUnity] = useState<Array<UnityDetail>>([]);
    const [showUnity, setShowUnity] = useState<boolean>(false);
    let timeOutId: number = 0;

    const unityFilters = {
        id_status: '1',
        contact_name: '',
        id_unity: '',
        pagination: '1',
        id_regional: '',
    };

    function changeFilters(newValue?: string) {

        if (!disabled) {
            if (selectedRegional === undefined) {
                setListUnity([]);
            } else if (selectedRegional.id_regional === -1) {
                let newFilters = unityFilters;
                if (newValue) newFilters.contact_name = newValue;
                getUnitiesList(newFilters).then((response) => setListUnity(response.list));
            } else {
                let newFilters = unityFilters;
                if (selectedRegional && typeof (selectedRegional) !== 'boolean' && selectedRegional.id_regional) newFilters.id_regional = selectedRegional.id_regional.toString();
                if (newValue) newFilters.contact_name = newValue;
                getUnitiesList(newFilters).then((response) => setListUnity(response.list));
            }
        }
    }

    useEffect(() => {
        if (isAdminUnidade()) {
            let token = getDataToken();
            getUnity().then((response) => {
                setSelectedUnity({
                    id_unity: token?.adm?.adm_id_unity,
                    contact_name: response
                })
            })
        }
    }, []);

    useEffect(() => {
        if (!isAdminUnidade()) {
            setSelectedUnity(undefined)
        }
    }, [selectedRegional])

    return (
        <>
            <Row>

                {typeof (selectedRegional) !== 'boolean' && setSelectedRegional && (
                    <SelectRegional selectedRegional={selectedRegional} setSelectedRegional={setSelectedRegional} id={"unity_" + id} disabled={disabled || !isAdminMaster() ? true : false} filter={filter} size={size ? size : 3} all={all} />
                )}
                {selectedRegional &&
                    <Col lg={size ? size : 3} className="px-2"
                        onMouseEnter={() => { clearTimeout(timeOutId); changeFilters(''); }}
                        onMouseLeave={() => { timeOutId = Number(setTimeout(() => { setShowUnity(false) }, 600)) }}
                    >
                        <FormInputGroup label={labelName ? labelName : "Unidade"} id={"unity_" + id}>
                            <div className="ts-select-list"   >
                                <span className={"show-selected" + (disabled || isAdminUnidade() ? ' disabled' : '')} onClick={() => !disabled && setShowUnity(!showUnity)}>
                                    {selectedUnity?.contact_name ? selectedUnity.contact_name : (filter ? "Todos" : "Selecione")}
                                    <ArrowIcon />
                                </span>
                                {showUnity && !disabled && !isAdminUnidade() && (
                                    <div className="show-list">
                                        <input className="search-list" type="text" placeholder="Pesquisar" onChange={(e) => changeFilters(e.target.value)} id={"unity_search_" + id} />
                                        <div className="list">
                                            {selectedRegional.id_regional === 0 && (
                                                <div className="item">
                                                    <p>Selecione a unidade</p>
                                                </div>
                                            )}
                                            {!!listUnity.length && (
                                                <div className="item" onClick={() => { setSelectedUnity(undefined); setShowUnity(false); changeFilters(''); }}>
                                                    <p>{filter ? "Todos" : "Selecione"}</p>
                                                </div>
                                            )}
                                            {!isAdminUnidade() && !!listUnity.length && all && (
                                                <div className="item" onClick={() => { setSelectedUnity({ id_unity: -1, contact_name: 'Todas as unidades' }); setShowUnity(false); changeFilters(''); }}>
                                                    <p>{'Todas as Unidades'}</p>
                                                </div>
                                            )}
                                            {!!listUnity && listUnity.map((item, key) => (
                                                <div key={item.id_unity} className="item" onClick={() => { setSelectedUnity(item); setShowUnity(false); changeFilters(''); }}>
                                                    <p>{item.contact_name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </FormInputGroup>
                    </Col>
                }

                {!!children &&
                    children
                }
            </Row>
        </>
    );
}
export default SelectUnity;