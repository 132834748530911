import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { getGeneralBalance, getGeneralBalancePDF } from '../../services/det_extract';

import LinkIcon from '../../components/LinkIcon';
import PageTitle from '../../components/PageTitle';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import cashRegisterIcon from '../../assets/images/icons/cash-register.png';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import Alert from '../../components/Alert';
import FormInputGroup from '../../components/FormInputGroup';
import FormInputMask from '../../components/FormInputMask';
import Loading from '../../components/Loading';
import GeneralBalance from '../../services/model/generalBalance';
import './styles.css';

const DetExtractGeneralList: React.FC = () => {

    const pageId = 10;


    const [loading, setLoading] = useState<boolean>(false);
    const [selectedProntuary, setSelectedProntuary] = useState<string | undefined>(undefined)
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined)

    const [listGeneralBalance, setListGeneralBalance] = useState<Array<GeneralBalance>>([])
    const [generalBalance, setGeneralBalance] = useState<GeneralBalance>();
    const [nRegisters, setNRegisters] = useState<number>(0);
    const [errorSubmit, setErrorSubmit] = useState<string>();


    const { control, handleSubmit, errors, reset, setValue } = useForm<GeneralBalance>({ defaultValues: generalBalance });

    const onConsult = handleSubmit((data) => {
        setErrorSubmit('')
        if (data.prontuario && data.prontuario?.length < 6) { setErrorSubmit('Prontuário deve ter 6 dígitos') }
        else if (data.prontuario && data.contact_name) { setErrorSubmit('Apenas um campo deve ser preenchido') }
        else if (!data.prontuario && !data.contact_name) { setErrorSubmit('Deve ser informado pelo menos um dos campos') } else {
            let date = {
                prontuario: data.prontuario,
                contact_name: data.contact_name
            }
            setLoading(true)
            getGeneralBalance(date).then((response) => {
                if (response.list[0]) {
                    let active = !!response.list.find((item) => {
                        return item.active === true
                    })
                    let haveBalance = !!response.list.find((item) => {
                        return item.haveBalance === true
                    })
                    if (active === false && !haveBalance) {
                        setOpenAlertPermission(true)
                        setMessagePermission('Você não tem acesso aos dados desse detento, pois o mesmo está inativo e/ou não tem saldo em sua unidade/regional')
                        handleClean()
                    } else if ((active === false && haveBalance) || active == true) {
                        setListGeneralBalance(response.list)
                        if (response.list[0].contact_name) setSelectedItem(response.list[0].contact_name)
                        if (data.prontuario) { setSelectedProntuary(data.prontuario) } else { setSelectedProntuary(response.list[0].prontuario) }
                        setNRegisters(response.total)
                        setLoading(false)
                    }
                }
                else {
                    handleClean()
                    setOpenAlertDelete(true)
                }
            });
        }
    });

    function handleClean() {
        setNRegisters(0)
        setSelectedItem('')
        setListGeneralBalance([])
        setLoading(false)
    }

    function handleExportSaldo() {
        setLoading(true);
        let dados = {
            contact_name: selectedItem,
            prontuario: selectedProntuary,
        }
        getGeneralBalancePDF(dados).then(() => setLoading(false));
    }

    useEffect(() => {
        document.addEventListener('keypress', function (e) {
            if (e.which == 13) {
                onConsult()
            }
        }, false);
    }, []);


    const [openAlertDelete, setOpenAlertDelete] = useState<boolean>(false);
    const [openAlertPermission, setOpenAlertPermission] = useState<boolean>(false);
    const [msgPermission, setMessagePermission] = useState<string>('')

    return (
        <div id="page-det-extract">

            <Alert showAlert={openAlertDelete} setShowAlert={setOpenAlertDelete} message={<p className="alert-error">{"Interno não possuí saldo em nenhuma unidade"}</p>} />

            <Alert showAlert={openAlertPermission} setShowAlert={setOpenAlertPermission} message={<p className="alert-error">{msgPermission}</p>} />

            <main>

                <div className="ts-gutter-t container">
                    <Row>
                        <Col lg={6}>
                            <PageTitle text={"Saldo do detento em Geral"} icon={cashRegisterIcon} />
                        </Col>
                    </Row>
                    <Row className="ts-gutter-t align-items-center mx-n2">
                        <Col lg={2} className="px-2 py-1">
                            <FormInputGroup label="Prontuário" id="prontuario">
                                <FormInputMask
                                    id="prontuario"
                                    name="prontuario"
                                    control={control}
                                    mask={'999999'}
                                    maxLength={6}
                                    rules=''
                                />
                            </FormInputGroup>
                        </Col>

                    </Row>
                    <Col lg={12}>
                        {errorSubmit && (
                            <p className="p-form-error text-center mb-3">{errorSubmit}</p>
                        )}
                        <div className="ts-submit"><button disabled={loading} onClick={onConsult}>{loading ? 'Buscando...' : 'Consultar'}</button></div>
                    </Col>

                    {(!!nRegisters &&
                        <>
                            <Row>
                                <Col lg={12} className='text-right'>
                                    <button className="ts-link-icon" onClick={handleExportSaldo} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>
                                </Col>
                            </Row>
                            <div className="ts-gutter" style={loading ? { opacity: '0.3' } : { opacity: '1.0' }}>
                                <Table striped size='sm' className="ts-items-table" style={{ textAlign: 'center' }}>
                                    <thead>
                                        <tr className='inmateTittleFormated'>
                                            <th colSpan={7} className="inmateTittle">{selectedItem?.toUpperCase()}</th>
                                            <th colSpan={1} className="inmateTittle" style={{ textAlign: 'center' }}>{selectedProntuary}</th>
                                        </tr>
                                        <tr>
                                            <th className='rightBorder bottom' rowSpan={2} style={{ paddingBottom: '20px', fontSize: '20px' }}>Unidade</th>
                                            <th className='rightBorder bottom' colSpan={3} >Disponível</th>
                                            <th className='rightBorder bottom' colSpan={3} >Poupança</th>
                                            <th className='bottom' rowSpan={2} style={{ paddingBottom: '20px', fontSize: '18px' }}>Saldo Final</th>
                                        </tr>
                                        <tr>
                                            <th className='bottom'>Débito</th>
                                            <th className='bottom'>Credito</th>
                                            <th className='rightBorder bottom'>Saldo</th>
                                            <th className='bottom'>Débito</th>
                                            <th className='bottom'>Credito</th>
                                            <th className='rightBorder bottom'>Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!!listGeneralBalance && listGeneralBalance.map((item, key) => (
                                            <tr>
                                                <td className='rightBorder' ><p>{item.unity_name}</p></td>
                                                <td><p>R$ {item.availableDebit}</p></td>
                                                <td><p>R$ {item.availableCred}</p></td>
                                                <td className='rightBorder'><p>R$ {item.availableTotal}</p></td>
                                                <td><p>R$ {item.savingsDebit}</p></td>
                                                <td><p>R$ {item.savingsCred}</p></td>
                                                <td className='rightBorder' ><p>R$ {item.savingsTotal}</p></td>
                                                <td><p>R$ {item.finalTotal}</p></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    )}
                </div>
            </main >
            {loading && (<Loading />)}
        </div >
    )
}
export default DetExtractGeneralList;

