import React from 'react';

interface FormInputGroupProps {
	label?: string;
	id: string;
	required?: boolean;
}

const FormInputGroup: React.FC<FormInputGroupProps> = ({ label, id, required, children }) => (
	<div className="ts-input-group form-group">
		{!!label && (<label className={required ? 'required' : ''} htmlFor={id}>{label} </label>)}
		{children}
	</div>
);

export default FormInputGroup;