import { Col, Row } from "react-bootstrap";
import firstPage from '../../assets/images/icons/rewind.svg';
import prevPage from '../../assets/images/icons/left.svg';
import lastPage from '../../assets/images/icons/forward.svg';
import nextPage from '../../assets/images/icons/right.svg';
import { useEffect, useState } from "react";


interface PaginationCP {

    pagination: number,
    filter: any,
    verifyFilters: Function,
    filterKey: string,

}


const PaginationCP: React.FC<PaginationCP> = ({ pagination, filter, verifyFilters, filterKey }) => {

    
    const [paginationNumbers, setPaginationNumbers] = useState<React.FC>();

    function updatePagination(qtd: number) {
        let paginationArrayPag: Array<number> = [];
        for (let i = 0; i < qtd; i++) {
            paginationArrayPag.push(i + 1);
        }
        setPaginationNumbers(() => {
            
            return (
                <>
                    {paginationArrayPag.map((item, key) => (
                        <div key={key} onClick={() => { verifyFilters(filterKey, item.toString()); }} className={'number' + (item == filter ? ' active' : '')}>
                            {item}
                        </div>
                    ))}
                </>
            )
        });
    } 
   
    
    useEffect(() => {
        updatePagination(pagination);
    },[filter, pagination])
        
    
    
    return (
        
        <>{

        pagination > 1 && (

            <Row className="ts-pagination d-flex justify-content-center text-center">
                <Col sm={'12'}>
                    <div><p>Página {filter} de {pagination}</p></div>
                </Col>
                <Col sm={'auto'} className="text-right">
                    <Row>
                        <Col>
                            <div style={filter > 1 ? { pointerEvents: 'visible' } : { pointerEvents: 'none' }} onClick={() => { verifyFilters(filterKey, '1'); updatePagination(pagination) }}><img src={firstPage} alt="Primeira página" title="Primeira página" /></div>
                        </Col>
                        <Col>
                            <div style={filter > 1 ? { pointerEvents: 'visible' } : { pointerEvents: 'none' }} className="margin" onClick={() => { verifyFilters(filterKey, (Number(filter) - 1).toString()); updatePagination(pagination) }}><img src={prevPage} alt="Página anterior" title="Página anterior" /></div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={pagination < 26 ? 'auto' : '6'}>
                    <div className="d-flex align-items-center content scroll" style={pagination > 25 ? { overflowX: 'scroll' } : {}}>
                        {paginationNumbers}
                    </div>
                </Col>
                <Col sm={'auto'} className="text-left">
                    <>
                        <Row>
                            <Col>
                                <div style={filter < pagination ? { pointerEvents: 'visible' } : { pointerEvents: 'none' }} onClick={() => { verifyFilters(filterKey, (Number(filter) + 1).toString()); updatePagination(pagination) }}><img src={nextPage} alt="Próxima página" title="Próxima página" /></div>
                            </Col>
                            <Col>
                                <div style={filter < pagination ? { pointerEvents: 'visible' } : { pointerEvents: 'none' }} className="margin" onClick={() => { verifyFilters(filterKey, pagination.toString()); updatePagination(pagination) }}><img src={lastPage} alt="Última página" title="Última página" /></div>
                            </Col>
                        </Row>
                    </>

                </Col>
            </Row>
        )
    }
    </>)
}

export default PaginationCP;