import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';

import FormInputGroup from '../../components/FormInputGroup';
import { getDetento, getProntuario } from '../../services/contact';
import ContactDetail from '../../services/model/contact-detail';
import Unity from '../../services/model/unity';
import FormInputMask from '../FormInputMask';

import './style.css';

interface SelectContactProps {
    selectedContact: ContactDetail | undefined;
    setSelectedContact: React.Dispatch<React.SetStateAction<ContactDetail | undefined>>;
    selectedProntuario?: number | string | undefined;
    setSelectedProntuario: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedUnity: Unity | boolean | undefined;
    id: string;
    filter?: boolean;
    disabled?: boolean;
    size?: number;
    labelName?: string;
}

const SelectDetento: React.FC<SelectContactProps> = ({ setSelectedContact, setSelectedProntuario, selectedProntuario, selectedUnity, id, filter, disabled, size, labelName }) => {

    const [listContact, setListContact] = useState<Array<ContactDetail>>([]);
    const [showContact, setShowContact] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [contact, setContact] = useState<ContactDetail>()

    const { control } = useForm<any>({});

    const contactFilters = {
        id_unity: '',
        contact_nome: ''
    };
    function changeFilters(newValue?: string) {
        if (selectedUnity === undefined) {
            setListContact([]);
        } else {
            let newFilters = contactFilters;
            if (selectedUnity && typeof (selectedUnity) !== 'boolean' && selectedUnity.id_unity) newFilters.id_unity = selectedUnity.id_unity.toString();
            if (newValue) newFilters.contact_nome = newValue;
            getDetento(newFilters).then((response) => {
                setListContact(response.list)
            });
        }
    }

    useEffect(() => {
        changeFilters();
        setListContact([])
        setContact(undefined)
    }, [selectedUnity]);


    useEffect(() => {
        let contact: ContactDetail = {
            numserie: '',
            contact_name: ''
        }
        if (String(selectedProntuario).length === 6) {
            let filter: any = {
                prontuario: String(selectedProntuario)?.split(" ").join(""),
                unidade: typeof (selectedUnity) !== 'boolean' ? selectedUnity?.id_unity : ''
            }
            getProntuario(filter).then((response) => {
                if (response?.contact_name) {
                    contact.numserie = response.numserie
                    contact.contact_name = response.contact_name
                    setSelectedContact(contact)
                    setContact(contact)
                }
            });
        } else {
            setSelectedContact(contact)
            setContact(contact)
        }
        changeFilters();
    }, [selectedProntuario])

    function selectContact(item: ContactDetail) {
        let filter: any = {
            nome_contato: item?.contact_name,
            unidade: typeof (selectedUnity) !== 'boolean' ? selectedUnity?.id_unity : ''
        }
        getProntuario(filter).then((response) => {
            if (response) {
                setSelectedProntuario(response.prontuario?.split(" ").join(""))
                setSelectedContact(item)
            }
        });
        changeFilters();
    }

    return (
        <>
            {selectedUnity &&
                <>
                    <Col lg={1} className="px-2">
                        <FormInputGroup label="Prontuário" id="prontuario">
                            <FormInputMask
                                id="prontuario"
                                name="prontuario"
                                control={control}
                                rules=""
                                value={selectedProntuario}
                                mask={'999999'}
                                onChange={(e) => {
                                    setSelectedProntuario(e.target.value)
                                }}

                            />
                        </FormInputGroup>
                    </Col>
                    <Col lg={size ? size : 3} className="px-2" onMouseLeave={() => { setShowContact(false); changeFilters(''); }}>
                        <FormInputGroup label={labelName ? labelName : "Nome do Detento"} id={"contact_" + id}>
                            <div className="ts-select-list">
                                <span className={"show-selected" + (disabled ? ' disabled' : '')} onClick={() => !disabled && setShowContact(!showContact)}>
                                    {contact?.contact_name && selectedProntuario ? contact.contact_name : (filter ? "Todos" : "Selecione")}
                                </span>
                                {showContact && !disabled && (
                                    <div className="show-list">
                                        <input className="search-list" type="text" placeholder="Pesquisar" onChange={(e) => changeFilters(e.target.value)} id={"contact_search_" + id} />
                                    <div className="list">
                                        {//@ts-ignore
                                            selectedUnity === 0 && (
                                                <div className="item">
                                                    <p>Selecione a unidade</p>
                                                </div>
                                            )}
                                            {!!listContact.length && (
                                                <div className="item" onClick={() => { setShowContact(false); changeFilters(''); setSelectedProntuario(''); }}>
                                                    <p>{filter ? "Todos" : "Selecione"}</p>
                                                </div>
                                            )}
                                            {!!listContact && listContact.map((item, key) => (
                                                <div className="item" onClick={() => { selectContact(item); setContact(item); setShowContact(false); changeFilters(''); }} key={key}>
                                                    <p>{item.contact_name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </FormInputGroup>
                    </Col>
                </>
            }
        </>
    );
}
export default SelectDetento;

