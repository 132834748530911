import React, { InputHTMLAttributes, Key } from 'react';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
	options: Array<string>;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Radio: React.FC<RadioButtonProps> = ({ options, value, setValue, ...props }) => {
	return (
		<>
			{options.map((option) => (
				<label key={option}>
					<input
						type="radio"
						value={option}
						checked={value === option}
						onChange={({ target }) => setValue(target.value)}
						{...props}
					/>
					{option}
				</label>
			))}
		</>
	);
};

export default Radio;
