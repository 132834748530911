import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useForm } from 'react-hook-form';

import configIcon from '../../assets/images/icons/configs.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import FormInput from '../../components/FormInput';
import FormInputGroup from '../../components/FormInputGroup';
import LinkIcon from '../../components/LinkIcon';
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
import SelectUnity from '../../components/SelectFilterUnity';
import { getConvenio, getConvenios } from '../../services/convenio';
import { Convenio } from '../../services/model/convenio';
import Regional from '../../services/model/regional';
import Unity from '../../services/model/unity';

import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import './styles.css';

const ConvenioList: React.FC = () => {
    const pageId = 16;
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [listConvenio, setListConvenio] = useState<Array<Convenio>>([]);
    const [nConvenio, setNConvenio] = useState<number>(0)
    const [selectedItem, setSelectedItem] = useState<number>(0)
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openModalAdd, setOpenModalAdd] = useState<boolean>(false)


    const [convenio, setConvenio] = useState<Convenio>();

    const { control } = useForm<Convenio>({ defaultValues: convenio })

    const [filters, setFilters] = useState<any>({
        contato: '',
        convenio: '',
        dtInico_from: '',
        dtInicio_to: '',
        dtFim_from: '',
        dtFim_to: '',
        pagination: '1',
    })

    const verifyFilters = useCallback((key: string, newValue: string) => {
        let newFilters = filters;
        if (key === 'dtInicio_from' || key === 'dtInicio_to' || key === 'dtFim_from' || key === 'dtFim_to') {
            let value = moment(newValue).format('YYYY-MM-DD');
            newValue = value !== 'Invalid date' ? value : '';
        }
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(filters);
    }, [filters]);

    function screenUpdate() {
        if (selectedUnity?.id_unity) {
            setLoading(true);
            let data = {
                ...filters,
                id_unity: selectedUnity.id_unity
            }
            getConvenios(data).then((response) => {
                if (response) {
                    setListConvenio(response)
                    setNConvenio(response.length)
                    setLoading(false)
                }
            })
        }
    }
    useEffect(() => {
        screenUpdate()
    }, [selectedUnity])



    useEffect(() => {
        if (selectedItem && selectedItem != 0) {
            let data = {
                contato: selectedItem,
                id_unity: selectedUnity?.id_unity
            }
            getConvenio(data).then((response) => {
                setConvenio(response)
                setOpenModalAdd(true)
            })
        }

    }, [selectedItem])


    function handleCloseModal() {
        setSelectedItem(0)
        setOpenModalAdd(false)
    }


    return (
        <div id="page-saldo">
            {loading && (<Loading />)}
            <main className="container">
                <div className="ts-gutter">
                    <PageTitle text="Convênios" icon={configIcon} />
                </div>
                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact"
                    size={4}
                    disabled={loading}
                    all={false}
                />
                {selectedUnity?.id_unity &&
                    <>
                        <Row>
                            <Col lg={10}>
                                <Row className="justify-content-between ">
                                    <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nConvenio} registros encontrados</p></Col>
                                </Row>
                            </Col>
                            <Col sm="auto" className="ts-gutter-t">
                                <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                    <LinkIcon text="Pesquisar" icon={searchIcon} />
                                </button>
                            </Col>
                        </Row>

                        <Collapse in={openSearch} appear={true}>
                            <div className="ts-search ts-form-box ts-gutter ">
                                <p className="ts-page-text mb-3">Pesquisar por:</p>

                                <Row className="mx-n2" onKeyUp={(e: any) => { if (e.keyCode == 13 && openSearch) { screenUpdate() } }}>
                                    <Col lg={1} className="px-2">
                                        <FormInputGroup label="Codigo" id="contato">
                                            <input type="text" autoFocus id="contato" defaultValue={filters.contato} key={filters.contato} onChange={(e) => verifyFilters('contato', e.target.value)} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Nome" id="convenio">
                                            <input type="text" autoFocus id="convenio" defaultValue={filters.convenio} key={filters.convenio} onChange={(e) => verifyFilters('convenio', e.target.value)} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={6} className="px-2">
                                        <Row className="mx-2">
                                            <Col className="px-2">
                                                <FormInputGroup label="Data de início (De)" id="dtInicio_from">
                                                    <input type="date" autoFocus id="dtInicio_from" defaultValue={filters.dtInicio_from} key={filters.dtInicio_from} onChange={(e) => verifyFilters('dtInicio_from', e.target.value)} />
                                                </FormInputGroup>
                                            </Col>
                                            <Col className="px-2">
                                                <FormInputGroup label="Data de início (Até)" id="dtInicio_to">
                                                    <input type="date" autoFocus id="dtInicio_to" defaultValue={filters.dtInicio_to} key={filters.dtInicio_to} onChange={(e) => verifyFilters('dtInicio_to', e.target.value)} />
                                                </FormInputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mx-2">
                                            <Col className="px-2">
                                                <FormInputGroup label="Data de término (De)" id="dtFim_from">
                                                    <input type="date" autoFocus id="dtFim_from" defaultValue={filters.dtFim_from} key={filters.dtFim_from} onChange={(e) => verifyFilters('dtFim_from', e.target.value)} />
                                                </FormInputGroup>
                                            </Col>
                                            <Col className="px-2">
                                                <FormInputGroup label="Data de término (Até)" id="dtFim_to">
                                                    <input type="date" autoFocus id="dtFim_to" defaultValue={filters.dtFim_to} key={filters.dtFim_to} onChange={(e) => verifyFilters('dtFim_to', e.target.value)} />
                                                </FormInputGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col lg={2} className="px-0 m-auto">
                                        <FormInputGroup id="search" >
                                            <div className="ts-submit">
                                                <button id="search" disabled={loading} onClick={screenUpdate} >  {loading ? 'Carregando...' : 'Filtrar'} </button>
                                            </div>
                                        </FormInputGroup>
                                    </Col>
                                </Row>

                            </div>
                        </Collapse>
                        <div className="ts-gutter " style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>
                            <Table striped hover size="sm" className="ts-items-table" style={{ cursor: 'pointer' }}>
                                <thead >
                                    <tr>
                                        <th><p>Cód</p></th>
                                        <th><p>Nome</p></th>
                                        <th><p>Data Início</p></th>
                                        <th><p>Data Fim</p></th>
                                        <th><p>Nº Processo</p></th>
                                        <th><p>Total Vagas</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listConvenio && listConvenio.map((item, key) => (
                                        <tr onClick={() => setSelectedItem(item.contato ? item.contato : 0)} key={key}>
                                            <td><p>{item.contato}</p></td>
                                            <td><p>{item.convenio}</p></td>
                                            <td><p>{item.data_inicio}</p></td>
                                            <td><p>{item.data_fim}</p></td>
                                            <td><p>{item.processo}</p></td>
                                            <td><p>{Number(item.num_vagas)}</p></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div >
                    </>
                }
            </main >
            <Modal dialogClassName="ts-modal ts-modal-small" show={openModalAdd} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <PageTitle text={"Convênio"} icon={configIcon} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <Row className="mx-n2 " as="fieldset" disabled={true}>
                            <Col lg={5} className="px-2">
                                <FormInputGroup label="Nome" id="convenio">
                                    <FormInput
                                        disabled={true}
                                        id="convenio"
                                        name="convenio"
                                        control={control}
                                        rules=""
                                        defaultValue={convenio?.convenio}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Início" id="data_inicio">
                                    <FormInput
                                        disabled={true}
                                        id="data_inicio"
                                        name="data_inicio"
                                        control={control}
                                        rules=""
                                        defaultValue={convenio?.data_inicio}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Fim" id="data_fim">
                                    <FormInput
                                        disabled={true}
                                        id="data_fim"
                                        name="data_fim"
                                        control={control}
                                        rules=""
                                        defaultValue={convenio?.data_fim}
                                    />
                                </FormInputGroup>
                            </Col>
                            <Col lg={3} className="px-2">
                                <FormInputGroup label="Nº Processo" id="processo">
                                    <FormInput
                                        disabled={true}
                                        id="processo"
                                        name="processo"
                                        control={control}
                                        rules=""
                                        defaultValue={convenio?.processo}
                                    />
                                </FormInputGroup>
                            </Col>
                            <div className="ts-gutter convenio-table" style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>
                                <Table striped hover size="sm" className="ts-items-table ">
                                    <thead >
                                        <tr>
                                            <th><p>Nome</p></th>
                                            <th><p>Total Vagas</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {convenio && convenio.grpData && convenio.grpData.map((item, key) => (
                                            <tr key={key}>
                                                <td><p>{item.descricao}</p></td>
                                                <td><p>{Number(item.vagas)}</p></td>
                                            </tr>
                                        ))}
                                        {convenio &&
                                            <tr style={{ background: 'var(--color-secondary)' }}>
                                                <th>Total</th>
                                                <th>{Number(convenio?.num_vagas)}</th>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                            </div >
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div >
    );
}

export default ConvenioList;