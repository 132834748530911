import api from './functions/api';
import fileDownload from 'js-file-download';
import { verifyDataPagination, verifyDataList, verifyData, verifyResponse } from './functions/verify-response';
import { CashInfo } from './model/cash-info';
import CashReturn from './model/cash-return';

export async function getCashHandling(filters: any): Promise<CashReturn> {
	return api.get('/lists/cash_handling/?' + new URLSearchParams(filters).toString()).then(response => {
		return verifyDataPagination(response);
	}).catch(error => {
		return verifyDataPagination(error.response);
	});
}

export function getCashHandlingPdf(filters: any): Promise<object | void> {
	return api.get('/lists/download_cash_handling/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
		try {
			fileDownload(response.data, 'movimentacao.pdf');
		} catch (error: any) {
			console.log(error);
		}
	}).catch(error => {
		console.log(error);
	});
}
interface CashListPaginationResponse {
	list: Array<Partial<CashInfo>>
	total: number
}

export async function getCashInfo(filters: any): Promise<CashListPaginationResponse> {
	return api.get('/dados/cash/?' + new URLSearchParams(filters).toString()).then(response => {
		return verifyDataPagination(response);
	}).catch(error => {
		return verifyDataPagination(error.response);
	});
}


interface PaginationResponse {
	list: Array<Partial<CashInfo>>;
	total: number;
}

export async function getFilteredByBillPlan(data: any): Promise<PaginationResponse> {
	return api.post('/lists/filter_bill_plan/', data).then(response => {
		return verifyDataPagination(response);
	}).catch(error => {
		return verifyDataPagination(error.response);
	});
}

