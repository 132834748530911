import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Collapse, Row, Table } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import { paramIcons } from '../../assets/beans/binds';
import accountsIcon from '../../assets/images/icons/cash-register.png';
import printIcon from '../../assets/images/icons/printer.svg';
import searchIcon from '../../assets/images/icons/search.svg';
import Alert from '../../components/Alert';
import AccountListCharts from '../../components/Charts/AccoutList/AccountListCharts';
import DropDownButton from '../../components/DropDownButton';
import FormInputGroup from '../../components/FormInputGroup';
import LinkIcon from '../../components/LinkIcon';
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
import PaginationCP from '../../components/PaginationCP';
import SelectUnity from '../../components/SelectFilterUnity';
import SelectGeneralData from '../../components/SelectGeneralData';
import TdResponsive from '../../components/TdResponsive';
import { getAccounts, getAccountsDataChartPeriod, getAccountsPdf } from '../../services/accounts';
import { AccountPeriodChart } from '../../services/model/account-data-chart-period';
import AccountsReturn from '../../services/model/accounts-return';
import BarChartObject from '../../services/model/bar-chart';
import Regional from '../../services/model/regional';
import Unity from '../../services/model/unity';

import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import './style.css';
import { FiltersAccountList } from '../../services/model/AccountList';
import { getBankAccounts } from '../../services/releases';
import BankAccount from '../../services/model/bank-account';

/*
let colorsValuesChrec: Array<string> = [];
let colorsValuesChpag: Array<string> = [];
*/



const AccountsList: React.FC = () => {


    const itemsPerPage = 10;
    const [paginationPag, setPaginationPag] = useState<number>(0);
    const [paginationRec, setPaginationRec] = useState<number>(0);
    const [listAccounts, setAccounts] = useState<AccountsReturn>();
    const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
    const [filters, setFilters] = useState<FiltersAccountList>({
        id_regional: '',
        id_unity: '',
        dt_From: '', //data emissao
        dt_To: '',
        prontuario: '',
        descricao: '',
        conta: '',
        banco: '',
        numconta: '',
        valor_From: '',
        valor_To: '',
        tipoContas: '-1', //1 = pagar, 2 = receber
        status: '', //A = ativo, I = inativo
        detento: '', //1 = sim, 2 = não
        dttype: "EMISSAO",
        paginationChrec: '1',
        paginationChpag: '1',
        nome: '',
    });


    function verifyFilters(key: string, newValue: string) {
        let newFiltersVerify = filters;
        if (key !== 'id_regional' && filterRegional && filterRegional.id_regional) newFiltersVerify['id_regional'] = String(filterRegional.id_regional);
        if (key !== 'id_unity' && filterUnity && filterUnity.id_unity) newFiltersVerify['id_unity'] = String(filterUnity.id_unity);
        if (key === 'dt_From' || key === 'dt_To') {
            let value = moment(newValue).format('DD-MM-YYYY');
            newValue = value !== 'Invalid date' ? value : '';
        }
        newFiltersVerify[key] = newValue;
        if (key !== 'paginationChrec' && key !== 'paginationChpag') {
            newFiltersVerify['paginationChrec'] = '1';
            newFiltersVerify['paginationChpag'] = '1';
        } else {
            screenUpdate();
        }
        if (key === '')
            setFilters(newFiltersVerify);

    }

    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [filterUnity, setFilterUnity] = useState<Unity | undefined>(undefined);

    useEffect(() => {
        verifyFilters('id_regional', filterRegional && filterRegional.id_regional ? filterRegional.id_regional.toString() : '');
    }, [filterRegional]);

    useEffect(() => {
        verifyFilters('numconta', '0')
        verifyFilters('id_unity', filterUnity && filterUnity.id_unity ? filterUnity.id_unity.toString() : '');
        verifyFilters('dt_From', moment().startOf('month').toString());
        verifyFilters('dt_To', moment().endOf('month').toString());
        if (filterUnity && filterUnity.id_unity && filterUnity.id_unity !== -1) {
            getBankAccounts(filterUnity.id_unity).then((response) => setBankAccounts(response))
        }
        screenUpdate();
    }, [filterUnity]);

    const [loading, setLoading] = useState<boolean>(false);
    const [viewType, setViewType] = useState<number>(1);
    const [chartPeriod, setChartPeriod] = useState<number>(6);
    const [openSearch, setOpenSearch] = useState<boolean>(false);

    function screenUpdate() {
        setFilters(filters);
        setLoading(true);
        setDataChartContasChpag(undefined);
        setDataChartContasChrec(undefined);
        getAccounts(filters).then(response => {
            setAccounts(response);
            let nPag = response?.contasPagar?.totalsChpag;
            let nRec = response?.contasRecebidas?.totalsChrec;
            setPaginationPag(nPag && nPag > 0 ? Math.ceil(nPag / itemsPerPage) : 0);
            setPaginationRec(nRec && nRec > 0 ? Math.ceil(nRec / itemsPerPage) : 0);
            updateChats(response);
            setLoading(false);
        });
    };


    const [dataChartPeriod, setDataChartPeriod] = useState<any>();
    const updateChartPeriod = useCallback((dataList: Partial<AccountPeriodChart> | undefined) => {
        let newValues: Array<any> = [];
        if (dataList) {

            if (dataList.totalChpagChart || dataList.totalChrecChart) {

                let sizePag = dataList?.totalChpagChart?.length ? dataList?.totalChpagChart?.length : 0;
                let sizeRec = dataList?.totalChrecChart?.length ? dataList?.totalChrecChart?.length : 0;
                let list1: Array<any> | undefined = [];
                let list2: Array<any> | undefined = [];
                let listType = 1;
                if (sizePag >= sizeRec) {
                    list1 = dataList.totalChpagChart;
                    list2 = dataList.totalChrecChart;
                } else {
                    list2 = dataList.totalChpagChart;
                    list1 = dataList.totalChrecChart;
                    listType = 2;
                }
                list1?.forEach((item, key) => {

                    list2?.forEach((item2, key2) => {

                        if (item2?.month == item?.month && item2?.year == item?.year) {
                            newValues.push({
                                name: listType == 1 ? item.month + '/' + item.year : item2.month + '/' + item2.year,
                                pagas: listType == 1 ? Number(item.value) : Number(item2.value),
                                recebidas: listType == 1 ? Number(item2.value) : Number(item.value),
                            });
                        } else if (list1?.length == 1 || list2?.length == 1) {
                            newValues.push({
                                name: listType == 1 ? item.month + '/' + item.year : item2.month + '/' + item2.year,
                                pagas: listType == 1 ? Number(item.value) : Number(item2.value),
                                recebidas: listType == 1 ? Number(item2.value) : Number(item.value),
                            });
                        }
                    });
                });
            }
        }
        setDataChartPeriod(newValues.reverse());

    }, []);

    useEffect(() => {
        setLoading(true);
        let filterChart = {
            'id_unity': filterUnity && filterUnity.id_unity ? filterUnity.id_unity.toString() : '',
            'id_regional': filterRegional && filterRegional.id_regional ? filterRegional.id_regional.toString() : '',
            'month': chartPeriod
        }
        getAccountsDataChartPeriod(filterChart).then(async (response) => {
            updateChartPeriod(response)
            setLoading(false);
        });

    }, [chartPeriod, filterUnity, filterRegional])

    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();

    const handleExportAll = useCallback((type: number) => {

        let n = 0;
        if (type === 1 && listAccounts?.contasPagar?.totalsChpag) n = listAccounts.contasPagar.totalsChpag;
        if (type === 2 && listAccounts?.contasRecebidas?.totalsChrec) n = listAccounts.contasRecebidas.totalsChrec;
        if (n > 0) {
            setLoading(true);
            let newFilter = { ...filters };
            newFilter.tipoContas = String(type);
            getAccountsPdf(newFilter).then(() => { setLoading(false) });

        } else {
            setMsgAlertError('Não há registros para imprimir');
            setOpenAlertError(true);
        }
    }, [listAccounts]);

    const [dataChart, setDataChart] = useState<Array<BarChartObject>>();
    const [dataChartContasChrec, setDataChartContasChrec] = useState<Array<BarChartObject>>();
    const [dataChartContasChpag, setDataChartContasChpag] = useState<Array<BarChartObject>>();

    const updateChats = useCallback((dataList: AccountsReturn | undefined) => {
        let newData: Array<BarChartObject> = [];
        let newDataContasRecebidas: Array<BarChartObject> | undefined = [];
        let newDataContasRPagas: Array<BarChartObject> | undefined = [];
        if (dataList?.contasPagar?.totalValorChpag && dataList?.contasRecebidas?.totalValorChrec) {
            newData = [
                { name: 'Pagas', valor: Number(dataList.contasPagar.totalValorChpag.replaceAll('.', '').replace(',', '.')) },
                { name: 'Recebidas', valor: Number(dataList.contasRecebidas.totalValorChrec.replaceAll('.', '').replace(',', '.')) }
            ];
            newDataContasRecebidas = dataList?.contasRecebidas?.chartValoresContasChrec?.map((item) => { return { name: item.name, valor: Number(item.valor ? item.valor : 0) } });
            newDataContasRPagas = dataList?.contasPagar?.chartValoresContasChpag?.map((item) => { return { name: item.name, valor: Number(item.valor ? item.valor : 0) } });
        }

        setDataChartContasChrec(dataList?.contasRecebidas?.chartValoresContasChrec)
        setDataChartContasChpag(dataList?.contasPagar?.chartValoresContasChpag)
        setDataChart(newData);
    }, [])

    const chartsRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => chartsRef.current,
    });

    function handleStates() {
        return viewType === 1 ? !loading && openSearch : (Boolean(dataChart?.length) && Boolean(dataChartContasChpag?.length) && Boolean(dataChartContasChrec?.length) && !loading) && openSearch
    }

    return (
        <div id="page-accounts">
            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} />
            <main>
                <div className="container">
                    <div className="ts-gutter">
                        <PageTitle text="Consulta de contas" icon={accountsIcon} />
                        <Row className="align-items-center mx-n2">
                            <Col sm={6} className="ts-gutter-t px-2">
                                <SelectUnity
                                    selectedUnity={filterUnity}
                                    setSelectedUnity={setFilterUnity}
                                    selectedRegional={filterRegional}
                                    setSelectedRegional={setFilterRegional}
                                    id="filter"
                                    size={6}
                                    all={true}
                                    disabled={loading}
                                />
                            </Col>
                            {filterUnity && (
                                <>
                                    <Col sm={3} className="ts-gutter-t px-2">
                                        <FormInputGroup label="Modo de visualização" id="filter_view">
                                            <select id="filter_view" onChange={(e) => setViewType(Number(e.target.value))} value={viewType} disabled={loading}>
                                                <option value="1">Lista</option>
                                                <option value="2">Gráfico</option>
                                            </select>
                                        </FormInputGroup>
                                    </Col>
                                    <Col sm="auto" className="ts-gutter-t pt-3">
                                        <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                            <LinkIcon text="Pesquisar" icon={searchIcon} />
                                        </button>
                                    </Col>

                                    <DropDownButton
                                        icon={printIcon}
                                        loading={loading}
                                        textIcon="Imprimir"
                                        idDropDown='print_id'
                                        viewType={viewType}
                                        propsDropItem={[
                                            {
                                                title: "Contas Pagas",
                                                paramsFunc: paramIcons.contasPagas
                                            },
                                            {
                                                title: "Contas Recebidas",
                                                paramsFunc: paramIcons.contasRecebidas
                                            },
                                        ]}
                                        func={handleExportAll}
                                        onClick={handlePrint}
                                    />
                                </>
                            )}
                        </Row>
                    </div>
                </div>

                <div className="container-fluid">
                    <Collapse in={handleStates()} appear={true} >
                        <div className="ts-search ts-form-box ts-gutter">
                            <p className="ts-page-text mb-3">Pesquisar por:</p>
                            <Row className="mx-n2 align-items-end" onKeyUp={(e: any) => { if (e.keyCode == 13 && openSearch) { screenUpdate() } }}>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Tipo de data" id="dttype">
                                        <select id="dttype" onChange={(e) => verifyFilters('dttype', e.target.value)}>
                                            <option value="EMISSAO">Emissão</option>
                                            <option value="PGARECEBIDA">Pagas recebidas</option>
                                        </select>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Data - de" id="dt_From">
                                        <input placeholder="dd-mm-yyyy" type="date"
                                            defaultValue={
                                                filters.dt_From
                                                    ? moment(filters.dt_From, 'DD-MM-YYYY').format('YYYY-MM-DD').toString()
                                                    : (moment().startOf('month').format('YYYY-MM-DD').toString())
                                            }
                                            id="dt_From" onChange={(e) => verifyFilters('dt_From', e.target.value)}
                                        />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Data - até" id="dt_To">
                                        <input placeholder="dd-mm-yyyy" type="date"
                                            defaultValue={
                                                filters.dt_To
                                                    ? moment(filters.dt_To, 'DD-MM-YYYY').format('YYYY-MM-DD').toString()
                                                    : (moment().endOf('month').format('YYYY-MM-DD').toString())
                                            }
                                            id="dt_To" onChange={(e) => verifyFilters('dt_To', e.target.value)}
                                        />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={1} className="px-2">
                                    <FormInputGroup label="Prontuário" id="prontuario">
                                        <input type="text" id="prontuario" onChange={(e) => verifyFilters('prontuario', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={3} className="px-2">
                                    <FormInputGroup label="Nome" id="nome">
                                        <input type="text" id="nome" onChange={(e) => verifyFilters('nome', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                {filterUnity?.id_unity !== -1 &&
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Banco" id="numconta">
                                            <select id="numconta" onChange={(e) => verifyFilters('numconta', e.target.value)}>
                                                <option value="0">Todos</option>
                                                {bankAccounts.map((item) => (
                                                    <option value={item.numconta}>{item.descricao}</option>
                                                ))}
                                            </select>
                                        </FormInputGroup>
                                    </Col>
                                }
                                <SelectGeneralData
                                    route='grp02'
                                    selectedRegional={filterRegional?.id_regional}
                                    selectedUnity={filterUnity?.id_unity}
                                    nomeListagem="Conta"
                                    disabled={loading}
                                    verifyFilters={verifyFilters}
                                    allItemsText={'Todas as contas'}
                                    keyFilter={'conta'}
                                    size={2}
                                />



                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Status" id="status">
                                        <select id="status" onChange={(e) => verifyFilters('status', e.target.value)}>
                                            <option value="">Todos</option>
                                            <option value="A">Ativo</option>
                                            <option value="I">Inativo</option>
                                        </select>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Valor - de" id="valor_From">
                                        <input type="number" id="valor_From" onChange={(e) => verifyFilters('valor_From', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Valor - até" id="valor_To">
                                        <input type="number" id="valor_To" onChange={(e) => verifyFilters('valor_To', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Tipo contas" id="tipoContas">
                                        <select id="tipoContas" onChange={(e) => { verifyFilters('tipoContas', e.target.value) }}>
                                            <option value="-1">Todos</option>
                                            <option value="1">Contas pagas</option>
                                            <option value="2">contas recebidas</option>
                                        </select>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Tipo lançamento" id="detento">
                                        <select id="detento" onChange={(e) => verifyFilters('detento', e.target.value)}>
                                            <option value="">Todos</option>
                                            <option value="1">Detentos</option>
                                        </select>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-2">
                                    <FormInputGroup label="Descrição" id="descricao">
                                        <input type="text" id="descricao" onChange={(e) => verifyFilters('descricao', e.target.value)} />
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2} className="px-0 m-auto">
                                    <FormInputGroup id="search" >
                                        <div className="ts-submit">
                                            <button id="search" disabled={loading} onClick={screenUpdate} >  {loading ? 'Carregando...' : 'Filtrar'} </button>
                                        </div>
                                    </FormInputGroup>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </div>

                <div className="container">
                    <div className="ts-gutter">
                        {filterUnity && viewType == 1 && (
                            <>
                                <Row>
                                    <Col lg={viewType == 1 ? 6 : 8}>
                                        <p className="ts-page-text mb-3 ts-gutter-t text-center"><b>TOTAL{(filters.dt_From || filters.dt_To) ?
                                            (" ENTRE " + (filters.dt_From ? filters.dt_From : '...') + ' ATÉ ' + (filters.dt_To ? filters.dt_To
                                                : '...')) : " COMPLETO"}</b></p>
                                        {viewType == 1 ? (
                                            <Table striped hover size="sm" className="ts-items-table ts-gutter-t">
                                                <tbody>
                                                    <tr key={1}>
                                                        <td><p>Registros encontrados</p></td>
                                                        <td><p className='text-right'>{listAccounts?.difTotal?.total ? listAccounts?.difTotal?.total : 0}</p></td>
                                                    </tr>
                                                    <tr key={3}>
                                                        <td><p>Pagos</p></td>
                                                        <td><p className='text-right'>R$ {listAccounts?.contasPagar?.totalValorChpag}</p></td>
                                                    </tr>
                                                    <tr key={2}>
                                                        <td><p>Recebidos</p></td>
                                                        <td><p className='text-right'>R$ {listAccounts?.contasRecebidas?.totalValorChrec}</p></td>
                                                    </tr>
                                                    <tr key={4}>
                                                        <td><p><b>Total</b></p></td>
                                                        <td><p className='text-right'><b>R$ {listAccounts?.difTotal?.difVal ? listAccounts?.difTotal?.difVal : 0}</b></p></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <div className="ts-charts big">

                                            </div>
                                        )}
                                    </Col>
                                    <Col lg={viewType == 1 ? 6 : 8}>
                                        <p className="ts-page-text mb-3 ts-gutter-t text-center"><b>TOTAL GERAL</b></p>
                                        {viewType == 1 && (
                                            <Table striped hover size="sm" className="ts-items-table ts-gutter-t">
                                                <tbody>
                                                    <tr key={'recn' + 1}>
                                                        <td><p>Registros encontrados</p></td>
                                                        <td><p className='text-right'>{listAccounts?.difTotal?.totalGeralNotFiltred}</p></td>
                                                    </tr>
                                                    <tr key={'pg' + 3}>
                                                        <td><p>Pagos</p></td>
                                                        <td><p className='text-right'>R$ {listAccounts?.contasPagar?.totalValorChpagNotFiltered}</p></td>
                                                    </tr>
                                                    <tr key={'rec' + 2}>
                                                        <td><p>Recebidos</p></td>
                                                        <td><p className='text-right'>R$ {listAccounts?.contasRecebidas?.totalValorChrecNotFiltered}</p></td>
                                                    </tr>
                                                    <tr key={'total' + 3}>
                                                        <td><p><b>Total</b></p></td>
                                                        <td><p className='text-right'><b>R$ {listAccounts?.difTotal?.diTotalNotFiltred ? listAccounts?.difTotal?.diTotalNotFiltred : 0}</b></p></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </div>

                {loading && viewType != 2 && (<Loading />)}


                {filterUnity && (

                    <div className="container-fluid">


                        {listAccounts?.difTotal && viewType == 1 && (
                            <div>
                                <div className="mt-5">
                                    <p className="ts-page-text ts-gutter-t"><b>CONTAS PAGAS:</b></p>
                                </div>
                                <div className="ts-gutter ">
                                    <Table striped hover size="sm" className="ts-items-table ts-lists">
                                        <thead>
                                            <tr className='acc-table-head' >
                                                <th>Data emissão</th>
                                                <th>Prontuário</th>
                                                <th>Nome</th>
                                                <th>Descrição</th>
                                                <th>Conta</th>
                                                <th>Valor</th>
                                                <th>Data pago</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ts-text-area">
                                            {!!listAccounts?.contasPagar?.listChpag && listAccounts.contasPagar.listChpag.map((item, key) => (
                                                <tr className='acc-table-body' key={key}>
                                                    <td><p>{item.dtem}</p></td>
                                                    <td><p>{item.prontuario}</p></td>
                                                    <td><p>{item.nomefor}</p></td>
                                                    <TdResponsive val={String(item.descricao)} />
                                                    <td><p>{item.codctb}</p></td>
                                                    <td><p className='valuePadding text-right'>R$ {item.valor}</p></td>
                                                    <td><p>{item.dtpag}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{listAccounts?.contasPagar?.totalsChpag} registros encontrados</p></Col>
                                        <Col className="ts-gutter-t"><p className="ts-page-text"><b>Total: </b>R$ {listAccounts?.contasPagar?.totalValorChpag}</p></Col>
                                    </Row>
                                    <br></br>
                                    <PaginationCP
                                        pagination={paginationPag}
                                        filter={filters.paginationChpag}
                                        filterKey={'paginationChpag'}
                                        verifyFilters={verifyFilters}
                                    />
                                </div>

                                <div className="mt-5">
                                    <p className="ts-page-text ts-gutter-t"><b>CONTAS RECEBIDAS:</b></p>
                                </div>
                                <div className="ts-gutter">
                                    <Table striped hover size="sm" className="ts-items-table ts-lists ">
                                        <thead>
                                            <tr className='acc-table-head'>
                                                <th>Data emissão</th>
                                                <th>Prontuário</th>
                                                <th>Nome</th>
                                                <th>Descrição</th>
                                                <th>Conta</th>
                                                <th>Valor</th>
                                                <th>Data recebido</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {!!listAccounts?.contasRecebidas?.listChrec && listAccounts.contasRecebidas.listChrec.map((item, key) => (
                                                <tr className='acc-table-body' key={key}>
                                                    <td><p>{item.dtem}</p></td>
                                                    <td><p>{item.prontuario}</p></td>
                                                    <td><p>{item.nomecli}</p></td>
                                                    <TdResponsive val={String(item.descricao)} />
                                                    <td><p>{item.codctb}</p></td>
                                                    <td><p className='valuePadding text-right'> R$ {item.valor}</p></td>
                                                    <td ><p>{item.dtpag}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <Row>
                                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{listAccounts?.contasRecebidas?.totalsChrec} registros encontrados</p></Col>
                                        <Col className="ts-gutter-t"><p className="ts-page-text"><b>Total: </b>R$ {listAccounts?.contasRecebidas?.totalValorChrec}</p></Col>
                                    </Row>
                                    <br></br>
                                    <PaginationCP
                                        filter={filters.paginationChrec}
                                        filterKey={'paginationChrec'}
                                        verifyFilters={verifyFilters}
                                        pagination={paginationRec}

                                    />
                                </div>
                            </div>
                        )}

                        {viewType === 2 && (

                            <AccountListCharts
                                chartsRef={chartsRef}
                                chartPeriod={chartPeriod}
                                dataChart={dataChart}
                                dataChartContasChpag={dataChartContasChpag}
                                dataChartContasChrec={dataChartContasChrec}
                                dataChartPeriod={dataChartPeriod}
                                loading={loading}
                                setChartPeriod={setChartPeriod}
                                filters={filters}
                            />
                        )
                        }
                    </div>
                )
                }
            </main >
        </div >
    )
}

export default AccountsList;