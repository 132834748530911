import api from './functions/api';
import fileDownload from 'js-file-download';
import { verifyDataPagination, verifyData } from './functions/verify-response';
import Contact from './model/contact';
import ContactDetail from './model/contact-detail';


interface PaginationResponse {
  list: Array<ContactDetail>;
  total: number;
}

export async function getContacts(filters: any): Promise<PaginationResponse> {
  return api.get('/contact/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export function getContactsPdf(filters: any): Promise<object | void> {
  return api.get('/contact/pdf/?' + new URLSearchParams(filters).toString(), { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'contatos.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}

export async function getPerson(document: string): Promise<ContactDetail | undefined> {
  return api.get('/contact/person/' + document).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

export async function getContact(id: number): Promise<Contact | undefined> {
  return api.get('/contact/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

export function getContactPdf(id: number): Promise<void> {
  return api.get('/contact/pdf/' + id, { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'contato-' + id + '.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}


export async function getProntuario(dados: any): Promise<ContactDetail | undefined> { 
  return api.post('/contact/interno/', dados).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}


export async function getDetento(dados: any): Promise<PaginationResponse> {
  return api.post('/contact/contatos/', dados).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}