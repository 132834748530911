import fileDownload from 'js-file-download';

import api from "../functions/api";
import { verifyData, verifyDataList, verifyDataListGeneric } from "../functions/verify-response";
import { ChamadaLog, DetailedListChamadaLog, FilterAttendaceReportDownload, ListChamadaLog } from "../model/chamadaLog";

export async function postCheckHasFiles(filtros?: {
    id_regional: number | undefined;
    id_unity: number;
    month: number;
}): Promise<any> {
    return api.post('/reports/attendance/checkHasFiles', filtros).then(response => {
        return verifyData(response);
    }).catch(error => {
        throw verifyData(error.response);
    });
}

export async function listFilesReportAttendance(id_unity: number, months: number): Promise<any> {
    return api.post('/reports/attendance/listFiles', { id_unity, months }).then(response => {
        return verifyData(response);
    }).catch(error => {
        throw verifyData(error.response);
    });
}

export async function downloadFile(filter: FilterAttendaceReportDownload): Promise<boolean> {
    return api.post('/reports/attendance/download', filter).then(response => {
        try {
            fileDownload(Buffer.from(response.data.report.data), response.data.fileName + ".pdf");
            return true;
        } catch (error: any) {
            console.log(error);
            return false;
        }
    }).catch(error => {
        return false;
    });
}




