

const RenderCustomizedLabel: any = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 5;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
        <g>

            <circle cx={ex} cy={ey} r={3} fill={fill} stroke="none" />
            <text x={ex + ((cos >= 0 ? 10 : 2))} y={ey - ((cos >= 0 ? 10 : -10))} dy={10} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>

        </g>
    );
}

export default RenderCustomizedLabel;