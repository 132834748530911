import React from 'react';

import loadIcon from '../../assets/images/loading.gif';

import './styles.css';

interface LoadingProps {
  notFullScreen?: boolean,
  text?: string
}

const Loading: React.FC<LoadingProps> = ({ notFullScreen, text }) => (
  <div className={`d-flex align-items-center justify-content-center ${notFullScreen ? undefined : "w-100 h-100 loading-content"} `} >
    <div className="text-center">
      <img src={loadIcon} alt="Carregando" />
      <p>{text?.length ? text : "Por favor, aguarde..."}</p>
    </div>
  </div>
);

export default Loading;