import axios from 'axios';

import { getToken } from './auth';

function configApi(config: any) {
	const token = getToken();
	if (token) config.headers.Authorization = `Bearer ${token}`;
	return config;
}

const baseDev = 'http://192.168.200.78';
const baseProd = 'http://sap.sagep.com.br';
//const baseProd = 'http://192.168.15.153';

export const baseURL = baseProd + ':9064';
const api = axios.create({ baseURL });
api.interceptors.request.use(async (config) => configApi(config));
export default api;

export const proceedsURL = baseProd + ':9061';
export const proceedsApi = axios.create({ baseURL: proceedsURL });
proceedsApi.interceptors.request.use(async (config) => configApi(config));

export const registroURL = baseProd + ':9062';
export const registroAPI = axios.create({ baseURL: registroURL });
registroAPI.interceptors.request.use(async (config) => configApi(config));
