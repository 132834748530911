import api from './functions/api';
import { verifyDataList, verifyData } from './functions/verify-response';
import Regional from './model/regional';

export async function getRegionals(filters?: any): Promise<Array<Regional>> {
  //return [{ id_regional: 1, regional_name: 'Teste' }];
  return api.get('/regional/?' + new URLSearchParams(filters).toString()).then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

export async function getRegionalName(id: number): Promise<string> {
  return api.get('/regional/' + id).then(response => {
    const data: any = response.data;
    if (data && data.regional_name) return data.regional_name;
    return '';
  }).catch(error => {
    return '';
  });
}

export async function getRegional(id: number): Promise<Regional | undefined> {
  return api.get('/regional/' + id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}
