
import React, { useState, useMemo, useEffect, } from 'react';

import { getLastMovimentations, getLastMovimentationsPdf } from '../../services/lastMovimentation';

import PageTitle from '../../components/PageTitle';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import configIcon from '../../assets/images/icons/configs.svg';

import LinkIcon from '../../components/LinkIcon';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import SelectUnity from '../../components/SelectFilterUnity';
import Unity from '../../services/model/unity';
import Regional from '../../services/model/regional';
import Loading from '../../components/Loading';
import Radio from '../../components/Radio';
import { DetailedLastMovimentation, SimpleLastMovimentation } from '../../services/model/lastMovimentation';
import FormInputGroup from '../../components/FormInputGroup';


const LastMovimentationList: React.FC = () => {
    const [lastMovimentation, setLastMovimentation] = useState<DetailedLastMovimentation | SimpleLastMovimentation>()
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>('Simples');
    const [day, setDay] = useState<number>(0);


    useEffect(() => {
        setValue('Simples')
    }, [selectedUnity?.id_unity !== -1])

    useMemo(() => {
        if (selectedUnity?.id_unity) {
            setLoading(true)
            let filter: any = {
                id_regional: filterRegional?.id_regional,
                id_unity: selectedUnity?.id_unity,
                type: value,
                day
            }
            getLastMovimentations(filter).then(response => {
                if (response) {
                    setLastMovimentation(response);
                }
                setLoading(false)
            });
        }
    }, [selectedUnity, value, day]);


    function handleExport() {
        setLoading(true)
        let lastMovimentationArray: any = {
            id_regional: filterRegional?.id_regional,
            id_unity: selectedUnity?.id_unity,
            type: value,
            day
        }
        getLastMovimentationsPdf(lastMovimentationArray).then(() => setLoading(false));
    }

    return (
        <div id="page-lastMovimentation">
            {loading && (<Loading />)}
            <main className="container">
                <div className="ts-gutter">
                    <Row>
                        <Col lg={10}>
                            <PageTitle text="Monitoramento de Atividades" icon={configIcon} />
                        </Col>
                        {!!selectedUnity?.id_unity &&
                            <>
                                <Col lg={2} style={{ paddingLeft: '6.7%' }}>
                                    {<button className="ts-link-icon" onClick={handleExport} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>}
                                </Col>
                            </>
                        }
                    </Row>
                </div>
                {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                    <Col style={{ paddingLeft: '77%' }}><b>Visualização</b></Col>
                }
                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact"
                    size={3}
                    disabled={loading}
                    all={true}
                >
                    {selectedUnity?.id_unity &&
                        <Col lg={2} className="px-2">
                            <FormInputGroup label="Dias" id="days">
                                <select id="days" onChange={(e) => { setDay(Number(e.target.value)) }}>
                                    <option value="0">Hoje</option>
                                    <option value="1">1 dia</option>
                                    <option value="7">7 dias</option>
                                    <option value="15">15 dias</option>
                                    <option value="30">30 dias</option>
                                </select>
                            </FormInputGroup>
                        </Col>
                    }
                    {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                        <Col lg={3} style={{ textAlign: 'center', marginTop: '2.5%' }}>
                            <Radio options={['Simples', 'Detalhado']} value={value} setValue={setValue} style={{ marginRight: '4px', marginLeft: '12px' }} />
                        </Col>
                    }
                </SelectUnity>
                <p className='lm-circle-false'> </p>
                {!!selectedUnity?.id_unity && value && lastMovimentation?.list &&
                    < div className="ts-gutter " style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>

                        <table>
                            <tr>
                                <td className='circle green'></td>
                                <td className='padding'>   Houve lançamentos</td>
                            </tr>
                            <tr>
                                <td className='circle red'></td>
                                <td className='padding'>   Não houve lançamentos</td>
                            </tr>
                        </table>


                        {value === 'Simples' && (
                            <Table striped size="sm" className="ts-items-table tabelaLastMovimentation ">
                                <thead>
                                    <tr>
                                        <th className='lm-th lm-greenTitles' colSpan={lastMovimentation?.list.length !== 1 ? 4 : 3}>
                                            <p className='Regititle'>
                                                {filterRegional?.id_regional !== -1 && selectedUnity?.id_unity === -1 ? '  ' + filterRegional?.regional_name :
                                                    filterRegional?.id_regional === -1 && selectedUnity?.id_unity !== -1 ? ' ' + selectedUnity?.contact_name :
                                                        filterRegional?.id_regional !== -1 && selectedUnity.id_unity !== -1 ? ' ' + selectedUnity?.contact_name : ' Todas as Unidades'}
                                            </p>
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ zIndex: 1, position: 'sticky' }}>
                                    <tr className='lm-fixed lm-greenTitles'>
                                        {lastMovimentation?.list.length !== 1 && <th className='lm-th right ' >Unidade</th>}
                                        <th className={lastMovimentation?.list.length !== 1 ? 'lm-th left right lm-width' : "lm-th right lm-width"}>Pecúlio</th>
                                        <th className='lm-th left lm-width'>Laboral</th>
                                    </tr>
                                </thead>
                                <tbody style={{ zIndex: 1 }}>
                                    {!!lastMovimentation && (lastMovimentation.list as Array<SimpleLastMovimentation['list'][0]>).map((item, key: any) => (
                                        <>
                                            <tr style={{ textAlign: 'center' }} key={key}>
                                                {lastMovimentation?.list.length !== 1 ? <td className='lm-td right  '><p className='colun1'>{item.unity_name}</p></td> : ''}
                                                <td className={lastMovimentation?.list.length !== 1 ? 'lm-td left right' : "lm-td  right"}><p className={item.account ? 'lm-circle-true' : 'lm-circle-false'}></p></td>
                                                <td className='lm-td left'><p className={item.attachment ? 'lm-circle-true' : 'lm-circle-false'}></p> </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {value === 'Detalhado' &&
                            <Table striped size="sm" className="ts-items-table tabelaLastMovimentation">
                                <thead>
                                    <tr>
                                        <th className='lm-th lm-greenTitles' colSpan={4}><p className='Regititle'> Todas as Unidades</p></th>
                                    </tr>
                                </thead>
                                {lastMovimentation && value === 'Detalhado' && (lastMovimentation.list as Array<DetailedLastMovimentation['list'][0]>).map((regionalItem, key: any) => (
                                    <>
                                        {regionalItem && regionalItem.lastMovimentationArray &&
                                            <>
                                                <tr key={key}>
                                                    <td className="lm-th coloredTitles" colSpan={4}> {regionalItem?.lastMovimentationArray[0].regional_name} </td>
                                                </tr>
                                                <thead>
                                                    <tr className='tr '>
                                                        <th className='lm-th right' >Unidade</th>
                                                        <th className='lm-th left right lm-width' >Pecúlio</th>
                                                        <th className='lm-th left lm-width'>Laboral</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!!regionalItem && regionalItem.lastMovimentationArray.map((item, key2: any) => {
                                                        return (
                                                            <tr key={key2}>
                                                                <td className='lm-td right'><p className='colun1'>{item.unity_name}</p></td>
                                                                <td className='lm-td left right'><p className={item.account ? 'lm-circle-true' : 'lm-circle-false'}></p></td>
                                                                <td className='lm-td left'><p className={item.attachment ? 'lm-circle-true' : 'lm-circle-false'}></p></td>
                                                            </tr>)
                                                    })}
                                                </tbody>

                                                <br></br>
                                            </>
                                        }
                                    </>
                                ))}

                            </Table>
                        }
                    </div>
                }
            </main >
        </div >
    );
}

export default LastMovimentationList;