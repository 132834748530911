import React, { useState, useEffect } from 'react';
import Dados from '../../services/model/dados_usuario';
import { getDadosAll } from '../../services/dados_usuario';

import PageTitle from '../../components/PageTitle';
import LinkIcon from '../../components/LinkIcon';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import configIcon from '../../assets/images/icons/configs.svg';
import firstPage from '../../assets/images/icons/rewind.svg';
import prevPage from '../../assets/images/icons/left.svg';
import lastPage from '../../assets/images/icons/forward.svg';
import nextPage from '../../assets/images/icons/right.svg';
import searchIcon from '../../assets/images/icons/search.svg';


import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import FormInputGroup from '../../components/FormInputGroup';
import { Collapse } from 'react-bootstrap';
import { formatDocument } from '../../services/functions/format';
import PaginationCP from '../../components/PaginationCP';

const DadosList: React.FC = () => {

    const pageId = 11;
    const itemsPerPage = 30;

    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [paginationNumbers, setPaginationNumbers] = useState<React.FC>();
    const [pagination, setPagination] = useState<number>(0);
    const [nDados, setNDados] = useState<number>(0);
    const [listDados, setListDados] = useState<Array<Dados>>([]);
    const [filters, setFilters] = useState<any>({
        numserie: '',
        dtem: '',
        hrem: '',
        chave_cnpj: '',
        nome: '',
        cnpj: '',
        operador: '',
        versao: '',
        licenca_tipo: '',
        licenca_periodo: '',
        ip_cliente: '',
        pagination: '1',
    });


    function verifyFilters(key: string, newValue: string) {
        let newFilters = filters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate();
    }
    function screenUpdate() {
        getDadosAll(filters).then(response => {
            setListDados(response.list);
            setNDados(response.total);
            response.total > 0 ? setPagination(Math.ceil(response.total / itemsPerPage)) : setPagination(0)
        });
    }
    useEffect(() => {
        screenUpdate();
    }, []);

    return (
        <div id="page-dados-usuario">
            <main className="container tabela">
                <div className="ts-gutter">
                    <PageTitle text="Lista de dados de acesso" icon={configIcon} />
                    <Row className="justify-content-between align-items-center">
                        <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nDados} registros encontrados</p></Col>
                        <Col sm="auto" className="ts-gutter-t">
                            <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                <LinkIcon text="Pesquisar" icon={searchIcon} />
                            </button>
                        </Col>
                    </Row>
                </div>
                <Collapse in={openSearch} appear={true}>
                    <div className="ts-search ts-form-box ts-gutter">
                        <p className="ts-page-text mb-3">Pesquisar por:</p>
                        <Row className="mx-n2">
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Número de série" id="numserie">
                                    <input type="text" id="numserie" onChange={(e) => verifyFilters('numserie', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Nome" id="nome">
                                    <input type="text" id="nome" onChange={(e) => verifyFilters('nome', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="CNPJ" id="cnpj">
                                    <input type="text" id="cnpj" onChange={(e) => verifyFilters('cnpj', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Chave CNPJ" id="chave_cnpj">
                                    <input type="text" id="chave_cnpj" onChange={(e) => verifyFilters('chave_cnpj', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Operador" id="operador">
                                    <input type="text" id="operador" onChange={(e) => verifyFilters('operador', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Versão" id="versao">
                                    <input type="text" id="versao" onChange={(e) => verifyFilters('versao', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Ip do cliente" id="ip_cliente">
                                    <input type="text" id="ip_cliente" onChange={(e) => verifyFilters('ip_cliente', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Tipo de licença" id="licenca_tipo">
                                    <input type="text" id="licenca_tipo" onChange={(e) => verifyFilters('licenca_tipo', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Período da licença" id="licenca_periodo">
                                    <input type="text" id="licenca_periodo" onChange={(e) => verifyFilters('licenca_periodo', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Data de emissão" id="dtem">
                                    <input type="date" id="dtem" onChange={(e) => verifyFilters('dtem', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                            <Col lg={2} className="px-2">
                                <FormInputGroup label="Hora de emissão" id="hrem">
                                    <input type="time" id="hrem" onChange={(e) => verifyFilters('hrem', e.target.value)} />
                                </FormInputGroup>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
                <div className="ts-gutter">
                    <Table striped hover size="sm" className="ts-items-table">
                        <thead>
                            <tr>
                                <th>Numserie</th>
                                <th>Nome</th>
                                <th>Código</th>
                                <th>Operador</th>
                                <th>Versão</th>
                                <th>Tipo Licença</th>
                                <th>Periodo Licença</th>
                                <th>Ip Cliente</th>
                                <th>Hora</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!listDados && listDados.map((item, key) => (
                                <tr key={key.toString()}>
                                    <td><p>{item.numserie}</p></td>
                                    <td><p>{item.nome}</p></td>
                                    <td><p>{item.cnpj ? formatDocument(item.cnpj) : ''}</p></td>
                                    <td><p>{item.operador}</p></td>
                                    <td><p>{item.versao}</p></td>
                                    <td><p>{item.licenca_tipo}</p></td>
                                    <td><p>{item.licenca_periodo}</p></td>
                                    <td><p>{item.ip_cliente}</p></td>
                                    <td><p>{item.hrem}</p></td>
                                    <td><p>{item.dtem}</p></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <PaginationCP
                        pagination={pagination}
                        filter={filters.pagination}
                        filterKey={'pagination'}
                        verifyFilters={verifyFilters}
                    />
                </div>
            </main>
        </div>
    );
}

export default DadosList;