import React from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from '../../services/functions/auth';

import './styles.css';

import outIcon from '../../assets/images/icons/log-out.svg';

const Logout: React.FC = () => {

    const navigate = useHistory();
    const handleLogout = () => {
        logout();
        navigate.push('/acessar');
    }

    return (
        <button className="ts-button-text logout" id="btn-logout" onClick={handleLogout}>
            <div className="d-flex align-items-center">
                <img src={outIcon} alt="Sair"/>
                <p>SAIR</p>
            </div>
        </button>
    );
}

export default Logout;