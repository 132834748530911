import React from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import PageHeader from './components/PageHeader';
import { ConfigurationProvider } from './contexts/InfoContext';
import LastMovimentationList from './pages/lastMovimentationList';
import Login from './pages/LoginList';
import Main from './pages/Main';
import { isAuthenticated } from "./services/functions/auth";
import { getMenuTop } from './services/functions/user-info';
import { checkLogin } from './services/login';

interface PrivateRouteProps extends RouteProps {
  component: any;
}
const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <>

    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <>
            <ConfigurationProvider>
            <PageHeader />
              <Component {...props} />
            </ConfigurationProvider>
          </>
        ) : (
          <Redirect to={{ pathname: "/acessar", state: { from: props.location } }} />
        )
      }
    />

  </>
);

const Routes: React.FC = () => {
  checkLogin()
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/acessar" component={Login} />
        <PrivateRoute exact path="/" component={/*Main*/LastMovimentationList} />
        {getMenuTop().map(item => {
          return item.sub_menu.map((sub_item, key) => {
            return !!sub_item.component && (<PrivateRoute key={'rtlst' + key} path={sub_item.url} component={sub_item.component} />)
          });
        })}
        <Route path="*" component={() => <h1>Página não encontrada</h1>} />

      </Switch>
    </BrowserRouter>



  );
}

export default Routes;