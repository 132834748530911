import fileDownload from 'js-file-download';
import api from './functions/api';
import { verifyDataPagination } from './functions/verify-response';
import DetExtract from './model/det-extract';
import GeneralBalance from './model/generalBalance';


interface PaginationResponse {
  list: Array<DetExtract>;
  total: number;
  totalcred?: number;
  totaldebit?: number
  totalsaldo?: number;
  msg?: string;
  statusCode?: string | number
}


export async function saldo(InmateBalance: any): Promise<object | void> {
  return api.get('/extrato/saldo/?' + new URLSearchParams(InmateBalance), { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'saldo_detento.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}

export async function extrato(InmateBalance: any): Promise<object | void> {
  return api.post('/extrato/extrato/?', InmateBalance, { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'extrato_detento.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}


export async function getAll(dados: any): Promise<PaginationResponse> {
  return api.post('/extrato/?', dados).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}
interface PaginationResponseGeneralBalance {
  list: Array<GeneralBalance>;
  total: number;
}

export async function getGeneralBalance(dados: any): Promise<PaginationResponseGeneralBalance> {
  return api.post('/extrato/general/', dados).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export function getGeneralBalancePDF(dados: any): Promise<object | void> {
  return api.post('/extrato/pdf/', dados, { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'Saldo_detento_geral.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}




