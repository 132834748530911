import { menu } from '../beans/menu';
import Menu from '../model/menu';
import { getRegionalName as apiRegional } from '../regional';
import { getUnity as apiUnity } from '../unity';
import { getDataToken } from './auth';

export const REGIONAL_KEY = '@sagep-Regional';
export const UNITY_KEY = '@sagep-Unity';
export const USER_KEY = '@sagep-User';


export function isAdmin(): boolean {
	let token = getDataToken();
	return token && token.adm ? true : false;
}

export function isAdminRegional(): boolean {
	let token = getDataToken();
	return token && token.adm && token.adm.type === 2 ? true : false;
}

export function isAdminUnidade(): boolean {
	let token = getDataToken();
	return token && token.adm && token.adm.type === 3 ? true : false;
}

export function isAdminMaster(): boolean {
	let token = getDataToken();
	return token && token.adm && token.adm.type === 1 ? true : false;
}

export function getAdminType(): number {
	let token = getDataToken();
	if (token && token.adm) return token.adm.type;
	return 0;
}

export function getLoggedId(): number {
	let token = getDataToken();
	if (token && token.adm) return token.adm.adm_id_login;
	return 0;
}

export function getMenuTop(): Array<Menu> {
	return verifyMenuAccess(menu);
}

function verifyMenuAccess(itemsMenu: Array<Menu>): Array<Menu> {
	let token = getDataToken();

	if (token && token.adm && token.adm.type === 1) return itemsMenu;

	let accessMenu: Array<Menu> = [];
	if (token && token.adm) {
		accessMenu = itemsMenu.filter(item => {
			let new_sub_menu = item.sub_menu.filter(sub_item => !sub_item.adm_master);
			item.sub_menu = new_sub_menu;
			if (new_sub_menu.length) return item;
		});
	}
	return accessMenu;
}

export async function getRegional(): Promise<string> {
	let regional = localStorage.getItem(REGIONAL_KEY);
	if (!regional || typeof regional === 'string') {
		let token = getDataToken();
		if (token && token.adm?.adm_id_regional) regional = await apiRegional(token.adm.adm_id_regional);

		if (regional) localStorage.setItem(REGIONAL_KEY, regional);
	}
	return regional ? regional : '';
}

export async function getUnity(): Promise<string> {
	let unity = localStorage.getItem(UNITY_KEY);
	if (!unity || typeof unity === 'string') {
		let token = getDataToken();
		if (token && token.adm?.adm_id_unity) unity = await apiUnity(token.adm.adm_id_unity).then(result => result && result.contact?.contact_name ? result.contact?.contact_name : '');
		if (unity) localStorage.setItem(UNITY_KEY, unity);
	}
	return unity ? unity : '';
}

export async function getUser(): Promise<string> {
	let user = localStorage.getItem(USER_KEY);
	return user ? user : '';
}