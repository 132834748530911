import { memo } from "react";
import { Dropdown } from "react-bootstrap";
import LinkIcon from "../LinkIcon";


interface ItemsDropDown {
    title: string,
    paramsFunc: number,
}

interface DropDownButton {
    icon: string,
    func: any,
    loading: boolean,
    textIcon: string,
    viewType:  number,
    idDropDown?: string,
    propsDropItem: Array<ItemsDropDown>,
    onClick?: React.EventHandler<any>


}


const DropDownButton: React.FC<DropDownButton> = ({ icon, func, loading, textIcon, idDropDown, propsDropItem, viewType, onClick }) => {
   
   
    return (
        <div onClick={viewType == 2 ? onClick : undefined }>
        <Dropdown className="ts-gutter-t  pt-3">
            <Dropdown.Toggle className={`drop-down-buttons ${viewType == 2 && 'drop-down-buttons-none'} `} variant="" id={idDropDown}>
                <div className="ts-link-icon">
                    <LinkIcon text={textIcon} icon={icon} />
                    {viewType != 2 && (<div className="arrow-down" ></div>)}
                </div>
            </Dropdown.Toggle>
            
            {
                (viewType != 2) && (
                    <Dropdown.Menu>
                        <>
                            {
                                propsDropItem.map(({ paramsFunc, title }, key) => {
                                    return (<Dropdown.Item key={key} onSelect={(e: any) => { func(Number(paramsFunc)); }} disabled={loading}>{title}</Dropdown.Item>)
                                })
                            }
                        </>
                    </Dropdown.Menu>
                )
            }
        
            </Dropdown>
        </div>

    )
}

export default memo(DropDownButton);