import fileDownload from 'js-file-download';
import api from './functions/api';
import { verifyData, verifyDataPagination } from './functions/verify-response';
import Historic from './model/Historic';

interface PaginationResponse {
  list: Array<Historic>;
  total: number;
}

export async function getHistoric(data: any): Promise<Historic | undefined> {
  return api.post('/historic/info/', data).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}

export async function getHistoricAll(filters: any): Promise<PaginationResponse> {
  return api.post('/historic/', filters).then(response => {
    return verifyDataPagination(response);
  }).catch(error => {
    return verifyDataPagination(error.response);
  });
}

export async function downloadFile(data: any): Promise<boolean> {
  return api.post('/historic/download/', data).then(response => {
    try {
      fileDownload(Buffer.from(response.data.arquivo), response.data.arquivo_nome);
      return true;
    } catch (error: any) {
      console.log(error);
      return false;
    }
  }).catch(error => {
    return false;
  });
}

export async function getHistoricPdf(filters: any): Promise<object | void> {
  return api.post('/historic/pdf/', filters, { responseType: 'blob' }).then(response => {
    try {
      fileDownload(response.data, 'Historico_unidade.pdf');
    } catch (error: any) {
      console.log(error);
    }
  }).catch(error => {
    console.log(error);
  });
}
