import api from './functions/api';
import { verifyDataList, verifyData } from './functions/verify-response';
import Admin from './model/admin';

export async function getAdmins(): Promise<Array<Admin>> {
  return api.get('/adm/').then(response => {
    return verifyDataList(response);
  }).catch(error => {
    return verifyDataList(error.response);
  });
}

export async function getAdmin(id: number): Promise<Admin | undefined> {
  return api.get('/adm/'+id).then(response => {
    return verifyData(response);
  }).catch(error => {
    return verifyData(error.response);
  });
}
