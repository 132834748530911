import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import moment from 'moment';

import { getAdminType } from '../../services/functions/user-info';
import { formatMoney } from '../../services/functions/format';
import BarChartObject from '../../services/model/bar-chart';
import Regional from '../../services/model/regional';
import Unity from '../../services/model/unity';
import BankAccount from '../../services/model/bank-account';
import ReleasesReturn from '../../services/model/releases-return';
import Releases from '../../services/model/releases';
import { getReleases, getBankAccounts, getReleasesPdf } from '../../services/releases';

import PageTitle from '../../components/PageTitle';
import LinkIcon from '../../components/LinkIcon';
import FormInputGroup from '../../components/FormInputGroup';
import SelectUnity from '../../components/SelectFilterUnity';
import Alert from '../../components/Alert';
import Loading from '../../components/Loading';

import { Row, Col, Collapse, Table } from 'react-bootstrap';

import releasesIcon from '../../assets/images/icons/cash-register.png';
import searchIcon from '../../assets/images/icons/search.svg';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import { useReactToPrint } from 'react-to-print';
import PaginationCP from '../../components/PaginationCP';

const ReleasesList: React.FC = () => {

    const itemsPerPage = 30;
    const [pagination, setPagination] = useState<number>(0);
    const [nReleases, setNReleases] = useState<number>(0);
    const [listReleases, setListReleases] = useState<Array<Releases>>([]);
    const [bankAccount, setBankAccount] = useState<BankAccount | undefined>(undefined);
    const chartsRef = useRef(null);
    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();
    const [idTimer, setIdTimer] = useState<NodeJS.Timeout>(setTimeout(() => { }, 0))

    const [filters, setFilters] = useState<any>({
        id_unity: '',
        numconta: '', //id da conta
        dtmov_From: '',
        dtmov_To: '',
        dtregconta_From: '',
        dtregconta_To: '',
        vlentE_From: '',
        vlentE_To: '',
        vlentS_From: '',
        vlentS_To: '',
        historico: '',
        docto: '',
        regconta: '',
        pagination: '1',
    });



    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [filterUnity, setFilterUnity] = useState<Unity | undefined>(undefined);
    const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);

    const verifyFilters = useCallback((key: string, newValue: string) => {
        let newFilters = filters;
        if (filterUnity && filterUnity.id_unity) newFilters['id_unity'] = filterUnity.id_unity;
        if (key === 'dtmov_From' || key === 'dtmov_To' || key === 'dtregconta_From' || key === 'dtregconta_To') {
            let value = moment(newValue).format('DD-MM-YYYY');
            newValue = value !== 'Invalid date' ? value : '';
        }
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        if (filters['numconta'] != '') {
            screenUpdate();
        }
    }, [filterUnity, filters])

    useEffect(() => {
        if (filterUnity && filterUnity.id_unity) {
            getBankAccounts(filterUnity.id_unity).then(response => {
                setBankAccounts(response);
            }).catch(e => {
                setMsgAlertError(e);
                setOpenAlertError(true)
            })
        }
        verifyFilters('numconta', '');
    }, [filterUnity]);

    const [loading, setLoading] = useState<boolean>(false);
    const [viewType, setViewType] = useState<number>(1);
    const [openSearch, setOpenSearch] = useState<boolean>(false);

    function screenUpdate() {
        setLoading(true);
        getReleases(filters).then(response => {
            setBankAccount(response.account);
            setListReleases(response.list);
            setNReleases(response.total);
            response.total > 0 ? setPagination(Math.ceil(response.total / itemsPerPage)) : setPagination(0);
            setLoading(false);
            updateChats(response);
        }).catch(e => {
            setMsgAlertError(e);
            setOpenAlertError(true);
            setLoading(false);
        })
    }



    function handleExportAll() {

        if (nReleases > 0) {
            setLoading(true);
            getReleasesPdf(filters).then(() => setLoading(false));
        } else {
            setMsgAlertError('Não há registros para imprimir');
            setOpenAlertError(true);
        }
    }

    const [dataValues, setDataValues] = useState<Array<BarChartObject>>();
    const [dataBalance, setDataBalance] = useState<Array<BarChartObject>>();

    function updateChats(dataList: ReleasesReturn) {
        let newValues: Array<BarChartObject> = [];
        let newBalance: Array<BarChartObject> = [];
        if (dataList?.totais) {
            let data = dataList.totais;
            newValues = [
                { name: 'Entradas', valor: data.totalVlent ? Number(data.totalVlent) : 0 },
                { name: 'Saídas', valor: data.totalVlsai ? Number(data.totalVlsai) : 0 },
            ];
            newBalance = [
                { name: 'Compensado', valor: data.totalCompensado ? Number(data.totalCompensado) : 0 },
                { name: 'Não compensado', valor: data.totalNCompensado ? Number(data.totalNCompensado) : 0 },
            ];
        }
        setDataValues(newValues);
        setDataBalance(newBalance);
    }
    const colorsValues = ['#76b64f', '#d75a4a'];

    const renderCustomizedLabel = ({ value }: any) => formatMoney(value.toFixed(2));

    const customTooltip = (value: any) => formatMoney(Number(value).toFixed(2));

    const handlePrint = useReactToPrint({
        content: () => chartsRef.current,
    });

    return (
        <div id="page-releases">

            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} />

            <main>
                <div className="container">
                    <div className="ts-gutter">
                        <PageTitle text="Grade de lançamentos" icon={releasesIcon} />
                    </div>
                    <div>
                        <Row className="align-items-center mx-n2">
                            <Col sm={6} className="ts-gutter-t px-2">
                                <SelectUnity
                                    selectedUnity={filterUnity}
                                    setSelectedUnity={setFilterUnity}
                                    selectedRegional={filterRegional}
                                    setSelectedRegional={setFilterRegional}
                                    id="filter"
                                    size={6}
                                    disabled={loading}
                                />
                            </Col>
                            {filterUnity && (
                                <Col sm={6} className="ts-gutter-t px-2">
                                    <Row className='mx-n2'>
                                        <Col lg={6} className='px-2'>
                                            <FormInputGroup label="Conta bancária" id="filter_bank">
                                                <select id="filter_bank" onChange={(e) => verifyFilters('numconta', e.target.value)} value={filters.numconta} disabled={loading}>
                                                    <option value="">Selecionar</option>
                                                    {bankAccounts.map((item) => (
                                                        <option value={item.numconta}>{item.descricao}</option>
                                                    ))}
                                                </select>
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={6} className='px-2'>
                                            <FormInputGroup label="Modo de visualização" id="filter_view">
                                                <select id="filter_view" onChange={(e) => setViewType(Number(e.target.value))} value={viewType} disabled={loading}>
                                                    <option value="1">Lista</option>
                                                    <option value="2">Gráfico</option>
                                                </select>
                                            </FormInputGroup>
                                        </Col>
                                    </Row>
                                </Col>)}
                        </Row>
                    </div>
                </div>

                {loading && (<Loading />)}
                {filters.numconta && (
                    <div className="container-fluid">
                        {bankAccount && (
                            <Row className="ts-gutter-t">
                                <Col lg={12}>
                                    <p className="ts-page-text mb-3"><b>DADOS DA CONTA:</b></p>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Nº conta" id="">
                                        <p className="ts-page-text">{bankAccount.numconta}</p>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Banco" id="">
                                        <p className="ts-page-text">{bankAccount.codbanco}</p>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Agência" id="">
                                        <p className="ts-page-text">{bankAccount.agencia}</p>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Conta" id="">
                                        <p className="ts-page-text">{bankAccount.conta}</p>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Descrição" id="">
                                        <p className="ts-page-text">{bankAccount.descricao}</p>
                                    </FormInputGroup>
                                </Col>
                                <Col lg={2}>
                                    <FormInputGroup label="Saldo" id="">
                                        <p className="ts-page-text">{bankAccount.saldo ? 'R$ ' + formatMoney(bankAccount.saldo) : '0,00'}</p>
                                    </FormInputGroup>
                                </Col>
                            </Row>)}
                        <div>
                            <p className="ts-page-text ts-gutter-t"><b>LANÇAMENTOS DA CONTA:</b></p>
                            <Row className="justify-content-between align-items-center">
                                <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nReleases} registros encontrados</p></Col>
                                <Col sm="auto" className="ts-gutter-t">
                                    <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                        <LinkIcon text="Pesquisar" icon={searchIcon} />
                                    </button>
                                    <button className="ts-link-icon" onClick={viewType !== 2 ? handleExportAll : handlePrint} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Collapse in={openSearch} appear={true}>
                            <div className="ts-search ts-form-box ts-gutter">
                                <p className="ts-page-text mb-3">Pesquisar por:</p>
                                <Row className="mx-n2">
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Data movimentação - de" id="dtmov_From">
                                            <input type="date" id="dtmov_From" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('dtmov_From', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Data movimentação - até" id="dtmov_To">
                                            <input type="date" id="dtmov_To" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('dtmov_To', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Data compensação - de" id="dtregconta_From">
                                            <input type="date" id="dtregconta_From" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('dtregconta_From', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Data compensação - até" id="dtregconta_To">
                                            <input type="date" id="dtregconta_To" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('dtregconta_To', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Nº documento" id="docto">
                                            <input type="text" id="docto" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('docto', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Histórico" id="historico">
                                            <input type="text" id="historico" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('historico', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Valor entrada - de" id="vlentE_From">
                                            <input type="number" id="vlentE_From" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('vlentE_From', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Valor entrada - até" id="vlentE_To">
                                            <input type="number" id="vlentE_To" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('vlentE_To', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Valor saída - de" id="vlentS_From">
                                            <input type="number" id="vlentS_From" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('vlentS_From', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Valor saída - até" id="vlentS_To">
                                            <input type="number" id="vlentS_To" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('vlentS_To', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Situação" id="regconta">
                                            <select id="regconta" onChange={(e) => verifyFilters('regconta', e.target.value)}>
                                                <option value="">Todos</option>
                                                <option value="S">Compensado</option>
                                                <option value="N">Não compensado</option>
                                            </select>
                                        </FormInputGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                        <div className="ts-gutter">
                            {viewType == 1 && !!listReleases.length && (
                                <>
                                    <Table striped hover size="sm" className="ts-items-table ts-lists">
                                        <thead>
                                            <tr>
                                                <th>Nº conta</th>
                                                <th>Nº documento</th>
                                                <th>Favorecido</th>
                                                <th className='text-right'>Valor Entrada</th>
                                                <th className='text-right'>Valor Saída</th>
                                                <th>Data</th>
                                                <th>Cód. CTB</th>
                                                <th>Desc. CTB</th>
                                                <th>Histórico</th>
                                                <th>Situação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!listReleases && listReleases.map((item, key) => (
                                                <tr key={key} style={{ 'backgroundColor': (item.regconta === 'S' ? '#fff' : 'rgba(14, 173, 212, 0.20)') }}>
                                                    <td><p>{item.numconta}</p></td>
                                                    <td><p>{item.docto}</p></td>
                                                    <td><p>{item.favorecido}</p></td>
                                                    <td><p className='text-right'>{item.vlent}</p></td>
                                                    <td><p className='text-right'>{item.vlsai}</p></td>
                                                    <td><p>{item.dtmov}</p></td>
                                                    <td><p>{item.codctb}</p></td>
                                                    <td><p title={item.descricao}>{item.descricao}</p></td>
                                                    <td><p>{item.historico}</p></td>
                                                    <td><p>{item.regconta === 'S' ? 'Compensado' : 'Não compensado'}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <PaginationCP
                                        pagination={pagination}
                                        filter={filters.pagination}
                                        filterKey={'pagination'}
                                        verifyFilters={verifyFilters}
                                    />
                                </>)}
                            {viewType == 1 && !!listReleases.length && (
                                <Row ref={chartsRef}>
                                    <Col lg={6}>
                                        <div className="ts-charts mx-auto">
                                            <ResponsiveContainer width="100%" aspect={1.5}>
                                                <PieChart>
                                                    <Pie
                                                        dataKey="valor"
                                                        isAnimationActive={false}
                                                        data={dataValues}
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={100}
                                                        fill="#385a7d"
                                                        label={renderCustomizedLabel}
                                                    >
                                                        {!!dataValues && dataValues.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colorsValues[index]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip formatter={customTooltip} />
                                                    <Legend />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="ts-charts mx-auto">
                                            <ResponsiveContainer width="100%" aspect={1.5}>
                                                <PieChart>
                                                    <Pie
                                                        dataKey="valor"
                                                        isAnimationActive={false}
                                                        data={dataBalance}
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={100}
                                                        fill="#385a7d"
                                                        label={renderCustomizedLabel}
                                                    >
                                                        {!!dataBalance && dataBalance.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colorsValues[index]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip formatter={customTooltip} />
                                                    <Legend />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </div>)}

            </main>
        </div>
    );
}

export default ReleasesList;