import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

import { name, version } from '../../../package.json';
import logoImg from '../../assets/images/logo.png';
import Logout from '../../components/Logout';
import { InfoConfigContext } from '../../contexts/InfoContext';
import { getInfoConfig } from '../../services/configurations/configurations';
import { getMenuTop, getRegional, getUnity, getUser } from '../../services/functions/user-info';
import InfoConfig from '../../services/model/configurations/configurations';
import whatsIcon from './../../assets/images/icons/whatsapp-social-media.svg';
import studentIcon from './../../assets/images/icons/student.svg';

import './styles.css';
import ReactInputMask from 'react-input-mask';
import { formatPhone } from '../../services/functions/format';

interface PageHeaderProps {
    opened?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ opened }) => {

    const [regional, setRegional] = useState<string>();
    const [unity, setUnity] = useState<string>();
    const [user, setUser] = useState<string>();

    const { infoConfig, setInfoConfig } = useContext(InfoConfigContext);

    useEffect(() => {
        getRegional().then(response => setRegional(response));
        getUnity().then(response => setUnity(response));
        getUser().then(response => setUser(response));
    }, []);

    return (
        <>
            <header id="page-header">
                <div className="ts-page-header">

                    <div className="ts-menu-top">
                        <Navbar bg="light" expand="lg">
                            <div className="container">
                                <div className="d-flex justify-content-between w-100">
                                    <Navbar.Toggle aria-controls="top-navbar" />
                                    <Navbar.Collapse id="top-navbar">
                                        <Nav className="menu ts-menu-top">
                                            {getMenuTop().map((item, key) => (
                                                <NavDropdown title={item.title} id="top-nav-dropdown" key={key}>
                                                    {item.sub_menu.map((sub_item, sub_key) => {

                                                        return !sub_item.type ? (
                                                            <Link key={sub_item.id} to={sub_item.url} className="dropdown-item" >{sub_item.title}</Link>
                                                        ) :
                                                            sub_item.type === 'text' ? (<p key={sub_key} className="item-text small" >{sub_item.title}</p>) : (<hr key={sub_key} />)

                                                    })}
                                                </NavDropdown>
                                            ))}
                                        </Nav>
                                    </Navbar.Collapse>
                                    <Logout />
                                    <div className={'header-user'}><p><b>Usuario:</b> {user}</p></div>


                                </div>
                            </div>
                            <div className={'version'}><p>Versão: {version}</p></div>
                        </Navbar>
                    </div>

                    <div className="container">
                        <div className="ts-menu-main">

                            <Row className="content-logo align-items-center justify-content-between menu-main-content ">

                                <Col lg="9">
                                    <Link to="/"><img src={logoImg} alt="SAGEP" className="logo" /></Link>
                                    {regional && (<p><b>Regional: </b>{regional}</p>)}
                                    {unity && (<p><b>Unidade: </b>{unity}</p>)}
                                </Col>
                                <Col className="text-center ts-contact-info">
                                    {infoConfig?.contactEmail && (<p>Email: <a href={`mailto:${infoConfig?.contactEmail}`}>{infoConfig?.contactEmail}</a></p>)}
                                    {infoConfig?.phoneInfo && (<p>Telefone: {formatPhone(infoConfig.phoneInfo)}</p>)}

                                    <Row className="d-flex align-items-center justify-content-center">
                                        <Col>
                                            {infoConfig?.whats && (<><a href={"whatsapp://send?phone=" + infoConfig.whats} target="_blank"><p>Whatsapp </p><img src={whatsIcon} alt="whatsapp" className="logoContact" /></a></>)}
                                        </Col>
                                        <Col>
                                            {infoConfig?.linkVideos && (<><a href={infoConfig.linkVideos} target="_blank"><p>Painel de videos </p><img src={studentIcon} alt="Portal de videos" className="logoContact" /></a></>)}
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>


                        </div>
                    </div>

                </div>
            </header >
        </>
    );

}

export default memo(PageHeader);