import React, { useState, useMemo, useEffect, } from 'react';

import { getInmatePerUnity, getInmatePerUnityPdf } from '../../services/inmate';

import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import configIcon from '../../assets/images/icons/configs.svg';

import LinkIcon from '../../components/LinkIcon';
import printIcon from '../../assets/images/icons/printer.svg';

import '../../assets/styles/list.css';
import '../../assets/styles/form.css';
import './styles.css';
import SelectUnity from '../../components/SelectFilterUnity';
import Unity from '../../services/model/unity';
import { formatMoney, formatNumber } from '../../services/functions/format';
import Regional from '../../services/model/regional';
import Loading from '../../components/Loading';
import Radio from '../../components/Radio';
import { DetailedInmate, SimpleInmate } from '../../services/model/inmateDetail';
import FormInputGroup from '../../components/FormInputGroup';
import moment from 'moment';


const InmateList: React.FC = () => {
    const [inmate, setInmate] = useState<DetailedInmate | SimpleInmate | undefined>(undefined);
    const [totals, setTotals] = useState<DetailedInmate['totals'] | SimpleInmate['totals'] | undefined>();
    const [selectedUnity, setSelectedUnity] = useState<Unity | undefined>(undefined);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>('Simples');
    const [month, setMonth] = useState<string>(moment().format('YYYY-MM'));

    useEffect(() => {
        setValue('Simples')
    }, [selectedUnity?.id_unity !== -1])

    useMemo(() => {
        if (selectedUnity?.id_unity) {
            setLoading(true)
            let filter: any = {
                id_regional: filterRegional?.id_regional,
                id_unity: selectedUnity?.id_unity,
                type: value,
                month
            }
            getInmatePerUnity(filter).then(response => {
                if (response) {
                    setInmate(response);
                    setTotals(response.totals)
                }
                setLoading(false)
            });
        }
    }, [selectedUnity, month, value]);


    function handleExport() {
        setLoading(true)
        let inmateArray: any = {
            id_regional: filterRegional?.id_regional,
            id_unity: selectedUnity?.id_unity,
            type: value,
            month
        }
        getInmatePerUnityPdf(inmateArray).then(() => setLoading(false));
    }

    return (
        <div id="page-inmate">
            {loading && (<Loading />)}
            <main className="container">
                <div className="ts-gutter">
                    <Row>
                        <Col lg={10}>
                            <PageTitle text="Detentos" icon={configIcon} />
                        </Col>
                        {!!selectedUnity?.id_unity &&
                            <>
                                <Col lg={2} style={{ paddingLeft: '6.7%' }}>
                                    {<button className="ts-link-icon" onClick={handleExport} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>}
                                </Col>
                            </>
                        }
                    </Row>
                </div>
                {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                    <Col style={{ paddingLeft: '75%' }}><b>Visualização</b></Col>
                }
                <SelectUnity
                    selectedUnity={selectedUnity}
                    setSelectedUnity={setSelectedUnity}
                    selectedRegional={filterRegional}
                    setSelectedRegional={setFilterRegional}
                    id="contact"
                    size={3}
                    disabled={loading}
                    all={true}
                >
                    {selectedUnity?.id_unity &&
                        <Col lg={2}>
                            <FormInputGroup label="Mês" id="month">
                                <input type="month" id="month" value={month} onChange={(e) => {
                                    setMonth(e.target.value)
                                }} />
                            </FormInputGroup>
                        </Col>
                    }
                    {filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1 &&
                        <Col lg={3} style={{ textAlign: 'center', marginTop: '2.5%' }}>
                            <Radio options={['Simples', 'Detalhado']} value={value} setValue={setValue} style={{ marginRight: '4px', marginLeft: '12px' }} />
                        </Col>
                    }
                </SelectUnity>

                {!!selectedUnity?.id_unity && value && inmate?.list &&
                    < div className="ts-gutter " style={loading ? { opacity: '0.1' } : { opacity: '1.0' }}>
                        {value === 'Simples' && (
                            <Table striped size="sm" className="ts-items-table tabelaInmate ">
                                <thead>
                                    <tr>
                                        <th className='inm-th inm-greenTitles' colSpan={inmate?.list.length !== 1 ? 4 : 3}>
                                            <p className='Regititle'>
                                                {filterRegional?.id_regional !== -1 && selectedUnity?.id_unity === -1 ? '  ' + filterRegional?.regional_name :
                                                    filterRegional?.id_regional === -1 && selectedUnity?.id_unity !== -1 ? ' ' + selectedUnity?.contact_name :
                                                        filterRegional?.id_regional !== -1 && selectedUnity !== -1 ? ' ' + selectedUnity?.contact_name : ' Todas as Unidades'}
                                            </p>
                                        </th>
                                    </tr>
                                </thead>
                                <thead style={{ zIndex: 1, position: 'sticky' }}>
                                    <tr className='inm-fixed inm-greenTitles'>
                                        {inmate?.list.length !== 1 && <th className='inm-th right ' >Unidade</th>}
                                        <th className={inmate?.list.length !== 1 ? 'inm-th left right inm-width' : "inm-th right inm-width"}>Ativos</th>
                                        <th className='inm-th left right inm-width'>Trabalhando</th>
                                        <th className='inm-th left inm-width' >Vagas Abertas</th>
                                    </tr>
                                </thead>
                                <tbody style={{ zIndex: 1 }}>
                                    {!!inmate && (inmate.list as Array<SimpleInmate['list'][0]>).map((item, key: any) => (
                                        <>
                                            <tr key={key}>
                                                {inmate?.list.length !== 1 ? <td className='inm-td right  '><p className='colun1'>{item.unity_name}</p></td> : ''}
                                                <td className={inmate?.list.length !== 1 ? 'inm-td left right' : "inm-td  right"}>{formatNumber(String(item?.inmate_active))}</td>
                                                <td className='inm-td left right'>{formatNumber(String(item?.inmate_working))}</td>
                                                <td className='inm-td left'>{formatNumber(String(item?.vacancy))} </td>
                                            </tr>
                                        </>
                                    ))}
                                    {inmate && totals && (
                                        ((filterRegional?.id_regional === -1 && selectedUnity?.id_unity === -1) || (filterRegional?.id_regional != -1 && selectedUnity?.id_unity === -1)) &&
                                        <tr className='inm-th inm-greenTitles'>
                                            <td className='inm-td right'><p className='colun1'>Total</p></td>
                                            <td className='inm-td left right'> {formatNumber(String((totals as SimpleInmate['totals']).sumInmate_active))}</td>
                                            <td className='inm-td left right' > {formatNumber(String((totals as SimpleInmate['totals']).sumInmate_working))}</td>
                                            <td className='inm-td left' > {formatNumber(String((totals as SimpleInmate['totals']).sumVacancies))}</td>
                                        </tr>)}
                                </tbody>
                            </Table>
                        )}


                        {value === 'Detalhado' &&
                            <Table striped size="sm" className="ts-items-table tabelaInmate">
                                <thead>
                                    <tr>
                                        <th className='inm-th inm-greenTitles' colSpan={4}><p className='Regititle'> Todas as Unidades</p></th>
                                    </tr>
                                </thead>
                                {inmate && value === 'Detalhado' && (inmate.list as Array<DetailedInmate['list'][0]>).map((regionalItem, key: any) => (
                                    <>
                                        {regionalItem && regionalItem.inmateArray &&
                                            <>
                                                <tr key={key}>
                                                    <td className="inm-th coloredTitles" colSpan={4}> {regionalItem?.inmateArray[0].regional_name} </td>
                                                </tr>
                                                <thead>
                                                    <tr className='tr'>
                                                        <th className='inm-th right' >Unidade</th>
                                                        <th className='inm-th left right inm-width' >Ativos</th>
                                                        <th className='inm-th left right inm-width'>Trabalhando</th>
                                                        <th className='inm-th left inm-width'  >Vagas Abertas</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!!regionalItem && regionalItem.inmateArray.map((item, key2: any) => {
                                                        return (
                                                            <tr key={key2}>
                                                                <td className='inm-td right'><p className='colun1'>{item.unity_name}</p></td>
                                                                <td className="inm-td left right">{formatNumber(String(item.inmate_active))}</td>
                                                                <td className='inm-td left right '> {formatNumber(String(item.inmate_working))}</td>
                                                                <td className='inm-td left'> {formatNumber(String(item.vacancy))}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                                <tr className='coloredTitles'>
                                                    <td className='inm-td right ' ><p className='colun1 ' style={{ fontSize: '100%' }}>Total Regional</p></td>
                                                    <td className='inm-td left right' > {formatNumber(String((inmate.list as Array<DetailedInmate['list'][0]>)[key].sumInmate_active))}</td>
                                                    <td className='inm-td left right' >{formatNumber(String((inmate.list as Array<DetailedInmate['list'][0]>)[key].sumInmate_working))}</td>
                                                    <td className='inm-td left' > {formatNumber(String((inmate.list as Array<DetailedInmate['list'][0]>)[key].sumVacancies))}</td>
                                                </tr>
                                                <br></br>
                                            </>
                                        }
                                    </>
                                ))}
                                {inmate && totals &&
                                    <tr className='inm-greenTitles' style={{ fontSize: '150%' }}>
                                        <th className='inm-th right'>Total Geral</th>
                                        <th className='inm-th left right'> {formatNumber(String((totals as DetailedInmate['totals']).sumFinalInmate_active))}</th>
                                        <th className='inm-th left right' > {formatNumber(String((totals as DetailedInmate['totals']).sumFinalInmate_working))}</th>
                                        <th className='inm-th left' > {formatNumber(String((totals as DetailedInmate['totals']).sumFinalVacancies))}</th>
                                    </tr>}
                            </Table>
                        }
                    </div>
                }
            </main >
        </div >
    );
}

export default InmateList;