import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Area, Bar, BarChart, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getCashHandling, getCashHandlingPdf, getCashInfo, getFilteredByBillPlan } from '../../services/cash-handling';
import { formatMoney } from '../../services/functions/format';
import BarChartObject from '../../services/model/bar-chart';
import CashHandling from '../../services/model/cash-handling';
import { CashInfo } from '../../services/model/cash-info';
import CashReturn from '../../services/model/cash-return';
import Regional from '../../services/model/regional';
import Unity from '../../services/model/unity';

import Alert from '../../components/Alert';
import FormInputGroup from '../../components/FormInputGroup';
import LinkIcon from '../../components/LinkIcon';
import Loading from '../../components/Loading';
import PageTitle from '../../components/PageTitle';
import SelectUnity from '../../components/SelectFilterUnity';

import { Col, Collapse, Modal, Row, Table } from 'react-bootstrap';

import cashHandlingIcon from '../../assets/images/icons/cash-register.png';
import configIcon from '../../assets/images/icons/configs.svg';
import printIcon from '../../assets/images/icons/printer.svg';
import searchIcon from '../../assets/images/icons/search.svg';


import '../../assets/styles/form.css';
import '../../assets/styles/list.css';
import './style.css';

import { useReactToPrint } from 'react-to-print';
import { valueType } from '../../assets/beans/binds';
import CustomTooltip from '../../components/Charts/CustomToolTip';
import { ArrowIcon } from '../../components/Icons';
import PaginationCP from '../../components/PaginationCP';
import SelectGeneralData from '../../components/SelectGeneralData';
import TdResponsive from '../../components/TdResponsive';



const CashList: React.FC = () => {

    const itemsPerPage = 30;

    const modalItemPerPage = 15;
    const cashListItemPerPage = 10;
    const [pagination, setPagination] = useState<number>(0);
    const [nCashHandling, setNCashHandling] = useState<number>(0);
    const [listCashHandling, setListCashHandling] = useState<Array<CashHandling>>([]);
    const [listCashReturn, setListCashReturn] = useState<CashReturn>();
    const chartsRef = useRef(null);
    const [filterRegional, setFilterRegional] = useState<Regional | undefined>(undefined);
    const [filterUnity, setFilterUnity] = useState<Unity | undefined>(undefined);
    const [cashInfo, setCashInfo] = useState<Array<Partial<CashInfo>>>([]);
    const [idTimer, setIdTimer] = useState<NodeJS.Timeout>(setTimeout(() => { }, 0))
    const [selectedCashInfo, setSelectedCashInfo] = useState<Partial<CashInfo> | undefined>()



    const [filters, setFilters] = useState<any>({
        id_unity: '',
        nrocx: '', //id da conta
        dtcx_From: '',
        dtcx_To: '',
        conta: '',
        codctb: '',
        nome: '',
        docto: '',
        descricao: '',
        pagination: '1'
    })
    const [cashListPagination, setCashListPagination] = useState<number>(1)
    const [cashListFilters, setCashListFilters] = useState<any>({ pagination: '1' })
    const verifyFilters = useCallback((key: string, newValue: string) => {
        let newFilters = filters;
        newFilters['id_unity'] = filterUnity?.id_unity;
        if (key === 'dtcx_From' || key === 'dtcx_To') {
            let value = moment(newValue).format('DD-MM-YYYY');
            newValue = value !== 'Invalid date' ? value : '';
        }
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setFilters(newFilters);
        screenUpdate();
    }, [filterUnity, filters]);


    const inputRef = useRef<HTMLInputElement | null | any>(null)

    useEffect(() => {
        setCashListFilters({ pagination: '1' })
        if (filterUnity && filterUnity.id_unity) {
            listUpdate()
        } else {
            verifyFilters('nrocx', '')
        }
    }, [filterUnity]);


    function verifyCashListPagination(key: string, newValue: string) {
        if (filterUnity && filterUnity.id_unity) {
            let newFilters = cashListFilters;
            newFilters['pagination'] = newValue;
            setCashListFilters(newFilters);
            listUpdate();
        }
    };

    function listUpdate() {
        if (filterUnity && filterUnity.id_unity) {
            let data = {
                id_unity: filterUnity.id_unity,
                pagination: cashListFilters.pagination
            }
            setLoading(true)
            getCashInfo(data).then(response => {
                if (response.list.length) {
                    setCashListPagination(response.total && response.total > 0 ? Math.ceil(response.total / cashListItemPerPage) : 0);
                    setCashInfo(response.list);
                    if (inputRef.current) inputRef.current.value = response.list[response.list.length - 1].nrocx
                    verifyFilters('nrocx', String(response.list[response.list.length - 1].nrocx));
                    setSelectedCashInfo(response.list[response.list.length - 1])

                } else {
                    setLoading(false)
                    setOpenAlertError(true)
                    setMsgAlertError('Ocorreu algum erro, Por favor recarregue a página')
                }
            });
        }
    }

    const [loading, setLoading] = useState<boolean>(false);
    const [viewType, setViewType] = useState<number>(1);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [openCashList, setOpenCashList] = useState<boolean>(true);

    const [openCashModal, setOpenCashModal] = useState<boolean>(false);
    const [selectedValueType, setSelectedValueType] = useState<number>(0);

    const [detailedModal, setDetailedModal] = useState<Array<Partial<CashInfo>>>([]);
    const [nDetailedModal, setNDetailedModal] = useState<number>(0)


    function screenUpdate() {
        setLoading(true);
        getCashHandling(filters).then(response => {
            if (response) {
                setListCashReturn(response);
                setListCashHandling(response.list);
                setNCashHandling(response.total);
                setPagination(response.total > 0 ? Math.ceil(response.total / itemsPerPage) : 0);
                setLoading(false);
                updateChats(response);
            }
        });
    };

    const [modalPagination, setModalPagination] = useState<number>(0)
    const [modalFilters, setModalFilters] = useState<any>({ pagination: '1' })

    function verifyModalFilters(key: string, newValue: string) {
        let newFilters = modalFilters;
        newFilters[key] = newValue;
        if (key !== 'pagination') newFilters['pagination'] = '1';
        setModalFilters(newFilters);
        OpenModal()
    }


    function OpenModal() {
        if (selectedValueType) {
            let data = {
                id_unity: filterUnity?.id_unity,
                valueType: selectedValueType,
                nrocx: selectedCashInfo?.nrocx,
                filters: modalFilters

            }
            getFilteredByBillPlan(data).then(response => {
                if (response.list[0]) {
                    setOpenCashModal(true)
                    setDetailedModal(response.list)
                    setNDetailedModal(response.total)
                    setModalPagination(response.total && response.total > 0 ? Math.ceil(response.total / modalItemPerPage) : 0);
                } else {
                    setOpenAlertModal(true)

                }

            })
        }
    }
    useEffect(() => {
        OpenModal()
    }, [selectedValueType])


    function handleCloseCashModal() {
        setOpenCashModal(false)
        setDetailedModal([])
        setModalPagination(0)
        setModalFilters({ pagination: '1' })
        setSelectedValueType(0)
        setNDetailedModal(0)
        setOpenAlertModal(false)
    }

    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [msgAlertError, setMsgAlertError] = useState<string>();

    const [openAlertModal, setOpenAlertModal] = useState<boolean>(false);

    const handlePrint = useReactToPrint({
        content: () => chartsRef.current,
    });
    function handleExportAll() {
        if (nCashHandling > 0) {
            setLoading(true);
            getCashHandlingPdf(filters).then(() => {
                setLoading(false);
            });
        } else {
            setMsgAlertError('Não há registros para imprimir');
            setOpenAlertError(true);
        }
    }

    const [dataBank, setDataBank] = useState<Array<BarChartObject>>();
    const [dataValues, setDataValues] = useState<Array<BarChartObject>>();
    const [dataValues2, setDataValues2] = useState<Array<BarChartObject>>();
    const [dataBalance, setDataBalance] = useState<Array<BarChartObject>>();
    const [dataBalanceFilter, setDataBalanceFilter] = useState<Array<BarChartObject>>();


    function updateChats(dataList: CashReturn) {
        let newBank: Array<BarChartObject> = [];
        if (dataList?.bank) {
            newBank = dataList.bank.map((item, key) => {
                return { name: item.descricao, valor: item.saldo ? Number(item.saldo.replaceAll('.', '').replace(',', '.')) : 0 };
            });
        }
        setDataBank(newBank);
        let newValues: Array<BarChartObject> = [];
        if (dataList?.informative?.abstract) {
            let data = dataList.informative.abstract;
            if (data.lastSixCashInfo) {
                data.lastSixCashInfo.map((item, key) => {
                    if (item.nrocx && dataList?.cash?.nrocx && item.nrocx < dataList.cash.nrocx) {
                        newValues.push(
                            {
                                name: 'Caixa ' + item.nrocx,
                                unidade: item.unityTotal ? Number(item.unityTotal.replaceAll('.', '').replace(',', '.')) : 0,
                                detentos: item.inmateTotal ? Number(item.inmateTotal.replaceAll('.', '').replace(',', '.')) : 0,
                                diferença: item.diference ? Number(item.diference.replaceAll('.', '').replace(',', '.')) : 0
                            }
                        );
                    }
                });
            }
            newValues.push(
                {
                    name: 'Caixa ' + dataList.cash?.nrocx,
                    unidade: data.unityTotal ? Number(data.unityTotal.replaceAll('.', '').replace(',', '.')) : 0,
                    detentos: data.inmateTotal ? Number(data.inmateTotal.replaceAll('.', '').replace(',', '.')) : 0,
                    diferença: data.diference ? Number(data.diference.replaceAll('.', '').replace(',', '.')) : 0
                }
            );
        }
        setDataValues(newValues);
        let newValues2: Array<any> = [];
        let newBalance: Array<BarChartObject> = [];
        let newBalanceFilter: Array<BarChartObject> = [];
        if (dataList?.informative?.infoCash) {
            let data = dataList.informative.infoCash;
            newValues2 = [
                {
                    name: 'Compensação',
                    "cp": data.compensatedTotal ? Number(data.compensatedTotal.replaceAll('.', '').replace(',', '.')) : 0,
                    "ncp": data.notCompensatedTotal ? Number(data.notCompensatedTotal.replaceAll('.', '').replace(',', '.')) : 0,

                }
            ];
            newBalance = [
                {
                    name: 'Movimentação',
                    entradas: data.inFlows ? Number(data.inFlows.replaceAll('.', '').replace(',', '.')) : 0,
                    saídas: data.outFlows ? Number(data.outFlows.replaceAll('.', '').replace(',', '.')) : 0,
                    saldo: data.currentBalance ? Number(data.currentBalance.replaceAll('.', '').replace(',', '.')) : 0
                }
            ];
            newBalanceFilter = [
                {
                    name: 'Movimentação',
                    entradas: data.inFlowsFiltred ? Number(data.inFlowsFiltred.replaceAll('.', '').replace(',', '.')) : 0,
                    saídas: data.outFlowsFiltred ? Number(data.outFlowsFiltred.replaceAll('.', '').replace(',', '.')) : 0,
                    saldo: data.currentBalanceFiltred ? Number(data.currentBalanceFiltred.replaceAll('.', '').replace(',', '.')) : 0
                }
            ];
        }
        setDataValues2(newValues2);
        setDataBalance(newBalance);
        setDataBalanceFilter(newBalanceFilter);
    }
    const colorsValues = ['#76b64f', '#d75a4a', '#385a7d'];
    const colorsValues2 = ['#155724', '#856404', '#004085', '#d6d8d9'];

    const renderCustomizedLabel = ({ value }: any) => formatMoney(value.toFixed(2));

    const customTooltip2 = (value: any) => formatMoney(Number(value).toFixed(2));

    return (
        <div id="page-cash">

            <Alert showAlert={openAlertModal} setShowAlert={handleCloseCashModal} message={<p className="alert-error">Não há lançamentos referentes a este tipo de valor.</p>} />

            <Alert showAlert={openAlertError} setShowAlert={setOpenAlertError} message={<p className="alert-error">{msgAlertError}</p>} />

            <main>
                <div className="container">
                    <div className="ts-gutter">
                        <PageTitle text="Movimentação de caixa" icon={cashHandlingIcon} />
                    </div>
                    <div>
                        <Row className="align-items-center mx-n2">
                            <Col sm={6} className="ts-gutter-t px-2">
                                <SelectUnity
                                    selectedUnity={filterUnity}
                                    setSelectedUnity={setFilterUnity}
                                    selectedRegional={filterRegional}
                                    setSelectedRegional={setFilterRegional}
                                    id="filter"
                                    size={6}
                                    disabled={loading}
                                />
                            </Col>
                            {filterUnity && (
                                <Col sm={6} className="ts-gutter-t px-2">
                                    <Row className='mx-n2'>
                                        <Col lg={6} className='px-2'>
                                            <FormInputGroup label="Caixa" id="filter_cash">
                                                <select ref={inputRef} id="filter_cash" onChange={(e) => verifyFilters('nrocx', e.target.value)} value={filters.nrocx} disabled={loading}>
                                                    {cashInfo.map((item) => (
                                                        <option key={item.nrocx} value={item.nrocx}>Caixa {item.nrocx}{item.status ? '' : ' (Aberto)'} {item.dtcx && (" - " + moment(item.dtcx).format('DD/MM/YYYY'))}</option>
                                                    ))}
                                                </select>
                                            </FormInputGroup>
                                        </Col>
                                        <Col lg={6} className='px-2'>
                                            <FormInputGroup label="Modo de visualização" id="filter_view">
                                                <select id="filter_view" onChange={(e) => setViewType(Number(e.target.value))} value={viewType} disabled={loading}>
                                                    <option value="1">Lista</option>
                                                    <option value="2">Gráfico</option>
                                                </select>
                                            </FormInputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>

                {loading && (<Loading />)}
                {filters.nrocx && (
                    <div className="container-fluid" ref={chartsRef}>

                        <Row className="ts-gutter-b justify-content-center print-none ">
                            <Col lg={12} className="d-flex justify-content-center">
                                <label onClick={() => { setOpenCashList(!openCashList) }} className="d-flex align-items-center btn btn-success" style={{ cursor: "pointer" }}>
                                    <p className="ts-page-text mr-3"><b>LISTAGEM DE CAIXAS</b></p><ArrowIcon position='inherit' />
                                </label>
                            </Col>
                            <Col lg={12}>
                                <Collapse in={openCashList} appear={true} >
                                    <div className='cash-list-table'>
                                        <Table striped hover size="sm" className="ts-items-table ts-gutter-t clicker" >
                                            <thead>
                                                <tr key="listCash">
                                                    <th>Nro° Caixa</th>
                                                    <th>Data de fechamento</th>
                                                    <th>Unidade</th>
                                                    <th>Não Identificado</th>
                                                    <th>Tarifas Bancárias</th>
                                                    <th>Rendimentos</th>
                                                    <th>Valor detentos</th>
                                                    <th>Valor diferença</th>
                                                </tr>
                                            </thead>

                                            <tbody className='scroll'>
                                                {
                                                    cashInfo.map((item, key) => (
                                                        <tr className={filters['nrocx'] == item.nrocx ? 'cash-td-selected' : ''} data-id={item.nrocx} key={'listCash-' + key}
                                                            onClick={(e) => {
                                                                verifyFilters('nrocx', String(e.currentTarget.dataset.id))
                                                                setSelectedCashInfo(item)
                                                            }}>
                                                            <td><p>{item.nrocx}</p></td>
                                                            <td><p>{item.dtcx ? moment(item.dtcx).format('DD/MM/YYYY') : ''}</p></td>
                                                            <td><p>R$ {item.valor_unidade}</p></td>
                                                            <td><p>R$ {item.notIdentified}</p></td>
                                                            <td><p>R$ {item.bankFees}</p></td>
                                                            <td><p>R$ {item.earnings}</p></td>
                                                            <td><p>R$ {item.valor_detentos}</p></td>
                                                            <td><p>R$ {item.valor_diferenca}</p></td>
                                                        </tr>
                                                    )).reverse()
                                                }
                                            </tbody>
                                        </Table>
                                        <PaginationCP
                                            pagination={cashListPagination}
                                            filter={cashListFilters.pagination}
                                            filterKey={'pagination'}
                                            verifyFilters={verifyCashListPagination}
                                        />
                                    </div>
                                </Collapse>
                            </Col>
                        </Row>
                        <div className={`cash-status ${selectedCashInfo?.dtcx ? 'closed' : 'open'}`}>
                            <p className="ts-page-status">Caixa Nro° {selectedCashInfo?.nrocx}</p>
                            <div className="status-text">
                                {selectedCashInfo?.dtcx ? 'Fechado' : 'Aberto'}
                            </div>
                        </div>
                        <Row className="ts-gutter-b justify-content-center">
                            {!!listCashReturn?.bank && (
                                <Col lg={4}>
                                    <p className="ts-page-text mb-3 ts-gutter-t ts-title-bank-text"><b>BANCOS:</b></p>
                                    {viewType == 1 ? (
                                        <Table striped hover size="sm" className="ts-items-table ts-gutter-t print-none ">
                                            <thead>
                                                <tr>
                                                    <th>Nº conta</th>
                                                    <th>Descrição</th>
                                                    <th className='text-right'>Compensado</th>
                                                    <th className='text-right'>Não compensado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listCashReturn.bank.map((item, key) => (
                                                    <tr key={key}>
                                                        <td><p>{(item.numconta)}</p></td>
                                                        <td><p>{item.descricao}</p></td>
                                                        <td><p className='text-right'>R$ {item.saldo}</p></td>
                                                        <td><p className='text-right'>R$ {item.saldo_nao_conciliado}</p></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div className="ts-charts my-3">
                                            <ResponsiveContainer width="100%" aspect={1.5}>
                                                <PieChart>
                                                    <Pie
                                                        dataKey="valor"
                                                        isAnimationActive={false}
                                                        data={dataBank}
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={100}
                                                        fill="#385a7d"
                                                        label={renderCustomizedLabel}
                                                    >
                                                        {!!dataBank && dataBank.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colorsValues2[index]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    )}
                                </Col>
                            )}
                            {!!listCashReturn?.informative?.abstract && !!listCashReturn?.informative.infoCash && (
                                <>
                                    {viewType == 1 ? (
                                        <Col lg={4}>
                                            <p className="ts-page-text mb-3 ts-gutter-t ts-title-bank-text"><b>SALDOS:</b></p>
                                            <Table striped hover size="sm" className="ts-items-table ts-gutter-t">
                                                <tbody>
                                                    <tr key={1}>
                                                        <td><p>Compensado</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.compensatedTotal}</p></td>
                                                    </tr>
                                                    <tr key={2}>
                                                        <td><p>Não compensado</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.notCompensatedTotal}</p></td>
                                                    </tr>
                                                    <tr key={3}>
                                                        <td><p>Total geral</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.grandTotal}</p></td>
                                                    </tr>
                                                    <tr style={{ height: '30px' }}></tr>
                                                    <tr key={4}>
                                                        <td><p>Saldo anterior</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.prevBalance}</p></td>
                                                    </tr>
                                                    <tr key={5}>
                                                        <td><p>Entradas</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.inFlows}</p></td>
                                                    </tr>
                                                    <tr key={6}>
                                                        <td><p>Saídas</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.outFlows}</p></td>
                                                    </tr>
                                                    <tr key={7}>
                                                        <td><p>Saldo atual</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.infoCash.currentBalance}</p></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    ) : (
                                        <>
                                            <Col lg={4}>
                                                <p className="ts-page-text mb-3 ts-gutter-t"><b>SALDOS:</b></p>
                                                <div className="ts-charts my-3">

                                                    <ResponsiveContainer width="100%" aspect={1.5}>
                                                        <BarChart data={dataValues2}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip formatter={customTooltip2} />
                                                            <Legend />
                                                            <ReferenceLine y={0} stroke="#000" />
                                                            <Bar name='Compensados' dataKey="cp" fill={colorsValues[0]} />
                                                            <Bar name="Não Compensados" dataKey="ncp" fill={colorsValues[1]} />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <p className="ts-page-text mb-3 ts-gutter-t" style={{ 'opacity': 0 }}><b>SALDOS:</b></p>
                                                <div className="ts-charts my-3">
                                                    <ResponsiveContainer width="100%" aspect={1.5}>
                                                        <BarChart data={dataBalance}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip formatter={customTooltip2} />
                                                            <Legend />
                                                            <ReferenceLine y={0} stroke="#000" />
                                                            <Bar dataKey="entradas" stackId="a" fill={colorsValues[0]} />
                                                            <Bar dataKey="saídas" stackId="a" fill={colorsValues[1]} />
                                                            <Bar dataKey="saldo" fill={colorsValues[2]} />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                    <Col lg={viewType == 1 ? 4 : 8}>
                                        <p className="ts-page-text mb-3 ts-gutter-t ts-title-bank-text" ><b>VALORES:</b></p>
                                        {viewType == 1 ? (
                                            <Table size="sm" className="ts-items-table ts-gutter-t ">
                                                <tbody>
                                                    <tr key={8}>
                                                        <td><p>Total Unidade</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.abstract.unityTotal}</p></td>
                                                    </tr>
                                                    <tr key={9} className="ValueTableColor" onClick={() => setSelectedValueType(valueType.notIdentified)}>
                                                        <td><p>Não Identificado</p></td>
                                                        <td><p className='text-right'> {listCashReturn.informative.abstract.notIdentified}</p></td>
                                                    </tr>
                                                    <tr key={10} className="ValueTableColor" onClick={() => setSelectedValueType(valueType.bankFees)}>
                                                        <td><p>Tarifas Bancárias</p></td>
                                                        <td><p className='text-right'> {listCashReturn.informative.abstract.bankFees}</p></td>
                                                    </tr>
                                                    <tr key={11} className="ValueTableColor" onClick={() => setSelectedValueType(valueType.earnings)}>
                                                        <td ><p>Rendimentos</p></td>
                                                        <td><p className='text-right'> {listCashReturn.informative.abstract.earnings}</p></td>
                                                    </tr>
                                                    <tr key={12}>
                                                        <td><p>Total Detento</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.abstract.inmateTotal}</p></td>
                                                    </tr>
                                                    <tr key={13}>
                                                        <td><p>Diferença</p></td>
                                                        <td><p className='text-right'>R$ {listCashReturn.informative.abstract.diference}</p></td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <div className="ts-charts big">
                                                <ResponsiveContainer width="100%" aspect={2.5}>
                                                    <ComposedChart data={dataValues}>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="name" />
                                                        <YAxis />
                                                        <Tooltip formatter={customTooltip2} />
                                                        <Legend />
                                                        <ReferenceLine y={0} stroke="#000" />
                                                        <Area type="monotone" dataKey="unidade" fill={colorsValues[0]} stroke={colorsValues[0]} />
                                                        <Bar dataKey="detentos" barSize={50} fill={colorsValues[2]} />
                                                        <Line type="monotone" dataKey="diferença" stroke={colorsValues[1]} />
                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </Col>
                                </>
                            )}
                        </Row>

                        <div className="print-none">
                            <p className="ts-page-text ts-gutter-t"><b>LANÇAMENTOS DO CAIXA:</b></p>
                            <Row className="justify-content-between align-items-center">
                                <Col sm="auto" className="ts-gutter-t"><p className="ts-page-text">{nCashHandling} registros encontrados</p></Col>
                                <Col sm="auto" className="ts-gutter-t">
                                    <button className="ts-link-icon" onClick={() => setOpenSearch(!openSearch)}>
                                        <LinkIcon text="Pesquisar" icon={searchIcon} />
                                    </button>
                                    <button className="ts-link-icon" onClick={viewType === 2 ? handlePrint : handleExportAll} disabled={loading}>
                                        <LinkIcon text="Imprimir" icon={printIcon} />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Collapse in={openSearch} appear={true}>
                            <div className="ts-search ts-form-box ts-gutter">
                                <p className="ts-page-text mb-3">Pesquisar por:</p>
                                <Row className="mx-n2 justify-content-center">
                                    <Col lg={1.5} className="px-2">
                                        <FormInputGroup label="Data - de" id="dtcx_From">
                                            <input type="date" id="dtcx_From" onChange={(e) => verifyFilters('dtcx_From', e.target.value)} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={1.5} className="px-2">
                                        <FormInputGroup label="Data - até" id="dtcx_To">
                                            <input type="date" id="dtcx_To" onChange={(e) => verifyFilters('dtcx_To', e.target.value)} />
                                        </FormInputGroup>
                                    </Col>
                                    {!!listCashReturn?.bank && (
                                        <SelectGeneralData
                                            selectedRegional={filterRegional?.id_regional}
                                            selectedUnity={filterUnity?.id_unity}
                                            nomeListagem="Banco"
                                            disabled={loading ? false : loading}
                                            verifyFilters={verifyFilters}
                                            allItemsText={'Todas'}
                                            keyFilter={'conta'}
                                            outListItems={listCashReturn.bank.map((item) => {
                                                return {
                                                    id: String(item.numconta),
                                                    descricao: item.descricao
                                                }
                                            })}
                                            size={2}
                                        />
                                    )
                                    }
                                    <SelectGeneralData
                                        route='grp02'
                                        selectedRegional={filterRegional?.id_regional}
                                        selectedUnity={filterUnity?.id_unity}
                                        nomeListagem="Cód. CTB"
                                        disabled={loading ? false : loading}
                                        verifyFilters={verifyFilters}
                                        allItemsText={'Todas'}
                                        keyFilter={'codctb'}
                                        size={2}
                                    />

                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Nome" id="nome">
                                            <input type="text" id="nome" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('nome', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Documento" id="docto">
                                            <input type="text" id="docto" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('docto', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                    <Col lg={2} className="px-2">
                                        <FormInputGroup label="Descrição" id="descricao">
                                            <input type="text" id="descricao" onChange={(e) => { clearTimeout(idTimer); setIdTimer(setTimeout(() => { verifyFilters('descricao', e.target.value) }, 700)) }} />
                                        </FormInputGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse>
                        <div className="ts-gutter">
                            {viewType == 1 ? (
                                <>
                                    <Table striped hover size="sm" className="ts-items-table  ts-lists ">
                                        <thead>
                                            <tr>
                                                <th>Documento</th>
                                                <th>Nome</th>
                                                <th>Descrição</th>
                                                <th className='text-right'>Entrada</th>
                                                <th className='text-right'>Saída</th>
                                                <th className='text-right'>Saldo</th>
                                                <th>Data</th>
                                                <th>Tipo</th>
                                                <th>Banco</th>
                                                <th>Cód. CTB</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!listCashHandling && listCashHandling.map((item, key) => (
                                                <tr key={key}>
                                                    <td><p>{item.docto}</p></td>
                                                    <td><p>{item.nome}</p></td>
                                                    <TdResponsive val={String(item.descricao)} />
                                                    <td><p className='text-right'>R$ {item.vlent ? formatMoney(item.vlent) : ''}</p></td>
                                                    <td><p className='text-right'>R$ {item.vlsai ? formatMoney(item.vlsai) : ''}</p></td>
                                                    <td><p className='text-right'>R$ {item.saldo}</p></td>
                                                    <td><p>{item.dtcx}</p></td>
                                                    <td><p>{item.moeda}</p></td>
                                                    <td><p>{item.numconta}</p></td>
                                                    <td><p>{item.codctb}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <PaginationCP
                                        pagination={pagination}
                                        filter={filters.pagination}
                                        filterKey={'pagination'}
                                        verifyFilters={verifyFilters}
                                    />
                                    {!!listCashReturn?.informative?.infoCash && (
                                        <Row className="ts-gutter justify-content-end px-5">
                                            <Col sm="auto" className="px-4">
                                                <p className="ts-page-text mb-3"><b>SALDO DOS LANÇAMENTOS:</b></p>
                                            </Col>
                                            <Col sm="auto" className="px-4 text-right">
                                                <FormInputGroup label="Entradas" id="">
                                                    <p className="ts-page-text">R$ {listCashReturn.informative.infoCash.inFlowsFiltred}</p>
                                                </FormInputGroup>
                                            </Col>
                                            <Col sm="auto" className="px-4 text-right">
                                                <FormInputGroup label="Saídas" id="">
                                                    <p className="ts-page-text">R$ {listCashReturn.informative.infoCash.outFlowsFiltred}</p>
                                                </FormInputGroup>
                                            </Col>
                                            <Col sm="auto" className="px-4 text-right">
                                                <FormInputGroup label="Saldo atual" id="">
                                                    <p className="ts-page-text">R$ {listCashReturn.informative.infoCash.currentBalanceFiltred}</p>
                                                </FormInputGroup>
                                            </Col>
                                        </Row>)}
                                </>) : (
                                <div className="ts-charts mx-auto">
                                    <ResponsiveContainer width="100%" aspect={1.5}>
                                        <BarChart data={dataBalanceFilter}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip formatter={customTooltip2} />
                                            <Legend />
                                            <ReferenceLine y={0} stroke="#000" />
                                            <Bar dataKey="entradas" stackId="a" fill={colorsValues[0]} />
                                            <Bar dataKey="saídas" stackId="a" fill={colorsValues[1]} />
                                            <Bar dataKey="saldo" fill={colorsValues[2]} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                        </div>
                    </div>)
                }
                <Modal dialogClassName="ts-modal ts-modal-ultra-large" show={openCashModal} onHide={handleCloseCashModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <PageTitle text={`Lançamentos de ${selectedValueType === valueType.bankFees ? 'Tarifas Bancárias' : selectedValueType === valueType.earnings ? 'Rendimentos' : 'Não Identificados'} `} icon={configIcon} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="ts-gutter m-3">
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col sm="auto" className="ts-gutter-t pl-4"><p className="ts-page-text">{nDetailedModal} registros encontrados</p></Col>

                            </Row>
                            <Table striped hover size="sm" className="ts-items-table ts-lists m-2">
                                <thead>
                                    <tr className='align-right'>
                                        <th>Emissão</th>
                                        <th>Documento</th>
                                        <th style={{ width: '18%' }}>Nome</th>
                                        <th>Plano Contas</th>
                                        <th>Conta Bancária</th>
                                        <th>Tipo</th>
                                        <th>Valor</th>
                                        <th>Receb/Pagam</th>
                                        <th style={{ width: '30%' }}>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {!!detailedModal && detailedModal.map((item, key) => (
                                        <tr key={key} className='align-right'>
                                            <td><p>{item.dtem}</p></td>
                                            <td><p>{item.docto}</p></td>
                                            <TdResponsive val={String(item.nome)} />
                                            <td><p>{item.bill_plan}</p></td>
                                            <td><p>{item.conta}</p></td>
                                            <td><p>{item.type === "E" ? 'Entrada' : 'Saida'}</p></td>
                                            <td><p>R$ {item.valor ? formatMoney(item.valor) : '0.00'}</p></td>
                                            <td><p>{item.dtpag}</p></td>
                                            <TdResponsive val={String(item.descricao)} />

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <PaginationCP
                                pagination={modalPagination}
                                filter={modalFilters.pagination}
                                filterKey={'pagination'}
                                verifyFilters={verifyModalFilters}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </main >
        </div >
    );
}

export default CashList;