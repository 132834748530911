import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';

import Unity from '../../services/model/unity';

import FormInputGroup from '../FormInputGroup';

import Col from 'react-bootstrap/Col';

import '../SelectContact/style.css';
import Regional from '../../services/model/regional';
import { generalListData } from '../../services/generalData';
import ItemListGeneralSelect from '../../services/model/ItemListGeneralSelect';
import { ArrowIcon } from '../Icons';




interface SelectyListGeneral {
    selectedUnity?: Unity['id_unity'] | undefined;
    selectedRegional?: Regional['id_regional'] | undefined;
    route?: string;
    disabled: boolean;
    nomeListagem: string;
    verifyFilters: any;
    allItemsText: string;
    outListItems?: Array<ItemListGeneralSelect | undefined>;
    keyFilter: string;
    size?: number | undefined
}

const SelectGeneralData: React.FC<SelectyListGeneral> = ({ selectedUnity, selectedRegional, route, disabled, nomeListagem, verifyFilters, allItemsText, keyFilter, size, outListItems }) => {

    const [itemList, setItemList] = useState<ItemListGeneralSelect>();
    const [list, setList] = useState<Array<any | undefined>>([]);
    const [filteresList, setFilteresList] = useState<Array<any>>([])
    const [show, setShow] = useState<boolean>(false);


    const changeFilters = useCallback(() => {
        if (!disabled) {
            if (route) {
                generalListData(route, { id_regional: selectedRegional, id_unity: selectedUnity }).then((response) => {setList(response); setFilteresList(response); });

            } else {
                setList(outListItems ? outListItems : []);
                setFilteresList(outListItems ? outListItems : []);
            }
        }
    }, [selectedUnity])

    useEffect(() => {
        setItemList({ id: '', descricao: '' })
        verifyFilters(keyFilter, '')
        changeFilters();
    }, [selectedRegional, selectedUnity])


    return (
        <>
            <Col lg={size ? size : 2} className="px-2" onMouseEnter={() => { setFilteresList(list ? list : (outListItems ? outListItems : [])) }} onMouseLeave={() => { setShow(false) }}>
                <FormInputGroup label={nomeListagem} id={nomeListagem} >
                    <div className="ts-select-list">
                        <span className={"show-selected" + (disabled ? ' disabled' : '')} onClick={() => { !disabled && setShow(!show); }}>
                            {itemList?.id ? '(' + itemList.id + ') ' + itemList.descricao : String(allItemsText)}
                            <ArrowIcon left={size && size < 2 ? '80%' : '93%'} />
                        </span>
                        {show && !disabled && (
                            <div className="show-list">
                                <input className="search-list" type="text" placeholder="Pesquisar"
                                    onChange={(e) => (setFilteresList(!!list && list.filter((object) => {
                                        return (object.descricao.includes(e.target.value) || object.id.includes(e.target.value)) ? true : false
                                    })
                                    ))} id={`list-` + nomeListagem} />
                                <div className="list">
                                    {!!list.length && (
                                        <div className="item" onClick={() => { verifyFilters(keyFilter, ''); setShow(false); setItemList({ id: '', descricao: allItemsText }) }}>
                                            <p>{allItemsText}</p>
                                        </div>
                                    )}
                                    {!!filteresList && filteresList.map((item, key) => (
                                        <div className="item" onClick={() => { setItemList(item); setShow(false); changeFilters(); verifyFilters(keyFilter, item.id) }} key={key}>
                                            <p>({item.id}) - {item.descricao}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </FormInputGroup>
            </Col>
        </>
    );
}
export default memo(SelectGeneralData);