import React, { TextareaHTMLAttributes } from 'react';
import { Controller } from 'react-hook-form';

interface FormTextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  defaultValue?: string;
  control: any;
  rules: any;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({ name, defaultValue, control, rules, ...rest }) => (
    <Controller
        control={control}
        render={({ onChange, onBlur, value }) => (
            <textarea
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                {...rest}
            />
        )}
        name={name}
        rules={rules}
        defaultValue={defaultValue ? defaultValue : ""}
    />
);

export default FormTextArea;